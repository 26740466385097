import { makeAutoObservable, reaction } from "mobx";

export default class CartStore {
  products = [];
  onProductAdded = () => {}; // Callback to be set from the component

  constructor() {
    makeAutoObservable(this);

    // Reaction to detect changes in products array
    reaction(
      () => this.products.length,
      () => {
        if (this.onProductAdded) {
          this.onProductAdded();
        }
      }
    );
  }

  loadCart(products) {
    this.products = products;
  }

  addProduct(product) {
    this.products.push(product);
  }

  removeProduct(product) {
    this.products = this.products.filter(p => p.id !== product.id);
  }

  changeProductQuantity(product) {
    const index = this.products.findIndex(p => p.id === product.id);
    if (index !== -1) {
      this.products[index] = product;
    }
  }
}
import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Typography,
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

function CourseMeetingLinkComponent({ formData, handleChange, error, helperText }, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);
  const [createLinkNow, setCreateLinkNow] = useState(!!formData.zoomLink);
  const [selectedPlatform, setSelectedPlatform] = useState("");

  useEffect(() => {
    if (formData.zoomLink) {
      if (formData.zoomLink.includes("zoom.us")) {
        setSelectedPlatform("Zoom");
      } else if (formData.zoomLink.includes("meet.google.com")) {
        setSelectedPlatform("Google Meet");
      } else if (formData.zoomLink.includes("teams.microsoft.com")) {
        setSelectedPlatform("Microsoft Teams");
      } else {
        setSelectedPlatform("Others");
      }
    }
  }, [formData.zoomLink]);

  const handleInfoClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCreateLinkOptionChange = (event) => {
    const value = event.target.value;
    setCreateLinkNow(value === "now");
    handleChange({
      target: {
        name: "zoomLink",
        value: value === "later" ? "Create Meeting Link Later" : "",
      },
    });
  };

  const handlePlatformChange = (event) => {
    setSelectedPlatform(event.target.value);
  };

  const handleLinkChange = (event) => {
    handleChange({
      target: {
        name: "zoomLink",
        value: event.target.value,
      },
    });
  };

  const meetingLinkDescription =
    "Choose whether to create a meeting link now or later. If you create it now, you can provide the link for the platform of your choice.";

  return (
    <Box className="course-meeting-link" sx={{ marginBottom: 3 }} ref={ref}>
      <FormControl component="fieldset" fullWidth error={error}>
        <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Course Meeting Link
        </FormLabel>
        <Grid container spacing={3}>
          {/* Create Meeting Link Option */}
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
              When would you like to create the meeting link?
            </Typography>
            <RadioGroup
              aria-label="create-meeting-link"
              name="create-meeting-link"
              value={createLinkNow ? "now" : "later"}
              onChange={handleCreateLinkOptionChange}
            >
              <FormControlLabel
                value="later"
                control={<Radio />}
                label="Create Meeting Link Later"
              />
              <FormControlLabel
                value="now"
                control={<Radio />}
                label="Create Meeting Link Now"
              />
            </RadioGroup>
          </Grid>

          {/* Platform Selection and Link Input */}
          {createLinkNow && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
                  Select a platform to create the meeting link:
                </Typography>
                <RadioGroup
                  aria-label="meeting-platform"
                  name="meeting-platform"
                  value={selectedPlatform}
                  onChange={handlePlatformChange}
                >
                  <FormControlLabel
                    value="Google Meet"
                    control={<Radio />}
                    label="Google Meet"
                  />
                  <FormControlLabel
                    value="Zoom"
                    control={<Radio />}
                    label="Zoom"
                  />
                  <FormControlLabel
                    value="Microsoft Teams"
                    control={<Radio />}
                    label="Microsoft Teams"
                  />
                  <FormControlLabel
                    value="Others"
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Meeting Link"
                  name="zoomLink"
                  placeholder="Enter the meeting link"
                  value={formData.zoomLink || ""}
                  onChange={handleLinkChange}
                  fullWidth
                  error={error}
                  helperText={helperText}
                />
              </Grid>
            </>
          )}

          {/* Helper Text */}
          {helperText && (
            <Grid item xs={12}>
              <Typography variant="caption" color={error ? "error" : "textSecondary"}>
                {helperText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </FormControl>

      {/* Mobile Dialog for Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>About Course Meeting Link</DialogTitle>
        <DialogContent>
          <Typography>{meetingLinkDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const CourseMeetingLink = React.forwardRef(CourseMeetingLinkComponent);

export default CourseMeetingLink;
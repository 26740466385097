import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Avatar,
  Button,
  TextField,
  InputAdornment,
  Tooltip, // Add Tooltip component
  CircularProgress, // Add loading spinner
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import MessageIcon from "@mui/icons-material/Message";
import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home"; // Add Home icon
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import logo from "../../assets/GhummanTechLogoPageWatermark.png";
import BuyMembershipButton from "./BuyMembershipButton";
import GhummanRoboTrialButton from "./GhummanRoboTrialButton";
import SignInPopup from "./SignInPopup";
import "./style.css";
import { useStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";

const Header = observer((props) => {
  const { authStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [signinTag, setSigninTag] = useState(null);
  const [signinMessageLocal, setSigninMessageLocal] = useState("");
  const [isSearching, setIsSearching] = useState(false); // Add loading state for search
  const isDesktopOrLarger = useMediaQuery("(min-width:768px)");
  const isMobile = useMediaQuery("(max-width:767px)");

  useEffect(() => {
    const resultEmail = localStorage.getItem("email");
    setEmail(resultEmail);
  }, [showSignInPopup]);

  useEffect(() => {
    if (props.showSignInPopup) {
      setShowSignInPopup(true);
      props.setShowSignInPopup(false);
    }
  }, [props.showSignInPopup]);

  useEffect(() => {
    try {
      if (localStorage.getItem("email") || authStore.loggedIn) {
        const initial =
          localStorage.getItem("email")?.charAt(0).toUpperCase() || "";
        setSigninMessageLocal(initial);
        setSigninTag(
          <Avatar
            sx={{ bgcolor: "green", cursor: "pointer" }}
            onClick={goToMyAccount}
          >
            {initial}
          </Avatar>
        );
      } else {
        setSigninMessageLocal("Sign In");
        setSigninTag(
          <IconButton color="inherit" onClick={goToSignIn}>
            <PersonOutlineIcon />
          </IconButton>
        );
      }
    } catch (e) {
      setSigninMessageLocal("Sign In");
      setSigninTag(
        <IconButton color="inherit" onClick={goToSignIn}>
          <PersonOutlineIcon />
        </IconButton>
      );
    }
  }, [email, authStore.loggedIn]);

  const goToMyAccount = () => {
    navigate("/MyAccount");
  };

  const goToSignIn = () => {
    setShowSignInPopup(true);
  };

  const closeSignInPopup = () => {
    setShowSignInPopup(false);
  };

  const handleSearch = async () => {
    if (isMobile || searchText.trim()) {
      setIsSearching(true); // Show loading spinner
      // Simulate a search delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      navigate(`/Categories/courses/${searchText}`);
      setIsSearching(false); // Hide loading spinner
    }
  };

  const clearSearch = () => {
    setSearchText("");
  };

  const handleHelpClick = () => {
    navigate("/docs");
  };

  const handleMobileSearchClick = () => {
    navigate("/search");
  };

  const breadcrumbs = location.pathname.split("/").slice(1);
  const formatCrumb = (crumb) => {
    if (crumb.startsWith("GT")) {
      return crumb;
    }

    return crumb
      .split(/(?=[A-Z])/) // Split on uppercase letters
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(" "); // Join words with spaces
  };

  const handleMessagesClick = () => {
    if (localStorage.getItem("email")) {
      navigate("/MyAccount/Messages");
    } else {
      goToSignIn();
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#fff", color: "#000" }}>
      {showSignInPopup && <SignInPopup closePopup={closeSignInPopup} />}
      <Toolbar>
        <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img
              src={logo}
              alt="GT Logo"
              className="no-outline"
              style={{ width: "70px", height: "auto" }}
            />
          </Link>
          {isDesktopOrLarger && breadcrumbs.length > 0 && (
            <Box sx={{ ml: 2 }}>
              <ul
                style={{
                  display: "flex",
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                }}
              >
                {breadcrumbs.map((crumb, index) => (
                  <li
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Link
                      to={`/${breadcrumbs.slice(0, index + 1).join("/")}`}
                      style={{ textDecoration: "underline", color: "blue" }}
                    >
                      {formatCrumb(crumb)}
                    </Link>
                    {index < breadcrumbs.length - 1 && (
                      <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                        &rarr;
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
        <Box sx={{ textAlign: "center", flexGrow: 1 }}>
          {location.pathname === "/" ? (
            <>
                <TextField
                  variant="outlined"
                  placeholder="Search online classes and tutors"
                  size="small"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "50px",
                      backgroundColor: "#f1f1f1",
                      paddingRight: "8px",
                      "&.Mui-focused": {
                        backgroundColor: "#fff",
                      },
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "#ccc",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#aaa",
                      },
                      "& input": {
                        outline: "none",
                        border: "none",
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchText && (
                          <Tooltip title="Clear search">
                            <IconButton onClick={clearSearch}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Search">
                          <IconButton onClick={handleSearch}>
                            {isSearching ? (
                              <CircularProgress size={20} />
                            ) : (
                              <SearchIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
            
            </>
          ) : (
            <>
              {isDesktopOrLarger ? (
                <Typography
                  variant="h6"
                  sx={{
                    color: "rgb(254, 1, 0)",
                    fontWeight: "bold",
                    fontFamily: "Arial, sans-serif", // Updated font
                  }}
                >
                  GHUMMAN TECH
                </Typography>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "rgb(254, 1, 0)",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                    }}
                  >
                    GHUMMAN
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "rgb(254, 1, 0)",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                    }}
                  >
                    TECH
                  </Typography>
                </>
              )}
              {isDesktopOrLarger && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "rgb(254, 1, 0)",
                    fontFamily: "Arial, sans-serif",
                  }}
                >
                  Empowering Lifelong Learners
                </Typography>
              )}
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title="Menu">
            <IconButton color="inherit" onClick={props.openMenu}>
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cart">
            <IconButton color="inherit" onClick={props.openCart}>
              <ShoppingCartIcon />
            </IconButton>
          </Tooltip>
          {isDesktopOrLarger && (
            <>
          <Tooltip title="Messages">
            <IconButton color="inherit" onClick={handleMessagesClick}>
              <MessageIcon />
            </IconButton>
          </Tooltip>

            <Tooltip title="Help">
              <IconButton color="inherit" onClick={handleHelpClick}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
            </>
          )}
          {signinTag}
          {isDesktopOrLarger && (
            <>
              <Button color="inherit">
                <BuyMembershipButton />
              </Button>
              <Button color="inherit">
                <GhummanRoboTrialButton />
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
});

export default Header;
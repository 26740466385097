import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme, useMediaQuery } from '@mui/material';
import ShelfCollection from './ShelfCollection';

const ShelfCollectionNew = ({ onNext, onBack, selectedProduct, setSelectedProduct }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                maxWidth: 800,
                margin: '0 auto',
                padding: 4,
                textAlign: 'center',
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <Typography
                variant="h1"
                sx={{
                    fontFamily: 'Times New Roman, serif',
                    color: 'red',
                    fontWeight: 'bold',
                    marginBottom: 4,
                    fontSize: isSmallScreen ? '2rem' : '2.5rem',
                }}
            >
                Select a Class
            </Typography>

            <ShelfCollection
                categoryName={'Courses'}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
            />

            <Box
                sx={{
                    padding: 3,
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                }}
            >
                <Button
                    variant="contained"
                    onClick={onNext}
                    sx={{
                        backgroundColor: 'red',
                        color: 'white',
                        padding: '12px 24px',
                        fontSize: '1rem',
                        borderRadius: '8px',
                        fontFamily: 'Times New Roman, serif',
                        '&:hover': {
                            backgroundColor: 'darkred',
                            transform: 'translateY(-2px)',
                            boxShadow: 2,
                        },
                    }}
                >
                    Next
                </Button>
                <Button
                    variant="outlined"
                    onClick={onBack}
                    sx={{
                        borderColor: 'red',
                        color: 'red',
                        padding: '12px 24px',
                        fontSize: '1rem',
                        borderRadius: '8px',
                        fontFamily: 'Times New Roman, serif',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.1)',
                            borderColor: 'darkred',
                            transform: 'translateY(-2px)',
                            boxShadow: 2,
                        },
                    }}
                >
                    Back
                </Button>
            </Box>
        </Box>
    );
};

export default ShelfCollectionNew;
import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, CircularProgress, Container, Typography, Box, Paper } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const SubscribeNewsLetter = () => {
  const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });
  const [customer, setCustomer] = useState({
    email: '',
    name: '',
    phone_number: '',
  });
  const emailInputRef = useRef(null); // Ref for input element
  const [isValidEmail, setIsValidEmail] = useState(true); // Email validity state
  const [buttonPressCount, setButtonPressCount] = useState(0);
  const [subscriptionComplete, setSubscriptionComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCustomer({ ...customer, [name]: value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email format
    const isValidEmailBoolean = emailRegex.test(email); // Test if email is valid
    setIsValidEmail(isValidEmailBoolean); // Update validity state
    return isValidEmailBoolean;
  };

  useEffect(() => {
    const shakeAnimation = () => {
      if (emailInputRef.current) {
        emailInputRef.current.classList.add('shake'); // Add shaking animation class
        setTimeout(() => {
          if (emailInputRef.current) {
            emailInputRef.current.classList.remove('shake'); // Remove animation after timeout
          }
        }, 500); // Adjust animation duration as needed
      }
    };
    if (!isValidEmail && buttonPressCount > 0) { // Check buttonPressCount state
      shakeAnimation();
    }

    // Cleanup function to remove animation class on unmount
    return () => {
      if (emailInputRef.current) {
        emailInputRef.current.classList.remove('shake');
      }
    };
  }, [isValidEmail, buttonPressCount]);

  const handleSubmitCustomerData = async (event) => {
    event.preventDefault();

    setButtonPressCount((prev) => prev + 1);

    const isValidEmailBoolean = validateEmail(customer.email); // Validate email before submission

    if (!isValidEmailBoolean) {
      return; // Prevent submission if email is invalid
    }

    try {
      setIsLoading(true);
      const baseUrl = getBaseUrl();

      const dataRequest = {
        ...customer, // Include all customer fields
        email: customer.email.trim(),
      };

      const response = await fetch(`${baseUrl}/api/user-subscribe-newsletter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      const data = await response.json();
      if (response.ok && data.message === "User subscribed successfully") {
        setSubscriptionComplete(true);
      }
    } catch (error) {
      console.error('Error verifying reCAPTCHA:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" style={{ background: 'linear-gradient(to right,hsla(0, 30.90%, 89.20%, 0.70),rgb(233, 42, 42))', padding: '20px', borderRadius: '10px' }}>
      {isLoading && (<CircularProgress />)}
      <Paper elevation={3} style={{ padding: '20px', borderRadius: '10px', width: '100%' }}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {!subscriptionComplete && (
            <>
              <Typography variant="h4" gutterBottom>Subscribe to our Newsletter</Typography>
              <Typography variant="subtitle1" gutterBottom>Stay updated with the latest news and offers</Typography>
              <Box display="flex" flexDirection="column" width="100%" gap={2}>
                <TextField
                  label="Full Name"
                  name="name"
                  variant="outlined"
                  onChange={handleInputChange}
                  fullWidth
                />
                <TextField
                  label="Your Email"
                  name="email"
                  variant="outlined"
                  onChange={handleInputChange}
                  error={!isValidEmail}
                  helperText={!isValidEmail && "Please enter a valid email"}
                  fullWidth
                  inputRef={emailInputRef}
                />
                <TextField
                  label="Phone Number"
                  name="phone_number"
                  variant="outlined"
                  onChange={handleInputChange}
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitCustomerData}
                  disabled={isLoading}
                  fullWidth
                  style={{ padding: '10px 0', transition: 'background-color 0.3s' }}
                  onMouseOver={(e) => e.target.style.backgroundColor = '#3f51b5'}
                  onMouseOut={(e) => e.target.style.backgroundColor = '#1976d2'}
                >
                  Subscribe
                </Button>
              </Box>
            </>
          )}

          {subscriptionComplete && (
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" marginTop={4} textAlign="center">
              <CheckCircleOutline style={{ fontSize: '4rem', color: 'green' }} />
              <Typography variant="h4">Thank you for subscribing to our newsletter!</Typography>
              <Typography variant="body1">Stay tuned for the latest updates and offers.</Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default SubscribeNewsLetter;
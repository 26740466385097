import React from 'react';
import Button from '@mui/material/Button';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme, useMediaQuery } from '@mui/material';
import TimezoneSelector from './TimezoneSelector';

const TimeSlotComponent = ({
    onNext,
    onBack,
    selectedTimezone,
    setSelectedTimezone,
    selectedDate,
    setSelectedDate,
    selectedSlot,
    setSelectedSlot,
    errorZone,
    errorDate,
    errorSlot,
    availableDates,
    availableTimeSlots,
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                maxWidth: 800,
                margin: '0 auto',
                padding: 4,
                textAlign: 'center',
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <TimezoneSelector
                selectedTimezone={selectedTimezone}
                setSelectedTimezone={setSelectedTimezone}
                errorZone={errorZone}
            />

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        color: 'black',
                        marginBottom: 2,
                        fontSize: isSmallScreen ? '1.5rem' : '2rem',
                        fontWeight: 'bold',
                    }}
                >
                    Select Date of Trial Class
                </Typography>
                <Grid container spacing={2}>
                    {availableDates.map((dd) => (
                        <Grid item xs={6} sm={4} md={3} key={dd}>
                            <Box
                                sx={{
                                    padding: 2,
                                    border: '2px solid #e0e0e0',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    backgroundColor: selectedDate === dd ? 'rgba(255, 0, 0, 0.1)' : 'white',
                                    borderColor: selectedDate === dd ? 'red' : '#e0e0e0',
                                    color: selectedDate === dd ? 'red' : 'black',
                                    fontFamily: 'Times New Roman, serif',
                                    fontSize: '1.25rem',
                                    textAlign: 'center',
                                    '&:hover': {
                                        borderColor: 'red',
                                        transform: 'translateY(-2px)',
                                        boxShadow: 2,
                                    },
                                }}
                                onClick={() => setSelectedDate(dd)}
                            >
                                <strong>{dd}</strong>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                {errorDate && !selectedDate && (
                    <Typography
                        sx={{
                            color: 'red',
                            fontSize: '0.875rem',
                            marginTop: 1,
                            fontFamily: 'Times New Roman, serif',
                        }}
                    >
                        {errorDate}
                    </Typography>
                )}
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        color: 'black',
                        marginBottom: 2,
                        fontSize: isSmallScreen ? '1.5rem' : '2rem',
                        fontWeight: 'bold',
                    }}
                >
                    Pick Your Time Slot
                </Typography>
                <Grid container spacing={2}>
                    {availableTimeSlots.map((slot) => (
                        <Grid item xs={6} sm={4} md={3} key={slot}>
                            <Box
                                sx={{
                                    padding: 2,
                                    border: '2px solid #e0e0e0',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    backgroundColor: selectedSlot === slot ? 'rgba(255, 0, 0, 0.1)' : 'white',
                                    borderColor: selectedSlot === slot ? 'red' : '#e0e0e0',
                                    color: selectedSlot === slot ? 'red' : 'black',
                                    fontFamily: 'Times New Roman, serif',
                                    fontSize: '1.25rem',
                                    textAlign: 'center',
                                    '&:hover': {
                                        borderColor: 'red',
                                        transform: 'translateY(-2px)',
                                        boxShadow: 2,
                                    },
                                }}
                                onClick={() => setSelectedSlot(slot)}
                            >
                                <strong>{slot}</strong>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                {errorSlot && !selectedSlot && (
                    <Typography
                        sx={{
                            color: 'red',
                            fontSize: '0.875rem',
                            marginTop: 1,
                            fontFamily: 'Times New Roman, serif',
                        }}
                    >
                        {errorSlot}
                    </Typography>
                )}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                }}
            >
                <Button
                    variant="contained"
                    startIcon={<EventAvailableIcon />}
                    sx={{
                        backgroundColor: 'red',
                        color: 'white',
                        padding: '12px 24px',
                        fontSize: '1rem',
                        borderRadius: '8px',
                        fontFamily: 'Times New Roman, serif',
                        '&:hover': {
                            backgroundColor: 'darkred',
                            transform: 'translateY(-2px)',
                            boxShadow: 2,
                        },
                    }}
                    onClick={onNext}
                >
                    Finish Scheduling Your Trial Class
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        borderColor: 'blue',
                        color: 'blue',
                        padding: '12px 24px',
                        fontSize: '1rem',
                        borderRadius: '8px',
                        fontFamily: 'Times New Roman, serif',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 255, 0.1)',
                            borderColor: 'darkblue',
                            transform: 'translateY(-2px)',
                            boxShadow: 2,
                        },
                    }}
                    onClick={onBack}
                >
                    Back
                </Button>
            </Box>
        </Box>
    );
};

export default TimeSlotComponent;
import React, { useState, forwardRef } from "react";
import {
  FormControl,
  FormLabel,
  Typography,
  Box,
  Grid,
  TextField,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Import the styles for the phone input

// Custom input component for PhoneInput
const CustomTextField = forwardRef((props, ref) => (
  <TextField
    {...props}
    inputRef={ref}
    fullWidth
    label="Phone Number"
    error={props.error}
    helperText={props.helperText}
  />
));

function CourseInstructorComponent({ formData, handleChange, handlePhoneChange, error, helperText }, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);

  const handleInfoClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const instructorDescription =
    "Enter your name and phone number. Your phone number will not be shared with users and will only be used by Ghumman Tech to schedule classes if email correspondence is not working.";

  return (
    <Box className="course-instructor" sx={{ marginBottom: 3 }} ref={ref}>
      <FormControl
        component="fieldset"
        fullWidth
        error={error?.instructor || error?.phoneNumber} // Safely access error properties
      >
        <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Course Instructor *
        </FormLabel>
        <Grid container spacing={3}>
          {/* Instructor Name */}
          <Grid item xs={12}>
            <TextField
              label="Instructor Name"
              name="instructor"
              placeholder="Enter your full name"
              value={formData.instructor || ""} // Fallback to empty string if undefined
              onChange={handleChange}
              fullWidth
              error={error?.instructor} // Safely access error.instructor
              helperText={helperText?.instructor} // Safely access helperText.instructor
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
              Phone Number (for internal use only)
            </Typography>
            <PhoneInput
              international
              defaultCountry="US" // Default country (can be changed or detected dynamically)
              value={formData.phoneNumber || ""} // Fallback to empty string if undefined
              onChange={handlePhoneChange}
              placeholder="Enter phone number"
              inputComponent={CustomTextField} // Use the custom input component
              inputProps={{
                error: error?.phoneNumber, // Safely access error.phoneNumber
                helperText: helperText?.phoneNumber, // Safely access helperText.phoneNumber
              }}
            />
          </Grid>

          {/* Helper Text */}
          {(helperText?.instructor || helperText?.phoneNumber) && (
            <Grid item xs={12}>
              <Typography
                variant="caption"
                color={error?.instructor || error?.phoneNumber ? "error" : "textSecondary"}
              >
                {helperText?.instructor || helperText?.phoneNumber}
              </Typography>
            </Grid>
          )}
        </Grid>
      </FormControl>

      {/* Mobile Dialog for Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>About Course Instructor</DialogTitle>
        <DialogContent>
          <Typography>{instructorDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const CourseInstructor = forwardRef(CourseInstructorComponent);

export default CourseInstructor;
import React, { useState, useRef, useEffect } from "react";

import seminars from "../../assets/landingPageThreeCards/seminarRoom.jpg";
import services from "../../assets/landingPageThreeCards/services.jpg";
import products from "../../assets/landingPageThreeCards/products.jpg";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import SeminarsLandingPageDetails from "./SeminarsLandingPageDetails";
import ServicesLandingPageDetails from "./ServicesLandingPageDetails";
import ProductsLandingPageDetails from "./ProductsLandingPageDetails";

const SWIPE_THRESHOLD = 50; // Minimum swipe distance for detection

const ScrollableCards = ({}) => {
  const cards = [
    {
      link: "/Seminars",
      image: seminars,
      title: "Seminars",
      description1: "New Tech, Unveiled! Register Now.",
      description2: "Master Ghumman Tech's Future. Sign Up.",
      description3: "Wide Topics, Deep Dives, Fun Learning.",
      description4: "Level Up Your Skills. Today! ",
      description5: "Exclusive Features. Register Now.",
    },
    {
      link: "/FreeAppForTeachers",
      image: services,
      title: "Free Apps for Popular Courses",
      description1: "Have a popular course?",
      description2: "Get a Free Mobile App for it!",
      description3: "Empowering Educators, One App at a Time.",
      description4: "Expand Your Reach. Engage Your Students.",
      description5: "Your Course. Your App. Built by Ghumman Tech.",
    },
    {
      link: "/Categories",
      image: products,
      title: "Courses",
      description1: "Learn Anything. Anytime.",
      description2: "Courses, Books & More!",
      description3: "Build Your Skills. Start Now.",
      description4: "Kids, Code, Languages & More.",
      description5: "Self-Paced or Instructor-Led.",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(1);
  const scrollContainerRef = useRef(null);
  const startX = useRef(0); // Store starting x position for drag/swipe

  const handleDragStart = (event) => {
    const clientX =
      event.clientX || (event.touches && event.touches[0].clientX);
    if (!clientX) return; // Handle cases where clientX is undefined
    startX.current = clientX;
  };

  const handleDragMove = (event) => {
    if (!startX.current) return;

    const currentX = event.clientX || event.touches[0].clientX;
    if (!currentX) return;
    const deltaX = currentX - startX.current;

    // Update scroll position based on drag delta (optional)
    // ... (similar logic as before)
  };

  const handleDragEnd = (event) => {
    startX.current = 0;
    const clientX =
      event?.clientX || (event?.touches && event?.touches[0]?.clientX);
    if (!clientX) return; // Handle cases where clientX is undefined

    const currentX = clientX;
    const deltaX = currentX - startX.current;

    if (Math.abs(deltaX) > SWIPE_THRESHOLD) {
      // Detect swipe based on threshold
      const newIndex = currentIndex + (deltaX > 0 ? 1 : -1); // Update index based on swipe direction
      setCurrentIndex(Math.min(Math.max(newIndex, 0), cards.length - 1)); // Limit index to valid range
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    // Update currentIndex on scroll event (for smooth scrolling with mouse)
    const handleScroll = () => {
      const cardWidth = scrollContainer.clientWidth / cards.length;
      const newIndex = Math.round(scrollContainer.scrollLeft / cardWidth);
      setCurrentIndex(Math.min(newIndex, cards.length - 1));
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => scrollContainer.removeEventListener("scroll", handleScroll);
  }, [cards, currentIndex]);

  const handleCardClick = (index) => {
    setCurrentIndex(index);
    scrollContainerRef.current.scrollTo({
      behavior: "smooth",
      left: index * (scrollContainerRef.current.clientWidth / cards.length),
    });
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
          Your One-Stop Shop for Tech Education and Solutions
        </h1>
      </div>
      <div className={`scrollable-cards-container`}>
        <div
          className="scroll-container"
          ref={scrollContainerRef}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          onMouseMove={handleDragMove}
          onTouchMove={handleDragMove}
          onMouseUp={handleDragEnd}
          onTouchEnd={handleDragEnd}
        >
          {cards.map((card, index) => (
            <div
              key={index}
              className={`${
                index === 0
                  ? "firstCardMoveRight"
                  : index === 1
                  ? "langdingPageCard"
                  : "lastCardMoveRight"
              }  ${index === currentIndex ? "focused" : ""}`}
              onClick={() => handleCardClick(index)}
            >
              {/* Your card content here */}
              <div style={{ fontFamily: "Roboto, sans-serif" }}>
                <b>{card.title}</b>
                <br />
                <div style={{ fontSize: "10px" }}>
                  {card.description1}
                  <br />
                  {card.description2}
                  <br />
                  {card.description3}
                  <br />
                  {card.description4}
                  <br />
                  {card.description5}
                  <br />
                </div>
                <Link to={card.link}>
                  <Button variant="link" color="primary">
                    {"Learn More >"}
                  </Button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {currentIndex === 0 && <SeminarsLandingPageDetails />}
        {currentIndex === 1 && <ServicesLandingPageDetails />}
        {currentIndex === 2 && <ProductsLandingPageDetails />}
      </div>
    </>
  );
};

export default ScrollableCards;

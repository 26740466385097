import React, { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  RedditShareButton,
  EmailShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  RedditIcon,
  EmailIcon,
} from "react-share";
import { FaCopy, FaLink } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

const ShareButtons = ({ currentUrl, item }) => {
  const [copied, setCopied] = useState(false);
  const fullUrl = `https://ghummantech.com${currentUrl}`;

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <ShareContainer>
      <ShareTitle>Share This {item?.type || 'Course'}</ShareTitle>
      <ShareIcons>
        <FacebookShareButton url={fullUrl} quote={item?.title}>
          <FacebookIcon size={40} round />
        </FacebookShareButton>
        <TwitterShareButton url={fullUrl} title={item?.title}>
          <TwitterIcon size={40} round />
        </TwitterShareButton>
        <LinkedinShareButton url={fullUrl} title={item?.title} description={item?.description}>
          <LinkedinIcon size={40} round />
        </LinkedinShareButton>
        <WhatsappShareButton url={fullUrl} title={item?.title}>
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>
        <TelegramShareButton url={fullUrl} title={item?.title}>
          <TelegramIcon size={40} round />
        </TelegramShareButton>
        <RedditShareButton url={fullUrl} title={item?.title}>
          <RedditIcon size={40} round />
        </RedditShareButton>
        <EmailShareButton url={`mailto:?subject=${encodeURIComponent(item?.title)}&body=${encodeURIComponent(fullUrl)}`} >
          <EmailIcon size={40} round/>
        </EmailShareButton>
        <CopyToClipboard text={fullUrl} onCopy={handleCopy}>
          <CopyButton>
            <FaCopy size={24} />
          </CopyButton>
        </CopyToClipboard>
      </ShareIcons>
      <AnimatePresence>
        {copied && (
          <CopiedMessage
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
          >
            Link Copied!
          </CopiedMessage>
        )}
      </AnimatePresence>
    </ShareContainer>
  );
};

// Styled Components
const ShareContainer = styled.div`
  text-align: center;
  margin: 2em auto;
  padding: 1.5em;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
`;

const ShareTitle = styled.h3`
  font-weight: 600;
  margin-bottom: 1em;
  color: #333;
`;

const ShareIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const CopiedMessage = styled(motion.span)`
  display: block;
  margin-top: 1em;
  color: #28a745;
  font-weight: 500;
`;

export default ShareButtons;
import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite'; // Import observer from mobx-react
import { useStore } from '../../stores/storeContext'; // Import MobX store
import { toJS } from 'mobx';
import CartProduct from './CartProduct';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import './style.css';

const FloatCart = observer((props) => {
  const navigate = useNavigate();
  const { cartStore, totalStore } = useStore(); // Access MobX stores

  const [isOpen, setIsOpen] = useState(props.isTheCartOpen); // Initialize `isOpen` with `props.isTheCartOpen`
  const [productAddedState, setProductAddedState] = useState(false);
  const [specialInstructionsText, setSpecialInstructionsText] = useState('');

  const prevCartProducts = useRef(cartStore.products);
  const prevIsTheCartOpen = useRef(props.isTheCartOpen);

  useEffect(() => {
    cartStore.onProductAdded = () => {
      setProductAddedState(true);
    };

    return () => {
      cartStore.onProductAdded = null; // Cleanup on unmount
    };
  }, [cartStore]);

  useEffect(() => {
    // Update cart total whenever cart products change
    if (prevCartProducts.current !== toJS(cartStore.products)) {
      totalStore.updateCart(cartStore.products);
      prevCartProducts.current = toJS(cartStore.products);
    }
  
    // Sync `isOpen` with `props.isTheCartOpen` when it changes
    if (prevIsTheCartOpen.current !== props.isTheCartOpen) {
      setIsOpen(props.isTheCartOpen);
      prevIsTheCartOpen.current = props.isTheCartOpen;
    }
  
    // Keep the cart open when a product is added
    if (productAddedState) {
      setIsOpen(true);
      setProductAddedState(false);
    }
  }, [toJS(cartStore.products), totalStore, props.isTheCartOpen, productAddedState]);

  const goToCheckoutPage = () => {
    navigate('/Checkout', {
      state: {
        specialInstructions: specialInstructionsText,
      },
    });
  };

  const openFloatCart = () => {
    setIsOpen(true);
  };

  const closeFloatCart = () => {
    setIsOpen(false);
    // setProductAddedState(true); // Ensure the cart stays closed
  };

  const addProduct = (product) => {
    cartStore.addProduct(product); // Use MobX store to add product
    setProductAddedState(true); // Keep the cart open after adding a product
    openFloatCart();
  };

  const removeProduct = (product) => {
    cartStore.removeProduct(product); // Use MobX store to remove product
  };

  const changeProductQuantity = (changedProduct) => {
    cartStore.changeProductQuantity(changedProduct); // Use MobX store to change quantity
  };

  const handleSpecialInstructionsChange = (event) => {
    setSpecialInstructionsText(event.target.value);
  };

  const products = cartStore.products.map((p) => (
    <CartProduct
      product={p}
      removeProduct={removeProduct}
      changeProductQuantity={changeProductQuantity}
      key={`${p.id}-${p.selectedSize}`} // Ensure unique key for products with the same ID but different sizes
      currency="USD" // Hardcoded currency
    />
  ));

  let classes = ['float-cart'];

  if (isOpen) {
    classes.push('float-cart--open');
  }

  let checkout;

  if (cartStore.products.length > 0) {
    checkout = (
      <div style={{ padding: 2 }}>
        <Container>
          <Row>
            <Col></Col>
            <Col xs={6}>
              <Button variant="primary" onClick={() => goToCheckoutPage()}>
                CHECK OUT
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    checkout = null;
  }

  const productFormatWithCurrency = (
    <div className="sub-price">
      <p className="sub-price__val">
        $ <span> </span>
        {totalStore.totalPrice.toFixed(2)} {/* Format total price */}
      </p>
    </div>
  );

  return (
    <div className={classes.join(' ')}>
      {/* If cart open, show close (x) button */}
      {isOpen && (
        <div onClick={() => closeFloatCart()} className="float-cart__close-btn">
          X
        </div>
      )}

      <div className="float-cart__content">
        <div className="float-cart__header">
          <span className="bag">
            <span className="bag__quantity">{totalStore.productQuantity}</span>
          </span>
          <span className="header-title">Cart</span>
        </div>

        <div className="float-cart__shelf-container">
          {products}
          {!products.length && (
            <p className="shelf-empty">
              Add some products in the cart <br />
              :)
            </p>
          )}
          <p className="shelf-message">Special instructions for seller</p>
          <Form.Group style={{ marginLeft: '20px', marginRight: '20px' }}>
            <Form.Control
              value={specialInstructionsText}
              onChange={handleSpecialInstructionsChange}
              size="lg"
              type="text"
              placeholder="Wrap as a gift..."
              as="textarea"
              rows="3"
              maxLength={1000}
            />
          </Form.Group>
        </div>

        <div className="float-cart__footer">
          <div className="sub">SUBTOTAL</div>
          {productFormatWithCurrency}
          {checkout}
        </div>
      </div>
    </div>
  );
});

export default FloatCart;
import React from 'react';
import TimezoneSelect from 'react-timezone-select';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme, useMediaQuery } from '@mui/material';

const TimezoneSelector = ({ selectedTimezone, setSelectedTimezone, errorZone }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                maxWidth: 800,
                margin: '0 auto',
                padding: 4,
                textAlign: 'center',
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    fontFamily: 'Times New Roman, serif',
                    color: 'black',
                    marginBottom: 4,
                    fontSize: isSmallScreen ? '1.5rem' : '2rem',
                    fontWeight: 'bold',
                }}
            >
                Select Your Timezone
            </Typography>

            <Box sx={{ marginBottom: 4 }}>
                <TimezoneSelect
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                    styles={{
                        control: (base) => ({
                            ...base,
                            fontFamily: 'Times New Roman, serif',
                            borderRadius: '8px',
                            border: '2px solid #e0e0e0',
                            padding: '8px',
                            fontSize: isSmallScreen ? '1rem' : '1.25rem',
                        }),
                        menu: (base) => ({
                            ...base,
                            fontFamily: 'Times New Roman, serif',
                            fontSize: isSmallScreen ? '1rem' : '1.25rem',
                        }),
                    }}
                />
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        color: 'black',
                        fontSize: isSmallScreen ? '1rem' : '1.25rem',
                    }}
                >
                    <strong>Selected Timezone:</strong> {selectedTimezone?.value || 'None'}
                </Typography>
            </Box>

            {errorZone && !selectedTimezone && (
                <Typography
                    sx={{
                        color: 'red',
                        fontSize: '0.875rem',
                        marginTop: 1,
                        fontFamily: 'Times New Roman, serif',
                    }}
                >
                    {errorZone}
                </Typography>
            )}
        </Box>
    );
};

export default TimezoneSelector;
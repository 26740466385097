import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Typography,
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

function CourseCostComponent({ formData, handleChange, error, helperText }, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);
  const [hourlyRate, setHourlyRate] = useState(10); // Default hourly rate
  const [weeklyFrequency, setWeeklyFrequency] = useState(1); // Default weekly frequency
  const [isFreeCourse, setIsFreeCourse] = useState(false); // Free course checkbox

  const handleInfoClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleHourlyRateChange = (event) => {
    const rate = parseFloat(event.target.value);
    if (!isNaN(rate)) {
      setHourlyRate(rate);
      updateMonthlyCost(rate, weeklyFrequency);
    }
  };

  const handleWeeklyFrequencyChange = (event) => {
    const frequency = parseInt(event.target.value, 10);
    console.log("Weekly Frequency Change:", frequency); // Debug log
    if (!isNaN(frequency) && frequency >= 0 && frequency <= 14) {
      setWeeklyFrequency(frequency);
      updateMonthlyCost(hourlyRate, frequency);
    }
  };

  const handleMonthlyCostChange = (event) => {
    const cost = parseFloat(event.target.value);
    if (!isNaN(cost)) {
      handleChange({
        target: {
          name: "monthlyCost",
          value: cost,
        },
      });
    }
  };

  const handleFreeCourseChange = (event) => {
    const isFree = event.target.checked;
    setIsFreeCourse(isFree);
    handleChange({
      target: {
        name: "monthlyCost",
        value: isFree ? 0 : hourlyRate * weeklyFrequency * 4,
      },
    });
  };

  const updateMonthlyCost = (rate, frequency) => {
    if (!isFreeCourse) {
      handleChange({
        target: {
          name: "monthlyCost",
          value: rate * frequency * 4,
        },
      });
    }
  };

  const costDescription =
    "Set the hourly rate and number of live sessions per week to calculate the monthly cost. You can also directly edit the monthly cost or mark the course as free.";

  return (
    <Box className="course-cost" sx={{ marginBottom: 3 }} ref={ref}>
      <FormControl component="fieldset" fullWidth error={error}>
        <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Course Cost *
        </FormLabel>
        <Grid container spacing={3}>
          {/* Hourly Rate */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Hourly Rate ($)"
              type="number"
              value={hourlyRate}
              onChange={handleHourlyRateChange}
              fullWidth
              disabled={isFreeCourse}
              inputProps={{ min: 0, max: 999, step: 1, pattern: "[0-9]*" }}
              helperText="We recommend between $7-$25 per hour."
            />
          </Grid>

          {/* Weekly Frequency */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Live Sessions per Week"
              type="number"
              value={weeklyFrequency}
              onChange={handleWeeklyFrequencyChange}
              fullWidth
              disabled={isFreeCourse}
              inputProps={{ min: 0, max: 14, step: 1, pattern: "[0-9]*" }}
            />
          </Grid>

          {/* Monthly Cost */}
          <Grid item xs={12}>
            <TextField
              label="Monthly Cost ($)"
              type="number"
              value={formData.monthlyCost || 0}
              onChange={handleMonthlyCostChange}
              fullWidth
              disabled={isFreeCourse}
              inputProps={{ min: 0, max: 999, step: 1, pattern: "[0-9]*" }}
            />
          </Grid>

          {/* Free Course Checkbox */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFreeCourse}
                  onChange={handleFreeCourseChange}
                  color="primary"
                />
              }
              label="Free Course"
            />
          </Grid>

          {/* Helper Text */}
          {helperText && (
            <Grid item xs={12}>
              <Typography variant="caption" color={error ? "error" : "textSecondary"}>
                {helperText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </FormControl>

      {/* Mobile Dialog for Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>About Course Cost</DialogTitle>
        <DialogContent>
          <Typography>{costDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const CourseCost = React.forwardRef(CourseCostComponent);

export default CourseCost;
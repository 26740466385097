import React, { useState } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import PropTypes from "prop-types";
import { getBaseUrl } from "../Utils/getBaseUrl";
import { observer } from "mobx-react-lite"; // Import observer from mobx-react
import { useStore } from "../../stores/storeContext" // Import MobX store
import Spinner from "../Spinner";

const CheckoutForm = observer(({
  stripe,
  elements,
  finalEmptyCart,
  finalHandleToken,
  finalDeclinePayment,
  history,
  finalCurrency,
  finalAmount,
  name,
  email,
  phone_number,
  automaticMonthlyPayment,
}) => {
  const { cartStore } = useStore(); // Access MobX store
  const [buttonDisableStatus, setButtonDisableStatus] = useState(false);

  const handleSubmit = async (event) => {
    setButtonDisableStatus(true);
    event.preventDefault();
  
    if (finalAmount === 0) {
      await finalHandleToken();
      setButtonDisableStatus(false);
      return;
    }
  
    if (!stripe || !elements) {
      return;
    }
  
    try {
      // Debug: Log cartStore.products before API call
      console.log("cartStore.products:", cartStore.products);
  
      // Unwrap MobX proxy to get the raw array
      const rawProducts = cartStore.products.slice(); // Use .slice() to unwrap the proxy
      console.log("Raw products:", rawProducts);
  
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: { name: name, email: email, phone: phone_number },
      });
  
      if (error) {
        console.log("error", error);
        finalDeclinePayment();
        return;
      }
  
      const baseUrl = getBaseUrl();
      const dataRequest = {
        email: email,
        name: name,
        phone: phone_number,
        products: rawProducts.map((product) => ({
          id: product.id,
          title: product.title,
          price: product.price,
          quantity: product.quantity,
          selectedSize: product.selectedSize,
        })), // Serialize only necessary fields
        paymentMethodId: paymentMethod.id,
        automaticMonthlyPayment: automaticMonthlyPayment,
      };
  
      // Debug: Log dataRequest before API call
      console.log("dataRequest:", dataRequest);
  
      const response = await fetch(
        `${baseUrl}/api/stripe-secret-subscription-one-time-combination`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        }
      );
  
      const data = await response.json();
      if (response.ok && data.clientSecret) {
        await finalHandleToken();
        setButtonDisableStatus(false);
      } else {
        await finalHandleToken();
        setButtonDisableStatus(false);
      }
    } catch (error) {
      console.error("Error during payment:", error);
      finalDeclinePayment();
      setButtonDisableStatus(false);
    }
  };

  return (
    <>
      {buttonDisableStatus && <Spinner />}
      <form onSubmit={handleSubmit}>
        {finalAmount > 0 && <CardSection />}
        <button className="btn btn-primary" disabled={buttonDisableStatus}>
          Confirm Order
        </button>
      </form>
    </>
  );
});

CheckoutForm.propTypes = {
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  finalEmptyCart: PropTypes.func.isRequired,
  finalHandleToken: PropTypes.func.isRequired,
  finalDeclinePayment: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  finalCurrency: PropTypes.string.isRequired,
  finalAmount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
  automaticMonthlyPayment: PropTypes.bool.isRequired,
};

const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          finalAmount={props.finalAmount}
          finalCurrency={props.finalCurrency}
          history={props.history}
          finalEmptyCart={props.finalEmptyCart}
          finalHandleToken={props.finalHandleToken}
          finalDeclinePayment={props.finalDeclinePayment}
          name={props.name}
          email={props.email}
          phone_number={props.phone_number}
          automaticMonthlyPayment={props.automaticMonthlyPayment}
        />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm;
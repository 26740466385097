import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Paper, Stepper, Step, StepLabel } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/GhummanTechLogoPageWatermark.png";
import Authentication from "./Authentication/Auth";
import CreateNewCourseDetails from "./CreateNewCourse/CreateNewCourseDetails";
import TeacherProfileData from "./TeacherProfileTabPanel/TeacherProfileData";
import TeacherCalendar from "./TeacherProfileTabPanel/TeacherCalendar";

const steps = ["Authentication", "Create Course", "Profile Data", "Calendar"];

const stepIcons = [
  PersonIcon,
  SchoolIcon,
  AccountBoxIcon,
  CalendarTodayIcon,
];

const StepIcon = (props) => {
  const { active, completed, icon } = props;
  const IconComponent = stepIcons[icon - 1];
  return completed ? (
    <CheckCircleIcon sx={{ color: "red" }} />
  ) : (
    <IconComponent sx={{ color: active ? "red" : "gray" }} />
  );
};

const OnboardTeacher = () => {
  const [step, setStep] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [step2Complete, setStep2Complete] = useState(false);
  const [step3Complete, setStep3Complete] = useState(false);
  const [step4Complete, setStep4Complete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (step4Complete) {
      toast.success("Onboarding complete!");
      navigate("/MyAccount/Teaching");
    }
  }, [step4Complete, navigate]);

  useEffect(() => {
    if (step === 0 && isAuthenticated && isEmailVerified) {
      setStep(1);
    } else if (step === 1 && step2Complete) {
      setStep(2);
    } else if (step === 2 && step3Complete) {
      setStep(3);
    }
  }, [step, isAuthenticated, isEmailVerified, step2Complete, step3Complete]);

  const handleNext = () => {
    if (
      (step === 1 && !step2Complete) ||
      (step === 2 && !step3Complete) ||
      (step === 3 && !step4Complete)
    )
      return;
    if (step < 3) setStep(step + 1);
  };

  const handlePrev = () => {
    if (step > 0) setStep(step - 1);
  };

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <Authentication
            setIsAuthenticated={setIsAuthenticated}
            setIsEmailVerified={setIsEmailVerified}
          />
        );
      case 1:
        return <CreateNewCourseDetails setStep2Complete={setStep2Complete} />;
      case 2:
        return <TeacherProfileData setStep3Complete={setStep3Complete} />;
      case 3:
        return <TeacherCalendar setStep4Complete={setStep4Complete} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "auto",
        padding: "20px",
        fontFamily: "Times New Roman",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: "20px",
          marginBottom: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <img
            src={logo}
            alt="Ghumman Tech Logo"
            style={{ maxWidth: "100px", height: "auto", marginRight: "20px" }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h1"
              sx={{ fontSize: "2rem", fontWeight: "bold", color: "#1976d2" }}
            >
              Ghumman Tech Teacher Onboarding
            </Typography>
            <Typography variant="body1" sx={{ color: "#555555" }}>
              We are excited to have you join our team. Please follow the steps
              to complete your onboarding process.
            </Typography>
          </Box>
        </Box>
        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={(props) => <StepIcon icon={index + 1} {...props} />}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          {step > 0 && (
            <IconButton
              onClick={handlePrev}
              sx={{
                backgroundColor: "#1976d2",
                color: "#fff",
                borderRadius: "50%",
                marginRight: "20px",
                "&:hover": { backgroundColor: "#115293" },
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: "#1976d2" }}
          >
            Step {step + 1} of 4
          </Typography>
          {step < 3 && (
            <IconButton
              onClick={handleNext}
              sx={{
                backgroundColor: "#1976d2",
                color: "#fff",
                borderRadius: "50%",
                marginLeft: "20px",
                "&:hover": { backgroundColor: "#115293" },
              }}
              disabled={
                (step === 0 && (!isAuthenticated || !isEmailVerified)) ||
                (step === 1 && !step2Complete) ||
                (step === 2 && !step3Complete)
              }
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Box>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          overflow: "auto",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
          {renderStepContent()}
        </Box>
      </Paper>
    </Box>
  );
};

export default OnboardTeacher;
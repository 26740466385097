import React, { useState, useEffect } from "react";
import { getBaseUrl } from "../Utils/getBaseUrl";
import { maxAttendeesPerSeminar } from "../Utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import SeminarSlider from "./SeminarSlider";
import PaypalCheckout from "./PaypalCheckout";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/Alert";
import Fade from "react-bootstrap/Fade";
import Spinner from "../Spinner";
import ReactPlayer from "react-player"; // For video playback
import "./style.css";

const stripePromise = process.env.REACT_APP_NOT_SECRET_CODE
  ? loadStripe("pk_test_4hmpKDMcjCeCwm8cvzr4ZYrv")
  : loadStripe("pk_live_zed2dWneRMXvc1gfUmwFU99t");
let elementsInstance;

const SeminarInfo = () => {
  const navigate = useNavigate();

  const [seminar, setSeminar] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams(); // Extract seminar ID from URL params
  const [useStripe, setUseStripe] = useState(true);
  const [registrationAllowed, setRegistrationAllowed] = useState(true);
  const [showRegistrationClosed, setShowRegistrationClosed] = useState(false);
  const [finalDate, setFinalDate] = useState("");

  const processDescription = (description) => {
    return description.replace(
      /\*\*(.*?)\*\*/g,
      '<b style="color: #ff0000;">$1</b>'
    );
  };

  const getSeatsLeft = (totalSeats, filledSeats) => {
    // Treat filledSeats as 0 if it is null
    const filled = filledSeats === null ? 0 : filledSeats;

    if (totalSeats === null) {
      return "Unlimited seats available";
    }
    const seatsLeft = totalSeats - filled;
    if (seatsLeft <= 0) {
      return "No seats available";
    } else if (seatsLeft > 50) {
      return "50+ seats left";
    } else if (seatsLeft > 40) {
      return "40+ seats left";
    } else if (seatsLeft > 30) {
      return "30+ seats left";
    } else if (seatsLeft > 20) {
      return "20+ seats left";
    } else if (seatsLeft > 10) {
      return "10+ seats left";
    } else {
      return `${seatsLeft} seats left`;
    }
  };

  useEffect(() => {
    const fetchSeminarDetails = async () => {
      try {
        if (!id) {
          navigate("/Seminars");
          return; // Exit the function to avoid further code execution
        }

        const baseUrl = getBaseUrl();
        const dataRequest = {
          id,
        };

        const response = await fetch(`${baseUrl}/api/seminar`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        });

        if (response.status === 500) {
          navigate("/Seminars");
          return; // Exit the function to avoid further code execution
        }

        const data = await response.json();
        data.seminar.description = processDescription(data.seminar.description);

        setSeminar(data.seminar);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSeminarDetails();
  }, []);

  useEffect(() => {
    if (seminar && seminar.total_attendees && seminar.seminar_datetime) {
      if (
        seminar.total_attendees >= maxAttendeesPerSeminar ||
        seminar.seminar_datetime < new Date()
      ) {
        setRegistrationAllowed(false);
        setShowRegistrationClosed(true);
      }
    }
  }, [seminar]);

  useEffect(() => {
    async function initStripe() {
      const stripe = await stripePromise;
      elementsInstance = stripe.elements();

      if (elementsInstance) {
        elementsInstance.on("ready", () => {});
      }
    }

    initStripe();
  }, [stripePromise]);

  useEffect(() => {
    if (seminar && seminar.seminar_datetime) {
      let startDate = new Date(seminar.seminar_datetime);
      startDate.setDate(startDate.getDate() + 5);
      setFinalDate(startDate.toLocaleDateString());
    }
  }, [seminar]);

  return (
    <div>
      {isLoading && <Spinner />}
      {error && <p>Error fetching seminar details: {error.message}</p>}
      {seminar && (
        <div
          style={{ textAlign: "left", marginLeft: "10px", marginRight: "10px" }}
        >
          <div style={{ display: "grid", placeItems: "center" }}>
            <h1>{seminar.title}</h1>
          </div>
          {seminar.video_link && (
            <div className="video-section">
              <ReactPlayer
                url={seminar.video_link}
                controls={true}
                width="100%"
                height="400px"
                style={{ marginBottom: "20px" }}
              />
            </div>
          )}
          <div style={{ marginBottom: "50px" }}>
            <Tabs
              defaultActiveKey="description"
              id="seminar-detail-tabs"
              className="mb-3"
            >
              <Tab eventKey="description" title="Description">
                <div
                  dangerouslySetInnerHTML={{ __html: seminar.description }}
                />
              </Tab>
              <Tab eventKey="presenter" title="Presenter">
                <p>
                  The upcoming {seminar.type === "camp" ? "camp" : "seminar"}{" "}
                  will be proudly presented by{" "}
                  <b style={{ color: "#ff0000" }}>{seminar.presenter}</b>, a
                  renowned expert in their field.
                </p>
              </Tab>
              <Tab eventKey="dateAndTime" title="Date & Time">
                {seminar.type !== "camp" && (
                  <p>
                    This upcoming seminar will be conducted on{" "}
                    <b style={{ color: "#ff0000" }}>
                      {new Date(seminar.seminar_datetime).toLocaleString(
                        undefined,
                        {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone, // Use the user's timezone
                          dateStyle: "long", // Format the date
                          timeStyle: "short", // Format the time
                        }
                      )}
                    </b>{" "}
                    ({Intl.DateTimeFormat().resolvedOptions().timeZone})! Mark
                    your calendars for this exciting deep dive...
                  </p>
                )}
                {seminar.type === "camp" && (
                  <p>
                    This upcoming camp will be from{" "}
                    <b style={{ color: "#ff0000" }}>
                      {new Date(seminar.seminar_datetime).toLocaleDateString(
                        undefined,
                        {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone, // Use the user's timezone
                          dateStyle: "long", // Format the date
                        }
                      )}
                    </b>{" "}
                    to{" "}
                    <b style={{ color: "#ff0000" }}>
                      {new Date(finalDate).toLocaleDateString(undefined, {
                        timeZone:
                          Intl.DateTimeFormat().resolvedOptions().timeZone, // Use the user's timezone
                        dateStyle: "long", // Format the date
                      })}
                    </b>{" "}
                    every day from{" "}
                    <b style={{ color: "#ff0000" }}>
                      {new Date(seminar.seminar_datetime).toLocaleTimeString(
                        undefined,
                        {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone, // Use the user's timezone
                          timeStyle: "short", // Format the time
                        }
                      )}
                    </b>{" "}
                    to{" "}
                    <b style={{ color: "#ff0000" }}>
                      {new Date(
                        new Date(seminar.seminar_datetime).setHours(
                          new Date(seminar.seminar_datetime).getHours() + 2
                        )
                      ).toLocaleTimeString(undefined, {
                        timeZone:
                          Intl.DateTimeFormat().resolvedOptions().timeZone, // Use the user's timezone
                        timeStyle: "short", // Format the time
                      })}
                    </b>{" "}
                    ({Intl.DateTimeFormat().resolvedOptions().timeZone})! Mark
                    your calendars for this exciting deep dive...
                  </p>
                )}
              </Tab>
              <Tab eventKey="location" title="Location">
                Prepare to learn and be inspired! The{" "}
                {seminar.type === "camp" ? "camp" : "seminar"} will be{" "}
                {seminar.location.toLowerCase() === "virtual" ? "" : "in"} **
                <b style={{ color: "#ff0000" }}>{seminar.location}</b>**!
              </Tab>
              <Tab eventKey="price" title="Pricing">
                <p>
                  Invest in your knowledge and future with this upcoming{" "}
                  {seminar.type === "camp" ? "camp" : "seminar"}, available for
                  only **<b style={{ color: "#ff0000" }}>{seminar.price} USD</b>
                  **. Don't miss this valuable opportunity...
                </p>
              </Tab>
              <Tab eventKey="language" title="Language">
                <p>
                  Please note that **
                  <b style={{ color: "#ff0000" }}>{seminar.language}</b>** will
                  be the primary language used throughout the{" "}
                  {seminar.type === "camp" ? "camp" : "seminar"}.
                </p>
              </Tab>
            </Tabs>
          </div>
          <SeminarSlider seminar={seminar} />

          {/* Display seats information */}
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <h3>
              {getSeatsLeft(seminar.total_seats, seminar.filled_seats)}
            </h3>
          </div>

          {/* Display payment information if seats are unlimited or available */}
          {registrationAllowed &&
            (seminar.total_seats === null ||
              (seminar.total_seats - (seminar.filled_seats || 0) > 0)) && (
              <div style={{ textAlign: "center" }}>
                <div>
                  <h1>Payment Method:</h1>
                </div>
                <div>
                  <ButtonGroup>
                    <Button
                      onClick={() => setUseStripe(true)}
                      className={useStripe ? "active" : ""}
                    >
                      Card
                    </Button>
                    <Button
                      onClick={() => setUseStripe(false)}
                      className={!useStripe ? "active" : ""}
                    >
                      PayPal
                    </Button>
                  </ButtonGroup>
                </div>

                {useStripe && (
                  <Elements stripe={stripePromise}>
                    <CheckoutForm seminar={seminar} />
                  </Elements>
                )}

                {!useStripe && <PaypalCheckout seminar={seminar} />}
              </div>
            )}

          {!registrationAllowed && (
            <Fade in={showRegistrationClosed}>
              <Alert
                show={showRegistrationClosed}
                variant="success"
                className="top-alert"
                onClose={() => setShowRegistrationClosed(false)}
                dismissible
              >
                The seminar has reached its maximum capacity and registration is
                now closed.
              </Alert>
            </Fade>
          )}
        </div>
      )}
    </div>
  );
};

export default SeminarInfo;
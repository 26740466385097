import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // For arrows
import { FaWhatsapp, FaDiscord, FaMastodon, FaTelegram } from 'react-icons/fa'; // Add Mastodon and Telegram icons
import watsappSlide from '../../assets/watsapp-slide.jpg';
import discordSlide from '../../assets/discord-slide.jpg'; // Add a Discord icon or image
import mastodonSlide from '../../assets/mastodon-slide.jpg'; // Add a Discord icon or image
import telegramSlide from '../../assets/telegram-slide.jpg'; // Add a Discord icon or image
import newsLetter from '../../assets/newsLetter.jpg'; // Newsletter background image
import SubscribeNewsLetter from './SubscribeNewsLetter';
import { Element } from 'react-scroll';

const WhatsAppAnnouncementGroup = () => {
    const isDesktopOrLarger = useMediaQuery({ minWidth: 1024 });

    // Custom Arrow Components
    const CustomPrevArrow = (props) => (
        <div
            {...props}
            style={{
                ...props.style,
                display: 'block',
                background: '#333',
                borderRadius: '50%',
                padding: '10px',
                cursor: 'pointer',
                zIndex: 1,
                left: '-25px', // Adjust position
            }}
        >
            <FaArrowLeft style={{ color: '#fff', fontSize: '1.5rem' }} />
        </div>
    );

    const CustomNextArrow = (props) => (
        <div
            {...props}
            style={{
                ...props.style,
                display: 'block',
                background: '#333',
                borderRadius: '50%',
                padding: '10px',
                cursor: 'pointer',
                zIndex: 1,
                right: '-25px', // Adjust position
            }}
        >
            <FaArrowRight style={{ color: '#fff', fontSize: '1.5rem' }} />
        </div>
    );

    // Carousel settings
    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        customPaging: (i) => {
            let backgroundColor;
            let Icon;
    
            // Determine the background color and icon based on the index
            switch (i) {
                case 0:
                    backgroundColor = '#25D366'; // WhatsApp green
                    Icon = FaWhatsapp;
                    break;
                case 1:
                    backgroundColor = '#7289DA'; // Discord blue
                    Icon = FaDiscord;
                    break;
                case 2:
                    backgroundColor = '#6364FF'; // Mastodon purple
                    Icon = FaMastodon;
                    break;
                case 3:
                    backgroundColor = '#0088CC'; // Telegram blue
                    Icon = FaTelegram;
                    break;
                default:
                    backgroundColor = '#ccc'; // Default gray
                    Icon = null;
            }
    
            return (
                <div
                    style={{
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: backgroundColor,
                        borderRadius: '50%',
                        cursor: 'pointer',
                        position: 'relative',
                    }}
                >
                    {Icon && <Icon style={{ color: '#fff', fontSize: '1.2rem' }} />}
                    {/* Red Circle for Active Slide */}
                    <div
                        style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-5px',
                            width: '10px',
                            height: '10px',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            display: 'none', // Hidden by default
                        }}
                        className="active-dot"
                    />
                </div>
            );
        },
    };

    return (
        <Element
            name="subscribe"
            style={{
                padding: '20px',
                backgroundColor: '#f9f9f9',
                display: 'flex',
                flexDirection: isDesktopOrLarger ? 'row' : 'column',
                gap: '20px',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {/* 1. Left Column: Subscribe Newsletter Form */}
            <div
                style={{
                    flex: 1,
                    borderRadius: '10px',
                    textAlign: 'center',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: isDesktopOrLarger ? '400px' : '100%',
                }}
            >
                <SubscribeNewsLetter />
            </div>

            {/* 2. Center Column: Carousel with Cards (WhatsApp and Discord) */}
            <div
                style={{
                    flex: 1,
                    maxWidth: isDesktopOrLarger ? '400px' : '100%',
                    marginBottom: isDesktopOrLarger ? '0' : '20px',
                }}
            >
                <h2 style={{ fontSize: '1.8rem', color: '#333', textAlign: 'center', marginBottom: '20px' }}>
                    Join Our Communities
                </h2>
                <Slider {...carouselSettings}>
                    {/* WhatsApp Card */}
                    <div style={{ padding: '10px' }}>
                        <div
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                padding: '20px',
                                textAlign: 'center',
                            }}
                        >
                            <a
                                href="https://chat.whatsapp.com/IU3XYWWeVE2K717wcFiWFU"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <img
                                    src={watsappSlide}
                                    alt="Join Our WhatsApp Group"
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'contain',
                                        borderRadius: '10px',
                                    }}
                                />
                                <div style={{ marginTop: '10px', fontSize: '1.2rem', fontWeight: 'bold', color: '#333' }}>
                                    Join Our WhatsApp Group for Updates
                                </div>
                            </a>
                        </div>
                    </div>

                    {/* Discord Card */}
                    <div style={{ padding: '10px' }}>
                        <div
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                padding: '20px',
                                textAlign: 'center',
                            }}
                        >
                            <a
                                href="https://discord.gg/FTuQQEYXYJ"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <img
                                    src={discordSlide}
                                    alt="Join Our Discord Server"
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'contain',
                                        borderRadius: '10px',
                                    }}
                                />
                                <div style={{ marginTop: '10px', fontSize: '1.2rem', fontWeight: 'bold', color: '#333' }}>
                                    Join Our Discord Server for Community Discussions
                                </div>
                            </a>
                        </div>
                    </div>

{/* Mastodon Card */}
<div style={{ padding: '10px' }}>
    <div
        style={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            textAlign: 'center',
        }}
    >
        <a
            href="https://mastodon.social/@ghummantech"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
        >
            <img
                src={mastodonSlide} 
                alt="Follow Us on Mastodon"
                style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'contain',
                    borderRadius: '10px',
                }}
            />
            <div style={{ marginTop: '10px', fontSize: '1.2rem', fontWeight: 'bold', color: '#333' }}>
                Follow Us on Mastodon for Updates
            </div>
        </a>
    </div>
</div>

{/* Telegram Card */}
<div style={{ padding: '10px' }}>
    <div
        style={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            textAlign: 'center',
        }}
    >
        <a
            href="https://web.telegram.org/k/#@ghumman_tech"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
        >
            <img
                src={telegramSlide}
                alt="Join Our Telegram Channel"
                style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'contain',
                    borderRadius: '10px',
                }}
            />
            <div style={{ marginTop: '10px', fontSize: '1.2rem', fontWeight: 'bold', color: '#333' }}>
                Join Our Telegram Channel for Updates
            </div>
        </a>
    </div>
</div>
                </Slider>
            </div>

            {/* 3. Right Column: YouTube Section */}
            <div
                style={{
                    flex: 1,
                    maxWidth: isDesktopOrLarger ? '400px' : '100%',
                    textAlign: 'center',
                }}
            >
                <h2 style={{ fontSize: '1.8rem', color: '#333', marginBottom: '20px' }}>
                    Subscribe to Our YouTube Channel
                </h2>
                <a
                    href="https://www.youtube.com/@ghummantech"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    <video
                        autoPlay
                        muted
                        loop
                        style={{
                            width: '100%',
                            maxWidth: '400px',
                            height: 'auto',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <source
                            src="https://github.com/ghumman/public-small-videos/blob/main/LandingPageYoutubeSubscribe.mp4?raw=true"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </a>
            </div>
        </Element>
    );
};

export default WhatsAppAnnouncementGroup;
import React from "react";
import { Box } from "@mui/material";
import MessageItem from "./MessageItem";

const MessageList = ({ messages, view, currentUserId, selectedStudentId }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {messages
        .filter((msg) => {
          if (view === "teacher") {
            return (
              msg.sender_id === selectedStudentId ||
              msg.receiver_id === selectedStudentId
            );
          } else {
            return true;
          }
        })
        .map((msg, index) => (
          <MessageItem
            key={index}
            msg={msg}
            currentUserId={currentUserId}
          />
        ))}
    </Box>
  );
};

export default MessageList;
import React from "react";
import { Card, CardContent, CardActions, Button, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const MessageInput = ({ newMessage, setNewMessage, handleSendMessage, isLoading }) => {
  return (
    <Card variant="outlined" sx={{ boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h6">Send a New Message</Typography>
        <TextField
          label="Message"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          disabled={isLoading}
        />
      </CardContent>
      <CardActions>
        <Button
          onClick={handleSendMessage}
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          disabled={!newMessage.trim() || isLoading}
        >
          Send Message
        </Button>
      </CardActions>
    </Card>
  );
};

export default MessageInput;
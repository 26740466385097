import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Typography,
  Box,
  Grid,
  TextField,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

function CourseContentDetails({ formData, handleChange, error, helperText }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);

  const handleInfoClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const contentDescription =
    "Provide details about the course content, including prerequisites, learning objectives, syllabus, materials needed, and resources. All fields are optional.";

  return (
    <Box className="course-content-details" sx={{ marginBottom: 3 }}>
      <FormControl component="fieldset" fullWidth error={error}>
        <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Course Content Details
        </FormLabel>
        <Grid container spacing={3}>
          {/* Pre-Requisites */}
          <Grid item xs={12}>
            <TextField
              label="Pre-Requisites"
              name="preRequisites"
              placeholder="Enter pre-requisites for the course"
              value={formData.preRequisites || "Basic understanding of the subject."}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>

          {/* Learning Objectives */}
          <Grid item xs={12}>
            <TextField
              label="Learning Objectives"
              name="learningObjectives"
              placeholder="Enter learning objectives for the course"
              value={
                formData.learningObjectives ||
                "By the end of this course, students will be able to understand the fundamentals and apply them in real-world scenarios."
              }
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>

          {/* Syllabus */}
          <Grid item xs={12}>
            <TextField
              label="Syllabus"
              name="syllabus"
              placeholder="Enter the syllabus for the course"
              value={
                formData.syllabus ||
                "Week 1: Introduction\nWeek 2: Core Concepts\nWeek 3: Advanced Topics\nWeek 4: Practical Applications"
              }
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>

          {/* Materials Needed */}
          <Grid item xs={12}>
            <TextField
              label="Materials Needed"
              name="materialNeeded"
              placeholder="Enter materials needed for the course"
              value={formData.materialNeeded || "Laptop, Notebook, Internet Connection"}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>

          {/* Resources */}
          <Grid item xs={12}>
            <TextField
              label="Resources"
              name="resources"
              placeholder="Enter additional resources for the course"
              value={
                formData.resources ||
                "Relevant books, reading materials, and online resources will be shared during the course. Additional references, including website links and supplementary materials, may be provided as needed to support learning."
              }
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </FormControl>

      {/* Mobile Dialog for Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>About Course Content Details</DialogTitle>
        <DialogContent>
          <Typography>{contentDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CourseContentDetails;
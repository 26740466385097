import React, { useState, useEffect } from 'react';

import Header from '../Header';
import FloatCart from '../FloatCart';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import './style.css';
import VideoWithButton2 from './VideoWithButton2';
import ImageWithButton from './ImageWithButton';
// import SummerCampAd from './SummerCampAd';
import FridayProgrammingClassAd from './FridayProgrammingClassAd';
import GhummanRoboAd from './GhummanRoboAd';
import FreeTrialClassMainPageAd from './FreeTrialClassGeneral/FreeTrialClassMainPageAd/FreeTrialClassMainPageAd';
import CreateCoursesAd from './CreateCoursesAd';
import CompanyDivisionCards from './CompanyDivisionCards';
import CompanyDivisionCards2 from './CompanyDivisionCards2';
import { useNavigate, useParams } from 'react-router-dom';
import WhatsAppAnnouncementGroup from './WhatsAppAnnouncementGroup';
import { scroller } from 'react-scroll'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ThreeLatestCourses from './ThreeLatestCourses';
import OnboardingCard from './OnboardingCard/OnboardingCard';
import FreeDemoCourseAd from './FreeDemoCourseAd/FreeDemoCourseAd';
import TeachersCarousel from './TeachersCarousel/TeachersCarousel';
import TeachersVideoCarousel from './TeachersVideoCarousel/TeachersVideoCarousel';

const MainPage = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [componentsLoaded, setComponentsLoaded] = useState(false);

  useEffect(() => {
    if (id) {
      setShowSignInPopup(true);
      navigate('/', { replace: true });
    }
  }, [id, navigate]);

  useEffect(() => {
    if (componentsLoaded) {
      const hash = window.location.hash.substring(1);
      if (hash) {
        scroller.scrollTo(hash, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }
    }
  }, [componentsLoaded]);

  const handleComponentsLoaded = () => {
    setComponentsLoaded(true);
  };

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const signMeIn = () => {
    navigate('/Login');
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
          showSignInPopup={showSignInPopup}
          setShowSignInPopup={setShowSignInPopup}
        />
        <div id="three-latest-courses">
          <ThreeLatestCourses onLoad={handleComponentsLoaded} />
        </div>
        <div id="free-trial-class">
          <FreeTrialClassMainPageAd />
        </div>
        <div id="free-demo-course-ad">
          <FreeDemoCourseAd />
        </div>
        <div id="create-courses-ad">
          <CreateCoursesAd setShowSignInPopup={setShowSignInPopup} />
        </div>
        <div id="teachers-carousel">
          <TeachersCarousel />
        </div>
        <div id="onboarding-card">
          <OnboardingCard />
        </div>
        <div id="teachers-video-carousel">
          <TeachersVideoCarousel />
        </div>
        <TawkMessengerReact
          propertyId="663396d5a0c6737bd133b152"
          widgetId="1hssoac5o" />
        {/* <div id="ghumman-robo-ad">
          <GhummanRoboAd />
        </div> */}
        {/* <SummerCampAd /> */}
        {/* <FridayProgrammingClassAd /> */}
        <div id="image-with-button">
          <ImageWithButton />
        </div>
        {/* <CompanyDivisionCards /> */}
        <div id="company-division-cards2">
          <CompanyDivisionCards2 />
        </div>
        <div id="whatsapp-announcement-group">
          <WhatsAppAnnouncementGroup id="subscribe" />
        </div>
        {/* <VideoWithButton2 /> */}
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
};

export default MainPage;
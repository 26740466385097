import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => (
  <div style={{ border: "2px solid #cc0066", backgroundColor: "#cc0066", borderRadius: "10px", padding: "6px", textAlign: "center" }}>
    {text}
  </div>
);

const Product = (props) => {
  const [username, setUsername] = useState("");
  const [menuHidden, setMenuHidden] = useState(true);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [selectedSizeName, setSelectedSizeName] = useState('');
  const [citiesSelectionStates, setCitiesSelectionStates] = useState(new Array(51).fill(0));

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
    const resultUsername = localStorage.getItem("username");
    if (resultUsername) {
      setUsername(resultUsername);
    }
    props.updateFilters({ 'sku': props.match.params.id });
    setAvailableSizes(props.products.availableSizes);
  }, [props]);

  const signMeIn = () => {
    props.history.push('/Login');
  };

  const toggleCityState = (index) => {
    const newCitiesSelectionStates = [...citiesSelectionStates];
    newCitiesSelectionStates[index] = 1 ^ newCitiesSelectionStates[index];
    setCitiesSelectionStates(newCitiesSelectionStates);
  };

  const places = props.greatPlaces.map((place) => {
    const { index, id, ...coords } = place;
    if (citiesSelectionStates[index] === 1) {
      return (
        <AnyReactComponent
          key={id}
          {...coords}
          text={id}
          hover={props.hoverKey === id}
        />
      );
    }
    return null;
  });

  const showCities = props.cities.map((item, index) => (
    <span
      key={index}
      style={{
        margin: "4px",
        border: "2px solid #cc0066",
        borderRadius: "10px",
        padding: "4px",
        display: "inline-block",
        cursor: "pointer",
        backgroundColor: citiesSelectionStates[index] === 1 ? "#cc0066" : "transparent"
      }}
      onClick={() => toggleCityState(index)}
    >
      {item}
    </span>
  ));

  let signinTag;
  if (username) {
    signinTag = <button type="button" className="btn btn-success btn-circle btn-lg">{username.charAt(0).toUpperCase()}</button>;
  } else {
    signinTag = <PersonOutlineIcon fontSize="large" onClick={signMeIn} />;
  }

  return (
    <div id="outer-container">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div style={{ textAlign: "center", fontWeight: "400", margin: "0 0 0.5em", lineHeight: "1.4", fontFamily: "auto", fontSize: "40px" }}>
              Visited Cities
            </div>
            <div>{showCities}</div>
            <div style={{ height: '100vh', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyD2RCwLCLh0aXZbKh_wfov36lYNDdvQE9E" }}
                defaultCenter={props.center}
                defaultZoom={props.zoom}
              >
                {places}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Product.defaultProps = {
  center: {
    lat: 30.37,
    lng: 69.34
  },
  zoom: 5,
  greatPlaces: [
    { index: 0, id: '*', lat: 24.8607, lng: 67.0011 },
    { index: 1, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 2, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 3, id: '*', lat: 33.5651, lng: 73.0169 },
    { index: 4, id: '*', lat: 32.1877, lng: 74.1945 },
    { index: 5, id: '*', lat: 34.0151, lng: 71.5249 },
    { index: 6, id: '*', lat: 30.1575, lng: 71.5249 },
    { index: 7, id: '*', lat: 25.3960, lng: 68.3578 },
    { index: 8, id: '*', lat: 33.6844, lng: 73.0479 },
    { index: 9, id: '*', lat: 30.1798, lng: 66.9750 },
    { index: 10, id: '*', lat: 29.3544, lng: 71.6911 },
    { index: 11, id: '*', lat: 32.0740, lng: 72.6861 },
    { index: 12, id: '*', lat: 32.4945, lng: 74.5229 },
    { index: 13, id: '*', lat: 27.7244, lng: 68.8228 },
    { index: 14, id: '*', lat: 27.5570, lng: 68.2028 },
    { index: 15, id: '*', lat: 31.7167, lng: 73.9850 },
    { index: 16, id: '*', lat: 28.4212, lng: 70.2989 },
    { index: 17, id: '*', lat: 31.2781, lng: 72.3317 },
    { index: 18, id: '*', lat: 30.0489, lng: 70.6455 },
    { index: 19, id: '*', lat: 32.5731, lng: 74.1005 },
    { index: 20, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 21, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 22, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 23, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 24, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 25, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 26, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 27, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 28, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 29, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 30, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 31, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 32, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 33, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 34, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 35, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 36, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 37, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 38, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 39, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 40, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 41, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 42, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 43, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 44, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 45, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 46, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 47, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 48, id: '*', lat: 31.4504, lng: 73.1350 },
    { index: 49, id: '*', lat: 31.5204, lng: 74.3587 },
    { index: 50, id: '*', lat: 31.5204, lng: 74.3587 }
  ]
};

export default Product;
import React from "react";
import { Box, Chip } from "@mui/material";

const ProductList = ({ products, selectedProductId, onProductClick }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {products.map((product) => (
        <Chip
          key={product.product_id}
          label={product.product_name}
          onClick={() => onProductClick(product.product_id)}
          color={selectedProductId === product.product_id ? "primary" : "default"}
          sx={{ mr: 1, mb: 1 }}
        />
      ))}
    </Box>
  );
};

export default ProductList;
import React from "react";
import ProductCarousel from "./components/ProductCarousel";
import ProductCarouselHeader from "./components/ProductCarouselHeader";

const ProductCarouselComponent = ({ products, videoUrl }) => {
  if (products[0] != undefined) {
    return <>
      <ProductCarouselHeader product={products[0]} />
      <ProductCarousel images={products[0].images} product={products[0]} videoUrl={videoUrl} />
    </>;
  }
  return null;
};

export default ProductCarouselComponent;
import React, { useEffect, useState } from 'react';
import FloatCart from '../FloatCart';

import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ContactPage from './ContactPage';

import { useNavigate } from 'react-router-dom';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import './style.css';
const ContactUs = (props) => {

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };


  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };


  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    onChange: (oldIndex, newIndex) => {
    }
  }

  const zoomOutProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.4,
    arrows: true
  }

  return (
    <div id="outer-container" style={{ overflowX: 'hidden' }}>

      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />
        <TawkMessengerReact
          propertyId="663396d5a0c6737bd133b152"
          widgetId="1hssoac5o" />
        <FloatCart isTheCartOpen={isOpen} history={props.history} />


        <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT" }}>Contact us</h1>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
          </a>
        </div>
        <div style={{ fontFamily: "serif", padding: "20px", textAlign: "center" }}>
          <div>
            <Row>
              <Col>
                <ContactPage />
              </Col>
            </Row>
          </div>
        </div>

        <Footer history={props.history} />

      </div>
    </div>
  );

}

export default ContactUs;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Box, Button } from '@mui/material';
import MembershipStatus from "./MembershipStatus";
import SubscriptionSwitch from "./SubscriptionSwitch";
import SubscriptionSwitchNotGt from "./SubscriptionSwitchNotGt";
import SignOutModal from "./SignOutModal";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import CancelSubscriptionModalNotGt from "./CancelSubscriptionModalNotGt";
import Spinner from "../../Spinner";
import UserInfo from "../UserInfo";
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar-heatmap/dist/styles.css';
import { getBaseUrl } from '../../Utils/getBaseUrl';
import { observer } from "mobx-react-lite";
import { authStore } from '../../../stores/storeContext';// Import the MobX store

const EMAIL = "email";

const SettingsTabPanel = observer(() => {
  const navigate = useNavigate();
  const [isMember, setIsMember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userHeatMap, setUserHeatMap] = useState([]);
  const [openSignOutModal, setOpenSignOutModal] = useState(false);
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = useState(false);
  const [openCancelSubscriptionModalNotGt, setOpenCancelSubscriptionModalNotGt] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [notGtSubscriptions, setNotGtSubscriptions] = useState(false);
  const [notGtSubscriptionsDeleteCourseId, setNotGtSubscriptionsDeleteCourseId] = useState(null);
  
  const signMeOut = async () => {
    authStore.logout();
    localStorage.setItem(EMAIL, '');
    navigate({ pathname: '/' });
  };

  const cancelSubscription = async () => {
    try {
      setIsCancelLoading(true);
      const baseUrl = getBaseUrl();
      const dataRequest = { email: localStorage.getItem(EMAIL) };

      const response = await fetch(`${baseUrl}/api/cancel-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === 'success') {
          setIsMember(false);
          setOpenCancelSubscriptionModal(false);
        }
      }
    } catch (e) {
      console.error('Error cancelling subscription:', e);
    } finally {
      setIsCancelLoading(false);
    }
  };

  const cancelSubscriptionNotGt = async () => {
    try {
      setIsCancelLoading(true);
      const baseUrl = getBaseUrl();
      const dataRequest = { 
        email: localStorage.getItem(EMAIL),
        course_id: notGtSubscriptionsDeleteCourseId
      };
  
      const response = await fetch(`${baseUrl}/api/cancel-subscription-not-gt`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === 'success') {
          setNotGtSubscriptions(prevSubscriptions => 
            prevSubscriptions.filter(subscription => subscription.course_id !== notGtSubscriptionsDeleteCourseId)
          );
          setOpenCancelSubscriptionModalNotGt(false);
        }
      }
    } catch (e) {
      console.error('Error cancelling subscription:', e);
    } finally {
      setIsCancelLoading(false);
    }
  };

  const handleToggleChange = async () => {
    if (isMember) {
      setOpenCancelSubscriptionModal(true);
    } else {
      toast.error('You cannot toggle this switch as you are not a member.');
    }
  };

  const handleCancelPressed = async () => {
      setOpenCancelSubscriptionModalNotGt(true);
  };

  const endDate = new Date();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 3);

  const maxCount = Array.isArray(userHeatMap) && userHeatMap.length > 0
    ? Math.max(...userHeatMap.map(value => value.count))
    : 0;

  const range1 = maxCount / 4;
  const range2 = (maxCount / 4) * 2;
  const range3 = (maxCount / 4) * 3;

  const classForValue = (value) => {
    if (!value || value.count === 0) {
      return 'color-empty';
    } else if (value.count <= range1) {
      return 'color-scale-1';
    } else if (value.count <= range2) {
      return 'color-scale-2';
    } else if (value.count <= range3) {
      return 'color-scale-3';
    } else {
      return 'color-scale-4';
    }
  };

  return (
    <div>
      <MembershipStatus setIsMember={setIsMember} setIsLoading={setIsLoading} setUserHeatMap={setUserHeatMap} setNotGtSubscriptions={setNotGtSubscriptions}/>
      {isLoading && <Spinner />}
      <h2 className="upload-activity-title">Your Quiz Upload Activity Over the Last 3 Months</h2>
      <CalendarHeatmap
        id="my-anchor-element"
        startDate={startDate}
        endDate={endDate}
        values={userHeatMap}
        classForValue={classForValue}
        tooltipDataAttrs={value => {
          if (!value || !value.date) {
            return { 'data-tip': 'No data' };
          }
          const formattedDate = new Date(value.date).toLocaleDateString();
          const uploadText = value.count === 1 ? 'upload' : 'uploads';
          return {
            'data-tip': `${formattedDate}: ${value.count} ${uploadText}`,
          };
        }}
      />
      <ReactTooltip />
      <Container>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <UserInfo />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" gap={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenSignOutModal(true)}
                sx={{ width: '200px', height: '50px' }}
              >
                SIGN OUT
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate('/Change')}
                sx={{ width: '200px', height: '50px' }}
              >
                CHANGE PASSWORD
              </Button>
            </Box>
          </Grid>
        </Grid>
        <SubscriptionSwitch isMember={isMember} handleToggleChange={handleToggleChange} />
        <SubscriptionSwitchNotGt notGtSubscriptions={notGtSubscriptions} handleCancelPressed={handleCancelPressed} setNotGtSubscriptionsDeleteCourseId={setNotGtSubscriptionsDeleteCourseId} />
        
      </Container>

      <SignOutModal openSignOutModal={openSignOutModal} setOpenSignOutModal={setOpenSignOutModal} signMeOut={signMeOut} />
      <CancelSubscriptionModal openCancelSubscriptionModal={openCancelSubscriptionModal} setOpenCancelSubscriptionModal={setOpenCancelSubscriptionModal} cancelSubscription={cancelSubscription} isCancelLoading={isCancelLoading} />
      <CancelSubscriptionModalNotGt openCancelSubscriptionModalNotGt={openCancelSubscriptionModalNotGt} setOpenCancelSubscriptionModalNotGt={setOpenCancelSubscriptionModalNotGt} cancelSubscriptionNotGt={cancelSubscriptionNotGt} isCancelLoading={isCancelLoading}/>
    </div>
  );
});

export default SettingsTabPanel;
import React from "react";
import { Button, Chip } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ProductFormatWithCurrency from "./components/ProductFormat";
import ChatDialog from "./components/ChatDialog";
import CourseDescription from "./CourseDescription";
import ShareButtons from "./components/ShareButtons";
import ProductTags from "./components/ProductTags";

const ProductDetailsContent = ({
  localProducts,
  selectedSizeIndex,
  sizeSelectedPressed,
  itemSizes,
  addThisProductToCart,
  handleClickOpen,
  open,
  handleClose,
  email,
  message,
  handleEmailChange,
  handleMessageChange,
  handleSend,
  emailError,
  messageError,
  eligible,
  navigate,
  handleClick,
  selectedKey,
  handleScheduleMeeting,
}) => {
  return localProducts.map((item) => (
    <div key={item.id}>
      <div style={{ textAlign: "center", fontWeight: "400", margin: "0 0 0.5em", lineHeight: "1.4", fontFamily: "auto", fontSize: "40px" }}>
        {item.title}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {item?.status === "active" && <Chip label="Active" style={{ fontWeight: "bold", backgroundColor: "green", color: "white" }} />}
        {item?.status === "completed" && <Chip label="Expired" style={{ fontWeight: "bold", backgroundColor: "red", color: "white" }} />}
        {item?.status === "soon" && <Chip label="Coming Soon" style={{ fontWeight: "bold", backgroundColor: "yellow", color: "black" }} />}
        {item?.price === 0 && <Chip label="FREE !" style={{ fontWeight: "bold", backgroundColor: "green", color: "white" }} />}
      </div>

      <ProductFormatWithCurrency item={item} handleScheduleMeeting={handleScheduleMeeting} />

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <a style={{ display: "inline-block", borderTopStyle: "solid" }}>&ensp;&ensp;&ensp;</a>
      </div>
      {item.availableSizes[0] !== "standard" && (
        <div>
          <div style={{ textAlign: "center", fontWeight: "400", margin: "0 0 0.5em", lineHeight: "1.4", fontFamily: "auto" }}>Available Sizes/Options</div>
          <div style={{ textAlign: "center", fontWeight: "400", margin: "0 0 0.5em", lineHeight: "1.4", fontFamily: "auto" }}>
            {item.availableSizes.map((productSize, index) => {
              if (index === selectedSizeIndex) {
                return (
                  <span key={index}>
                    {" "}
                    <span style={{ border: "2px solid black", padding: "5px", cursor: "pointer" }} onClick={() => sizeSelectedPressed(productSize, index)}>
                      {productSize}
                    </span>
                    &nbsp;&nbsp;
                  </span>
                );
              } else {
                return (
                  <span key={index}>
                    {" "}
                    <span style={{ cursor: "pointer" }} onClick={() => sizeSelectedPressed(productSize, index)}>
                      {productSize}
                    </span>
                    &nbsp;&nbsp;
                  </span>
                );
              }
            })}
            {itemSizes}
          </div>
        </div>
      )}

      <ProductTags product={item} />
      <div>
        <div style={{ textAlign: "center", fontWeight: "400", margin: "0 0 0.5em", lineHeight: "1.4", fontFamily: "auto" }}>
          <Button variant="contained" color="primary" onClick={() => addThisProductToCart(item)}>
            ADD TO CART
          </Button>{" "}
          <Button variant="contained" color="secondary" onClick={handleClickOpen} style={{ marginLeft: "10px" }}>
            <ChatIcon /> Chat Now
          </Button>
        </div>

        <ChatDialog
          open={open}
          handleClose={handleClose}
          email={email}
          message={message}
          handleEmailChange={handleEmailChange}
          handleMessageChange={handleMessageChange}
          handleSend={handleSend}
          emailError={emailError}
          messageError={messageError}
        />
      </div>

      {eligible && (
        <div style={{ textAlign: "center", fontWeight: "400", margin: "0 0 0.5em", lineHeight: "1.4", fontFamily: "auto" }}>
          <Button variant="dark" onClick={() => navigate(`/Courses/${localProducts[0].course_id}`)}>
            GO TO THE COURSE
          </Button>{" "}
        </div>
      )}

      <CourseDescription item={item} />

      {item.additionalInformation && (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
          {Object.entries(item.additionalInformation).map(([key]) => (
            <Chip key={key} label={key} variant="outlined" onClick={() => handleClick(key)} color={selectedKey === key ? "primary" : "default"} />
          ))}
          {selectedKey && (
            <div>
              <p style={{ backgroundColor: "lightgray", borderRadius: "25px", padding: "10px" }}>{item.additionalInformation[selectedKey]}</p>
            </div>
          )}
        </div>
      )}
    </div>
  ));
};

export default ProductDetailsContent;
import React, { useState, useEffect } from "react";
import "./style.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getBaseUrl } from '../Utils/getBaseUrl';
import { useNavigate } from 'react-router-dom';

import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [errorServerMessage, setErrorServerMessage] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formPassword2, setFormPassword2] = useState("");
  const [token, setToken] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);


  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const signMeIn = () => {
    navigate('/Login');
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const handleChangePassword = (event) => {
    setFormPassword(event.target.value);
  }

  const handleChangePassword2 = (event) => {
    setFormPassword2(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const password = formPassword.trim();
    const password2 = formPassword2.trim();

    if (password.length < 5 || password !== password2 || !token) {
      setErrorServerMessage("Password should be 5 characters long and match, and token is required.")
      return;
    }

    try {
      const response = await fetch(`${getBaseUrl()}/reset-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ newPassword: password, resetToken: token }),
      });


      const data = await response.json();



      if (response.ok && data.message === "Password reset successfully") {
        setErrorServerMessage("Password updated successfully.")
      } else {
        setErrorServerMessage(data.error)
      }
    } catch (error) {
      console.error(error); // Handle potential errors in fetching/parsing data
    }
  }
  useEffect(() => {
    parseUrl();
  }, []);

  const parseUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenValue = urlParams.get('token');
    if (tokenValue && tokenValue.length < 255) {
      setToken(tokenValue);
      setErrorServerMessage("Please select new password.");
    } else {
      setErrorServerMessage("Url is tempered");
    }
  }

  const loginPressed = () => {
    navigate({
      pathname: "/Login"
    });
  }


  var errorMessage;
  if (errorServerMessage != "") {
    errorMessage = (
      <p
        style={{
          paddingTop: 20,
          color: "red"
        }}
      >
        {errorServerMessage}
      </p>
    );
  } else {
    errorMessage = null;
  }
  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />
        <div className="App">
          <header className="App-header">
            <p style={{textDecorationLine: 'underline'}}>Welcome to Ghumman Tech</p>
            <p
              style={{
                borderStyle: "solid",
                borderColor: "black",
                borderRadius: 5,
                padding: 5
              }}
            >
              Forgot Password
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="loginFormPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={formPassword}
                  placeholder="Password"
                  onChange={handleChangePassword}
                />
              </Form.Group>

              <Form.Group controlId="loginFormPassword2">
                <Form.Label>Password (again)</Form.Label>
                <Form.Control
                  type="password"
                  value={formPassword2}
                  placeholder="Password"
                  onChange={handleChangePassword2}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                RESET PASSWORD
              </Button>
            </Form>

            <div>
              <p
                style={{ fontSize: 14, paddingTop: 20 }}
                onClick={loginPressed}
              >
                Back to Login
              </p>
            </div>

            {errorMessage}
          </header>
        </div>
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
}

export default ResetPassword;
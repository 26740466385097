import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  TextField,
  Button,
  Modal,
  Box,
  CircularProgress,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { getBaseUrl } from '../Utils/getBaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SeminarsSection from './SeminarsSection/SeminarsSection';

const TeachingTabPanel = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [eligibleCourses, setEligibleCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [estimatedEarnings, setEstimatedEarnings] = useState(0);

  useEffect(() => {
    const fetchTeacherCourses = async () => {
      setIsLoading(true);
      try {
        const baseUrl = getBaseUrl();
  
        const dataRequest = {
          user_email: localStorage.getItem("email"),
        };
  
        const response = await fetch(`${baseUrl}/api/all-teacher-courses`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });
  
        if (response.ok) {
          const data = await response.json();
          setEligibleCourses(data);

          if (data.length > 0) {
            const earningsResponse = await fetch(`${baseUrl}/api/month-earnings-by-owner`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email: localStorage.getItem("email") }),
            });

            if (earningsResponse.ok) {
              const earningsData = await earningsResponse.json();
              setEstimatedEarnings(earningsData.earnings);
            }
          }
        }
      } catch (e) {
        console.error('Error fetching teacher courses:', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeacherCourses();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEditClick = (course) => {
    navigate('/CourseMetadataEdit', { state: { course } });
  }

  const handleOpenAndEditClick = (course) => {
    navigate(`/Courses/${course.id}`);
  }

  const handleDeleteClick = (course) => {
    setCourseToDelete(course);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        course_id: courseToDelete.id,
        user_email: localStorage.getItem("email"),
      };

      const response = await fetch(`${baseUrl}/api/delete-course`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        setEligibleCourses(eligibleCourses.filter(course => course.id !== courseToDelete.id));
        setShowModal(false);
        setCourseToDelete(null);
        toast.success('Course deleted successfully');
      } else {
        console.error('Error deleting course');
        toast.error('Error deleting course');
      }
    } catch (e) {
      console.error('Error deleting course:', e);
      toast.error('Error deleting course');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCourseToDelete(null);
  };

  const filteredCourses = eligibleCourses.filter(course => {
    const matchesSearch = course.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesSearch;
  });

  return (
    <>
    <div style={{ fontFamily: 'Times New Roman' }}>
      {isLoading && <CircularProgress />}
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Typography variant="h4">Estimated Earnings This Month: ${estimatedEarnings.toFixed(2)}</Typography>
      </div>
      <div>
        <TextField
          type="text"
          placeholder="Search by Name"
          value={searchQuery}
          onChange={handleSearchChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </div>
      {filteredCourses && filteredCourses.length > 0 ? (
        <Container>
          <Grid container spacing={3}>
            {filteredCourses.map(course => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={course.id}>
                <Card style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <Link to={`/Courses/${course.id}`}>
                    <Tooltip title="Click on image or title to go to course and there you can edit the course content like chapters, quizzes" arrow>
                      <CardMedia
                        component="img"
                        style={{ objectFit: 'cover', height: '140px' }}
                        image={`https://ghummantech-website.s3.us-east-2.amazonaws.com/GT_Course_${course.id}_1.jpg`}
                        alt={course.name}
                      />
                    </Tooltip>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {course.name}
                      </Typography>
                    </CardContent>
                  </Link>
                  <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Button variant="contained" color="primary" onClick={() => handleOpenAndEditClick(course)} style={{ marginRight: '10px' }}>
                        Open and Edit
                      </Button>
                      <Tooltip title="Click Open and Edit to go to the course and edit its content" arrow>
                        <IconButton style={{ color: 'blue' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Button variant="contained" color="primary" onClick={() => handleEditClick(course)} style={{ marginRight: '10px' }}>
                        Edit Metadata
                      </Button>
                      <Tooltip title="Click Edit to change the meta data of the course like name, description, pricing, images and others" arrow>
                        <IconButton style={{ color: 'blue' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Button variant="contained" color="secondary" onClick={() => handleDeleteClick(course)} style={{ marginRight: '10px' }}>
                        Delete
                      </Button>
                      <Tooltip title="Click Delete to delete this course and all the associated files and data related to this course" arrow>
                        <IconButton style={{ color: 'purple' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>You haven't created any courses yet!</div>
      )}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button variant="contained" color="success" onClick={() => navigate('/CreateNewCourse')}>
          Create New Course
        </Button>
      </div>

      <Modal open={showModal} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2">
            Confirm Delete
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to delete this course?
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleCloseModal} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
    <SeminarsSection />
    </>
  );
}

export default TeachingTabPanel;
import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Typography,
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const stripTime = (date) => {
  if (!date) return null;
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

function CourseMiscellaneous({ formData, handleChange, error, helperText }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);

  const handleInfoClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleMinAgeChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 2 && value <= 50) {
      handleChange({
        target: {
          name: "minAge",
          value,
        },
      });
      // Ensure maxAge is not less than the new minAge
      if (formData.maxAge < value) {
        handleChange({
          target: {
            name: "maxAge",
            value,
          },
        });
      }
    }
  };

  const handleMaxAgeChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= formData.minAge && value <= 99) {
      handleChange({
        target: {
          name: "maxAge",
          value,
        },
      });
    }
  };

  const handleLanguageChange = (event) => {
    handleChange({
      target: {
        name: "language",
        value: event.target.value,
      },
    });
  };

  const handleCertificationChange = (event) => {
    handleChange({
      target: {
        name: "certification",
        value: event.target.checked,
      },
    });
  };

  const handleTotalSeatsOptionChange = (event) => {
    const value = event.target.value;
    handleChange({
      target: {
        name: "totalSeats",
        value: value === "unlimited" ? null : 0,
      },
    });
  };

  const handleTotalSeatsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 0) {
      handleChange({
        target: {
          name: "totalSeats",
          value,
        },
      });
    }
  };

  const handleStartDateOptionChange = (event) => {
    const value = event.target.value;
    handleChange({
      target: {
        name: "startDate",
        value: value === "noDate" ? null : stripTime(new Date()),
      },
    });
  };

  const handleStartDateChange = (date) => {
    const dateWithoutTime = stripTime(date);
    handleChange({
      target: {
        name: "startDate",
        value: dateWithoutTime,
      },
    });
    // Ensure endDate is not before the new startDate
    if (formData.endDate && dateWithoutTime > formData.endDate) {
      handleChange({
        target: {
          name: "endDate",
          value: dateWithoutTime,
        },
      });
    }
  };

  const handleEndDateOptionChange = (event) => {
    const value = event.target.value;
    handleChange({
      target: {
        name: "endDate",
        value: value === "noDate" ? null : stripTime(new Date()),
      },
    });
  };

  const handleEndDateChange = (date) => {
    const dateWithoutTime = stripTime(date);
    // Ensure endDate is not before startDate
    if (formData.startDate && dateWithoutTime < formData.startDate) {
      // If endDate is before startDate, set endDate to startDate
      handleChange({
        target: {
          name: "endDate",
          value: formData.startDate,
        },
      });
    } else {
      handleChange({
        target: {
          name: "endDate",
          value: dateWithoutTime,
        },
      });
    }
  };

  const handleFreeDemosOfferedChange = (event) => {
    handleChange({
      target: {
        name: "freeDemosOffered",
        value: event.target.checked, // Ensure this is a boolean
      },
    });
  };

  const miscellaneousDescription =
    "Provide additional details about the course, including age range, language, certification, total seats, start date, end date, and whether free demo classes are offered.";

  return (
    <Box className="course-miscellaneous" sx={{ marginBottom: 3 }}>
      <FormControl component="fieldset" fullWidth error={error}>
        <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Course Miscellaneous Details
        </FormLabel>
        <Grid container spacing={3}>
          {/* Min Age */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Minimum Age"
              type="number"
              value={formData.minAge || 3}
              onChange={handleMinAgeChange}
              fullWidth
              inputProps={{ min: 2, max: 50 }}
            />
          </Grid>

          {/* Max Age */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Maximum Age"
              type="number"
              value={formData.maxAge || 18}
              onChange={handleMaxAgeChange}
              fullWidth
              inputProps={{ min: formData.minAge || 3, max: 99 }}
            />
          </Grid>

          {/* Language */}
          <Grid item xs={12}>
            <TextField
              label="Language"
              value={formData.language || "English"}
              onChange={handleLanguageChange}
              fullWidth
            />
          </Grid>

          {/* Certification */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.certification || false}
                  onChange={handleCertificationChange}
                  color="primary"
                />
              }
              label="Will students receive a certification at the end of the course?"
            />
          </Grid>

          {/* Total Seats */}
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
              Total Seats
            </Typography>
            <RadioGroup
              aria-label="total-seats"
              name="totalSeatsOption"
              value={formData.totalSeats === null ? "unlimited" : "specific"}
              onChange={handleTotalSeatsOptionChange}
            >
              <FormControlLabel
                value="unlimited"
                control={<Radio />}
                label="Unlimited Seats"
              />
              <FormControlLabel
                value="specific"
                control={<Radio />}
                label="Specific Number"
              />
            </RadioGroup>
            {formData.totalSeats !== null && (
              <TextField
                label="Number of Seats"
                type="number"
                value={formData.totalSeats || ""}
                onChange={handleTotalSeatsChange}
                fullWidth
                inputProps={{ min: 0 }}
                sx={{ marginTop: 1 }}
              />
            )}
          </Grid>

          {/* Start Date */}
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
              Start Date
            </Typography>
            <RadioGroup
              aria-label="start-date"
              name="startDateOption"
              value={formData.startDate === null ? "noDate" : "specificDate"}
              onChange={handleStartDateOptionChange}
            >
              <FormControlLabel
                value="noDate"
                control={<Radio />}
                label="No Specific Date"
              />
              <FormControlLabel
                value="specificDate"
                control={<Radio />}
                label="Specific Date"
              />
            </RadioGroup>
            {formData.startDate !== null && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={formData.startDate}
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ marginTop: 1 }} />
                  )}
                />
              </LocalizationProvider>
            )}
          </Grid>

          {/* End Date */}
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
              End Date
            </Typography>
            <RadioGroup
              aria-label="end-date"
              name="endDateOption"
              value={formData.endDate === null ? "noDate" : "specificDate"}
              onChange={handleEndDateOptionChange}
            >
              <FormControlLabel
                value="noDate"
                control={<Radio />}
                label="No Specific Date"
              />
              <FormControlLabel
                value="specificDate"
                control={<Radio />}
                label="Specific Date"
              />
            </RadioGroup>
            {formData.endDate !== null && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={formData.endDate}
                  onChange={handleEndDateChange}
                  minDate={formData.startDate} // Ensure endDate cannot be before startDate
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ marginTop: 1 }} />
                  )}
                />
              </LocalizationProvider>
            )}
          </Grid>

          {/* Free Demos Offered */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!formData.freeDemosOffered} // Ensure this is a boolean
                  onChange={handleFreeDemosOfferedChange}
                  color="primary"
                />
              }
              label="Are you willing to offer 15-minute free demo classes to potential students?"
            />
          </Grid>
        </Grid>
      </FormControl>

      {/* Mobile Dialog for Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>About Course Miscellaneous Details</DialogTitle>
        <DialogContent>
          <Typography>{miscellaneousDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CourseMiscellaneous;
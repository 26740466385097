import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import {
  FormControl,
  FormLabel,
  Typography,
  Box,
  Tooltip,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

function CourseDescriptionComponent({ formData, handleChange, error, helperText }, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleInfoClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const descriptionDescription =
    "Enter a detailed description of your course. This will help students understand what the course is about, what they will learn, and any prerequisites or requirements.";

  const handleDescriptionChange = (value) => {
    handleChange({
      target: {
        name: "courseDescription",
        value,
      },
    });
  };

  return (
    <Box className="course-description" sx={{ marginBottom: 3 }} ref={ref}>
      <FormControl component="fieldset" fullWidth error={error}>
        <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
          <FormLabel component="legend" sx={{ fontWeight: "bold" }}>
            Course Description *
          </FormLabel>
          {!isMobile && (
            <Tooltip title={descriptionDescription} arrow>
              <InfoIcon
                fontSize="small"
                sx={{ marginLeft: 1, color: "action.active", cursor: "pointer" }}
              />
            </Tooltip>
          )}
          {isMobile && (
            <Button
              variant="text"
              size="small"
              onClick={handleInfoClick}
              sx={{ textTransform: "none" }}
            >
              <InfoIcon fontSize="small" sx={{ color: "action.active" }} />
            </Button>
          )}
        </Box>
        <Typography variant="caption" color="textSecondary" sx={{ marginBottom: 1 }}>
          Note: Emojis are not supported.
        </Typography>
        <ReactQuill
          value={formData.courseDescription}
          onChange={handleDescriptionChange}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, false] }],
              ["bold", "italic", "underline", "strike"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link", "image"],
              ["clean"],
            ],
          }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "link",
            "image",
          ]}
          placeholder="Enter the course description..."
          style={{ height: "200px", marginBottom: "16px" }}
        />
        {helperText && (
          <Typography variant="caption" color={error ? "error" : "textSecondary"} sx={{ marginTop: 1 }}>
            {helperText}
          </Typography>
        )}
      </FormControl>

      {/* Mobile Dialog for Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>About Course Description</DialogTitle>
        <DialogContent>
          <Typography>{descriptionDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const CourseDescription = React.forwardRef(CourseDescriptionComponent);

export default CourseDescription;
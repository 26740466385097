import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../Utils/getBaseUrl";
import Spinner from "../Spinner";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Chip,
  Button,
  IconButton,
  Box,
  CircularProgress,
  Tooltip,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import Slider from "react-slick";
import ShareIcon from "@mui/icons-material/Share";
import LinkIcon from "@mui/icons-material/Link";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import capitalizeName from "../Utils/capitalizeName";
import AllCourses from "./AllCourses.jpg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignInPopup from "../Header/SignInPopup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { keyframes } from "@mui/system";

const EMAIL = "email";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

function ThreeLatestCourses({ onLoad }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  // Inside the component
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm")); // screens < 600px
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  ); // screens between 600px and 960px
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("md")); // screens >= 960px

  // Determine the number of skeleton cards based on screen size
  const skeletonCount = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setIsLoading(true);
        const baseUrl = getBaseUrl();
        const userEmail = localStorage.getItem(EMAIL);

        const dataRequest = userEmail ? { user_email: userEmail } : {};

        const response = await fetch(`${baseUrl}/api/last-three-courses`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        });

        if (response.status === 500) {
          navigate("/Careers");
          return; // Exit the function to avoid further code execution
        }

        const data = await response.json();
        setCourses(data.products);
        onLoad(); // Call onLoad after setting the courses
      } catch (error) {
        console.log("Unable to fetch job details");
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobDetails();
  }, [navigate, onLoad]);

  const handleShare = async (course) => {
    const shareUrl = course
      ? `https://www.ghummantech.com/Categories/Product/${course.sku}`
      : `https://www.ghummantech.com/Categories/courses`;
    try {
      await navigator.share({
        title: course ? course.title : "Ghumman Tech Courses",
        url: shareUrl,
      });
    } catch (error) {
      if (error.name !== "AbortError") {
        console.log("Share failed:", error);
      }
    }
  };

  const handleCopyLink = (course) => {
    const shareUrl = course
      ? `https://www.ghummantech.com/Categories/Product/${course.sku}`
      : `https://www.ghummantech.com/Categories/courses`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      toast.info("Link copied to clipboard!");
    });
  };

  const toggleFavorite = async (item) => {
    const userEmail = localStorage.getItem(EMAIL);
    if (!userEmail) {
      setShowSignInPopup(true);
      return;
    }

    setLoadingFavorite(true);

    const baseUrl = getBaseUrl();
    const dataRequest = {
      email: userEmail,
      product_id: item.id,
    };

    const response = await fetch(`${baseUrl}/api/favorite-product`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    });

    if (response.ok) {
      const result = await response.json();
      const updatedFavoriteStatus =
        result.success && result.message === "Product favorited";

      // Update the local state
      const updatedCourses = courses.map((course) =>
        course.id === item.id
          ? { ...course, favorite: updatedFavoriteStatus }
          : course
      );

      setCourses(updatedCourses);
    }

    setLoadingFavorite(false);
  };

  const closeSignInPopup = () => {
    setShowSignInPopup(false);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="space-around"
        padding="20px"
        gap="20px"
      >
        {Array.from({ length: skeletonCount }).map((_, index) => (
          <Card
            key={index}
            style={{ margin: "0 10px", width: "100%", maxWidth: "300px" }}
          >
            <Skeleton variant="rectangular" height={300} />
            <CardContent>
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="40%" />
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  }

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIosIcon
        className={className}
        style={{
          ...style,
          display: "block",
          color: "white",
          right: "10px",
          zIndex: 1,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          padding: "10px",
        }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIosIcon
        className={className}
        style={{
          ...style,
          display: "block",
          color: "white",
          left: "10px",
          zIndex: 1,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          padding: "10px",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          animation: `${fadeIn} 0.5s ease-in`,
        }}
      >
        <div
          className="header-title"
          style={{
            color: "rgb(254, 1, 0)",
            fontWeight: "bold",
            fontFamily: "Times New Roman",
            textAlign: "center",
          }}
        >
          Featured Courses for Curious Minds
        </div>
        <div
          className="header-subtitle"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          Inspire the Next Generation—Launch Your Course Now!
        </div>

        <Slider {...settings}>
          {courses.map((course) => (
            <div key={course.id} style={{ padding: "20px" }}>
              <Card
                style={{
                  margin: "0 10px",
                  borderRadius: "12px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={course.images[0]}
                  alt={course.title}
                  style={{
                    position: "relative",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background:
                        "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))",
                    },
                  }}
                />
                <CardContent>
                  <Typography
                    variant="h6"
                    noWrap
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Times New Roman",
                    }}
                  >
                    {course.title}
                  </Typography>
                  <Chip
                    label={course.price === 0 ? "FREE!" : `$${course.price}`}
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    label={
                      course.included_in_membership
                        ? "Ghumman Tech"
                        : capitalizeName(course.owner_name)
                    }
                    color="secondary"
                    variant="outlined"
                  />
                  <IconButton
                    aria-label="share"
                    onClick={() => handleShare(course)}
                  >
                    <ShareIcon />
                  </IconButton>
                  <IconButton
                    aria-label="copy link"
                    onClick={() => handleCopyLink(course)}
                  >
                    <LinkIcon />
                  </IconButton>
                  <IconButton
                    aria-label="favorite"
                    onClick={() => toggleFavorite(course)}
                  >
                    {loadingFavorite ? (
                      <CircularProgress size={20} />
                    ) : course.favorite ? (
                      <Tooltip title="Remove from favorites">
                        <FavoriteIcon sx={{ color: "red" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add to favorites">
                        <FavoriteBorderIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(`/Categories/Product/${course.sku}`)
                    }
                    style={{
                      backgroundColor: "#1976d2",
                      color: "white",
                      transition: "transform 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    View Course
                  </Button>
                </CardContent>
              </Card>
            </div>
          ))}
          <div style={{ padding: "20px" }}>
            <Card
              style={{
                margin: "0 10px",
                borderRadius: "12px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardMedia
                component="img"
                height="300"
                image={AllCourses}
                alt="Explore More Courses"
                style={{
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))",
                  },
                }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  noWrap
                  style={{ fontWeight: "bold", fontFamily: "Times New Roman" }}
                >
                  Explore From Hundreds Of Courses...
                </Typography>
                <Chip
                  label="By World Class Instructors"
                  color="primary"
                  variant="outlined"
                />
                <IconButton
                  aria-label="share"
                  onClick={() => handleShare(null)}
                >
                  <ShareIcon />
                </IconButton>
                <IconButton
                  aria-label="copy link"
                  onClick={() => handleCopyLink(null)}
                >
                  <LinkIcon />
                </IconButton>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/Categories/courses")}
                  style={{
                    backgroundColor: "purple",
                    color: "white",
                    transition: "transform 0.2s ease",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  All Courses
                </Button>
              </CardContent>
            </Card>
          </div>
        </Slider>
        {showSignInPopup && <SignInPopup closePopup={closeSignInPopup} />}
      </Box>
    </ThemeProvider>
  );
}

export default ThreeLatestCourses;

import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";

const DescriptionContainer = styled("div")({
  textAlign: "center",
  fontWeight: 400,
  margin: "2em",
  lineHeight: 1.4,
  fontFamily: "Arial, sans-serif",
  position: "relative",
});

const TruncatedDescription = styled("div")({
  maxHeight: "100px",
  overflow: "hidden",
  position: "relative",
});

const GradientOverlay = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  height: "50px",
  background: "linear-gradient(to top, white, rgba(255, 255, 255, 0))",
});

const ModalContent = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "80vh",
  overflowY: "auto",
  backgroundColor: "white", // Ensure white background
  border: "2px solid #000",
  boxShadow: 24,
  padding: "16px", // Add padding
  borderRadius: "8px", // Optional: Add rounded corners
});

const CloseButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
});

const CourseDescription = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const processDescription = (description) => {
    return description.replace(
      /\*\*(.*?)\*\*/g,
      '<b style="color: #ff0000;">$1</b>'
    );
  };

  const truncatedDescription = (description) => {
    const maxLength = 200; // Maximum number of characters to display initially
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };

  const isTruncated = item.description.length > 200;

  return (
    <DescriptionContainer>
      <TruncatedDescription>
        <div
          dangerouslySetInnerHTML={{
            __html: processDescription(truncatedDescription(item.description)),
          }}
        />
        {isTruncated && <GradientOverlay />}
      </TruncatedDescription>
      {isTruncated && (
        <div style={{ textAlign: "center", color: "gray", marginTop: "10px" }}>
          <Button variant="outlined" onClick={handleOpen}>
            SHOW ALL
          </Button>
        </div>
      )}
      <Modal open={open} onClose={handleClose}>
        <ModalContent>
          <div
            dangerouslySetInnerHTML={{
              __html: processDescription(item.description),
            }}
          />
          <CloseButtonContainer>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </CloseButtonContainer>
        </ModalContent>
      </Modal>
    </DescriptionContainer>
  );
};

export default CourseDescription;
// src/stores/AuthStore.js
import { makeAutoObservable } from "mobx";

export default class AuthStore {
  loggedIn = false;
  user = "";
  userPassword = "";

  constructor() {
    makeAutoObservable(this);
  }

  login(email, password) {
    this.loggedIn = true;
    this.user = email;
    this.userPassword = password;
  }

  logout() {
    this.loggedIn = false;
    this.user = "";
    this.userPassword = "";
  }
}
// components/ProductDetailsUI.js
import React from "react";
import CourseRatingDetails from "../CourseRatingDetails";
import ProductElaboratedDetails from "./ProductElaboratedDetails";
import ShareButtons from "./ShareButtons";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Spinner from "../../Spinner";

const ProductDetailsUI = ({
  products,
  showCarousel,
  productDetails,
  isCourseOwner,
  videoUrl,
  isLoading,
  toggleFavorite,
  currentUrl,
}) => {
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">{showCarousel}</div>
            <div className="col-md-6">{productDetails}</div>
          </div>
          {products && products.length == 1 && (
            <>
            <ShareButtons currentUrl={currentUrl} item={products[0]} />
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
              <span onClick={(e) => { e.stopPropagation(); toggleFavorite(products[0]); }}>
                {products[0].favorite ? (
                  <FavoriteIcon style={{ color: "red", cursor: "pointer" }} />
                ) : (
                  <FavoriteBorderIcon style={{ cursor: "pointer" }} />
                )}
              </span>
            </div>
            <ProductElaboratedDetails product={products[0]} />
            <CourseRatingDetails item={products[0]} courseOwner={isCourseOwner} />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ProductDetailsUI;
import React, { useState, useEffect } from "react";
import { Grid, Box, Button, TextField, Typography } from '@mui/material';
import { getBaseUrl } from '../../Utils/getBaseUrl';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from 'react-router-dom';

const TeacherBankInfo = () => {
  const [bankName, setBankName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBankConnected, setIsBankConnected] = useState(false);
  const userEmail = localStorage.getItem("email") || "";
  const [searchParams, setSearchParams] = useSearchParams();

  // Fetch bank info on page load
  useEffect(() => {
    const fetchBankInfo = async () => {
      try {
        const baseUrl = getBaseUrl();
        const response = await fetch(`${baseUrl}/api/get-teacher-bank-info`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: userEmail }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch bank info");
        }

        const { bankInfo } = await response.json();
        if (bankInfo && bankInfo.bank_name && bankInfo.stripe_account_id) {
          setBankName(bankInfo.bank_name);
        }
      } catch (error) {
        console.error("Error fetching bank info:", error);
      }
    };

    fetchBankInfo();
  }, [userEmail]);

  // Handle query parameters for onboarding status
  useEffect(() => {
    const onboardingStatus = searchParams.get("onboarding");
    if (onboardingStatus === "success") {
      toast.success("Onboarding completed successfully!");
      // Clear the query parameter
      searchParams.delete("onboarding");
      setSearchParams(searchParams);
    } else if (onboardingStatus === "failed") {
      toast.error("Onboarding was not completed. Please try again.");
      // Clear the query parameter
      searchParams.delete("onboarding");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const handleBankNameChange = (e) => {
    setBankName(e.target.value);
  };

  const handleStripeOnboarding = async () => {
    if (!bankName) {
      toast.error("Please enter your bank name.");
      return;
    }

    setIsLoading(true);

    try {
      // Call the create-stripe-account endpoint
      const baseUrl = getBaseUrl();
      const response = await fetch(`${baseUrl}/api/create-stripe-account`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: userEmail, bank_name: bankName }),
      });

      if (!response.ok) {
        throw new Error("Failed to create Stripe account");
      }

      const { onboardingUrl } = await response.json();

      // Redirect to Stripe Onboarding
      window.location.href = onboardingUrl;
    } catch (error) {
      console.error("Error during Stripe onboarding:", error);
      toast.error("Failed to start Stripe onboarding. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={8}>
        <Typography variant="h5" gutterBottom>
          Update Bank Information
        </Typography>
        {bankName && (
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Previously connected bank: <strong>{bankName}</strong>
          </Typography>
        )}
        <TextField
          fullWidth
          label="Bank Name"
          value={bankName}
          onChange={handleBankNameChange}
          margin="normal"
          required
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleStripeOnboarding}
          disabled={isLoading || !bankName}
          sx={{ marginTop: 2 }}
        >
          {isLoading ? "Processing..." : "Connect with Stripe"}
        </Button>
      </Grid>
    </Grid>
  </Box>
  );
};

export default TeacherBankInfo;
import React, { useState, useEffect } from "react";
import { getBaseUrl } from '../Utils/getBaseUrl';
import './style.css';
import { useNavigate } from "react-router-dom";

import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { authStore } from '../../stores/storeContext';
import { observer } from "mobx-react-lite";


const PASSWORD = "password";
const LOGIN_TYPE = "login_type";
const ACCOUNT_TYPE = "account_type";
const EMAIL = "email";

const Login = observer((props) => {
  const navigate = useNavigate();
  const [errorServerMessage, setErrorServerMessage] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formPassword, setFormPassword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);


  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const signMeIn = () => {
    navigate('/Login');
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const handleChangeEmail = (event) => {
    setFormEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setFormPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formEmail.trim() === ""|| formPassword.trim() === "") {
      toast.error("Please fill in all fields");
      return;
    }

    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        email: formEmail.trim(),
        password: formPassword.trim(),
      };

      const response = await fetch(`${baseUrl}/api/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
        credentials: "include",
      });

      const data = await response.json();

      if (response.ok && data.message === "Login successful") {
        toast.success("Login successful");
        authStore.login(formEmail, formPassword);
        // props.login(formEmail, formPassword);
        localStorage.setItem(EMAIL, dataRequest.email);
        navigate({ pathname: "/" });
      } else {
        toast.error(data.error);
        setErrorServerMessage(data.error);
      }
    } catch (error) {
      console.error(error);
      // Handle error message display or other logic
    }
  };

  const goToRegisterScreen = () => {
    props.navigation.navigate('Register');
  };

  const showCountIncrement = () => {
    props.authenticate = props.increment;
  };

  const showCountDecrement = () => {
    props.authenticate = props.decrement;
  };

  const updateEmail = () => {
    props.login(formEmail, formPassword);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      const baseUrl = getBaseUrl();

      const dataRequest = {
        token
      };

      fetch(`${baseUrl}/decode-token-for-oauth-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'Login successful') {
            authStore.login(data.email, null);
            // props.login(data.email, null);
            localStorage.setItem(EMAIL, data.email);
            navigate({ pathname: '/' });
          } else {
            // Handle error state or message
          }
        })
        .catch((err) => {
          console.error(err);
          // Handle error message
        });
    }
  }, []);

  const responseFacebook = (response) => {
    try {
      var fullName = response.name.split(' ');

      // signup this person if email is not empty
      if (response.email !== '') {
        fetch('https://stealthdocumentation.com/dourbeen_api_pdo.php?apicall=loginSpecial', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: "loginType=special&firstName=" + fullName[0] + "&lastName=" + fullName[fullName.length - 1] + "&email=" + response.email
        }).then(async function (data) {
          data.json().then(async function (data) {
            // if good to go new then accountType is free
            if (data.message === 'Good to go new') {
              props.setState({ errorServerMessage: 'Logging in using Facebook.' });

              localStorage.setItem(ACCOUNT_TYPE, 'free');
              localStorage.setItem(LOGIN_TYPE, 'special');
              localStorage.setItem(EMAIL, response.email);
              localStorage.setItem(EMAIL, fullName[0] + ' ' + fullName[fullName.length - 1]);
              localStorage.setItem(PASSWORD, 'facebookPassword');

              // props.login(fullName[0] + ' ' + fullName[fullName.length - 1], 'facebookPassword');
              authStore.login(fullName[0] + ' ' + fullName[fullName.length - 1], 'facebookPassword');

              navigate({
                pathname: '/Profile'
              });
            } else if (data.message === 'Good to go old') {
              // do not set accountType
              props.setState({ errorServerMessage: 'Logging in using Facebook.' });

              localStorage.setItem(LOGIN_TYPE, 'special');
              localStorage.setItem(EMAIL, response.email);
              localStorage.setItem(EMAIL, fullName[0] + ' ' + fullName[fullName.length - 1]);
              localStorage.setItem(PASSWORD, 'facebookPassword');

              authStore.login(fullName[0] + ' ' + fullName[fullName.length - 1], 'facebookPassword');
              // props.login(fullName[0] + ' ' + fullName[fullName.length - 1], 'facebookPassword');

              navigate({
                pathname: '/Profile'
              });
            } else if (data.message === 'Regular account exists') {
              props.setState({
                errorServerMessage:
                  'Unable to login using Facebook. You already have an account with us. Please use email and password to login.'
              });
            } else {
              props.setState({ errorServerMessage: data.message });
            }
          });
        });
      } else {
        props.setState({
          errorServerMessage:
            'Unable to login using facebook. Facebook did not provide email address'
        });
      }
    } catch (e) {
      props.setState({ errorServerMessage: 'Facebook response not valid' });
    }
  };

  const needHelp = () => {
    navigate({
      pathname: "/Forgot"
    });
  }

  const signInUsingGoogle = async () => {

    const baseUrl = getBaseUrl();
    window.location.href = `${baseUrl}/auth/google`;
  }



  var that = this;

  var errorMessage;
  if (errorServerMessage != "") {
    errorMessage = (
      <p
        style={{
          paddingTop: 20,
          color: "red"
        }}
      >
        {errorServerMessage}
      </p>
    );
  } else {
    errorMessage = null;
  }
  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />

        <div className="App">
          <header className="App-header">
            <p style={{ textDecorationLine: 'underline' }}>Welcome to Ghumman Tech</p>
            <p
              style={{
                borderStyle: "solid",
                borderColor: "black",
                borderRadius: 5,
                padding: 5
              }}
            >
              Please Login
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="loginFormEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={formEmail}
                  placeholder="Enter Email"
                  onChange={handleChangeEmail}
                />
              </Form.Group>

              <Form.Group controlId="loginFormPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={formPassword}
                  placeholder="Password"
                  onChange={handleChangePassword}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Sign In
              </Button>
              <Link to="/Register" className="btn btn-link">
                Register
              </Link>
            </Form>

            <div style={{ paddingTop: 10 }}>

              <Button variant="success" onClick={signInUsingGoogle}>
                Sign in with Google
              </Button>
            </div>

            <div>
              <p style={{ fontSize: 14, paddingTop: 20, cursor: 'pointer' }} onClick={needHelp}>
                Need help?
              </p>
            </div>

            <div>
              <p style={{ fontSize: 14, cursor: 'pointer' }} onClick={() => { navigate('/') }}>
                Cancel
              </p>
            </div>

            {/* {errorMessage} */}
          </header>
        </div>
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
});

export default Login; 
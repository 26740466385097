export const tabNumberMap = {
    'Messages': 0,
    'FavoriteCourses': 1,
    'History': 2,
    'Bookshelf': 3,
    'Courses': 4,
    'CommunityCourses': 5,
    'Settings': 6,
    'TeacherProfile': 7,
    'Teaching': 8,
}
import { useNavigate } from 'react-router-dom';
import { getBaseUrl } from '../Utils/getBaseUrl';

const baseUrl = getBaseUrl();

const useRegisterClass = () => {
    const navigate = useNavigate();

    const registerClass = async (
        phone,
        childName,
        parentName,
        customerEmail,
        selectedGrade,
        selectedProduct,
        selectedSlot,
        selectedTimezone,
        selectedDate
    ) => {
        try {
            const dataRequestRegister = {
                phone_number: phone,
                child_name: childName,
                parent_name: parentName,
                customer_email: customerEmail,
                grade: selectedGrade,
                selected_time_slot: selectedSlot,
                selected_time_zone: selectedTimezone,
                selected_date: selectedDate,
                selectedProductID: selectedProduct.id,
                selectedProductTitle: selectedProduct.title,
                selectedProductOwnerEmail: selectedProduct.owner,
                selectedProductOwnerName: selectedProduct.owner_name
            };
            const response = await fetch(`${baseUrl}/api/register-trial-class`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataRequestRegister),
            });


            if (response.status === 200) {
                // If response status is 200, push to /ThankyouTrialClass
                navigate(
                    '/ThankyouTrialClass',
                    { state: { name: parentName } }
                );
            }

            return response;
        } catch (error) {
            // Handle error if needed
            console.error('Error registering to the class:', error);
            return null;
        }
    };

    return registerClass;
};

export default useRegisterClass;
import React, { useState, useRef } from "react";
import Carousel from "react-bootstrap/Carousel";
import { PlayCircleFill } from "react-bootstrap-icons"; // For the fancy play button
import "./ProductCarousel.css"; // Import a CSS file for custom styles

const ProductCarousel = ({ images = [], product = {} }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  // Function to check if a URL is valid
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  // Check if product.video_url is present and valid
  const hasVideo = product?.video_url && isValidUrl(product.video_url);

  // Check if the video URL starts with the specified prefix
  const isS3VideoUrl = (url) => {
    return url?.startsWith("https://ghummantech-website.s3.us-east-2.amazonaws.com");
  };

  // Handle video play event
  const handleVideoPlay = (e) => {
    e.stopPropagation(); // Stop event propagation
    setIsVideoPlaying(true);
  };

  // Handle video pause event
  const handleVideoPause = (e) => {
    e.stopPropagation(); // Stop event propagation
    setIsVideoPlaying(false);
  };

  // Handle play button click
  const handlePlayButtonClick = (e) => {
    e.stopPropagation(); // Stop event propagation
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  // Handle carousel item click
  const handleCarouselItemClick = (e) => {
    e.stopPropagation(); // Stop event propagation
  };

  return (
    <div className="product-carousel-container" onClick={handleCarouselItemClick}>
      <Carousel interval={isVideoPlaying ? null : 5000}> {/* Pause carousel when video is playing */}
        {/* Add the video as the first item in the carousel if it matches the S3 URL */}
        {hasVideo && isS3VideoUrl(product.video_url) && (
          <Carousel.Item key="video">
            <div className="carousel-video-container-multiple" onClick={handleCarouselItemClick}>
              <video
                ref={videoRef}
                className="carousel-video"
                controls
                playsInline
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
              >
                <source src={product.video_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {!isVideoPlaying && (
                <div className="play-button-overlay" onClick={handlePlayButtonClick}>
                  <PlayCircleFill className="play-button" />
                </div>
              )}
            </div>
          </Carousel.Item>
        )}

        {/* Render the images */}
        {images.map((image, index) => (
          <Carousel.Item key={image} onClick={handleCarouselItemClick}>
            <div className="carousel-image-container-multiple">
              <img
                className="carousel-image"
                src={image}
                alt={`Product slide ${index + 1}`}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ProductCarousel;
import React, { useState } from 'react';
import './style.css';
import BurgerMenu from 'react-burger-menu';
import { slide as SlideMenu } from 'react-burger-menu';
import { useNavigate } from 'react-router-dom';
import BuyMembershipButton from './BuyMembershipButton';
import ProfileButton from './ProfileButton';
import GhummanRoboTrialButton from './GhummanRoboTrialButton';
// Import MUI Icons
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LanguageIcon from '@mui/icons-material/Language';
import CodeIcon from '@mui/icons-material/Code';
import SchoolIcon from '@mui/icons-material/School';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EventIcon from '@mui/icons-material/Event';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WorkIcon from '@mui/icons-material/Work';
import HelpIcon from '@mui/icons-material/Help';

const HamburgerMenu = (props) => {
  const navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = useState('slide');

  const contactOpen = () => {
    props.callbackClose();
    navigate('/ContactUs');
  };

  const goToCareers = () => {
    props.callbackClose();
    navigate('/Careers');
  };

  const aboutOpen = () => {
    props.callbackClose();
    navigate('/AboutUs');
  };

  const goToSeminars = () => {
    props.callbackClose();
    navigate('/Seminars');
  };

  const goToSoftwareServices = () => {
    props.callbackClose();
    navigate('/FreeAppForTeachers');
  };

  const goToSummerCamp = () => {
    props.callbackClose();
    navigate('/Camps/10');
  };

  const goToPAGE = (pageName, categoryName) => {
    props.callbackClose();
    navigate({
      pathname: `/Categories/${categoryName}`,
      state: { pageName: pageName, categoryName: categoryName },
    });
  };

  const goToHelp = () => {
    props.callbackClose();
    navigate('/Docs');
  };

  const getMenu = () => {
    if (!props.isOpenStatus) {
      return (
        <SlideMenu
          className="bm-menu"
          id={currentMenu}
          pageWrapId={props.pageWrapId}
          outerContainerId={props.outerContainerId}
          right={props.side === 'right'}
          isOpen={!props.isOpenStatus}
          disableOverlayClick={false}
          onStateChange={props.isMenuOpenFunc}
          customBurgerIcon={false}
          styles={{
            bmMenu: {
              background: 'white',
            },
            bmItemList: {
              background: 'white',
              color: 'black',
            },
            bmItem: {
              background: 'white',
              color: 'black',
            },
            bmItemSmall: {
              color: 'black',
            },
            bmCrossButton: {
              color: 'black',
              background: 'red',
              opacity: 1,
            },
            bmCross: {
              background: 'white',
            },
          }}
        >
          <div className="bm-item-list" style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <div style={{ borderBottom: 'solid' }}>
              <div className="bm-item" onClick={() => goToPAGE('Courses', 'courses')}>
                <MenuBookIcon style={{ marginRight: '8px' }} /> Courses
              </div>
              <div className="bm-sub-item" onClick={() => goToPAGE('Language Courses', 'languages')}>
                <LanguageIcon style={{ marginRight: '8px' }} /> Languages
              </div>
              <div className="bm-sub-item" onClick={() => goToPAGE('Programming Courses', 'programming')}>
                <CodeIcon style={{ marginRight: '8px' }} /> Programming
              </div>
              <div className="bm-sub-item" onClick={() => goToPAGE('Certification Courses', 'certifications')}>
                <SchoolIcon style={{ marginRight: '8px' }} /> Certifications
              </div>
              <div className="bm-sub-item" onClick={() => goToPAGE('Kid Courses', 'kids')}>
                <ChildCareIcon style={{ marginRight: '8px' }} /> Courses for Kids
              </div>
              <div className="bm-sub-item" style={{ color: 'red' }} onClick={() => goToPAGE('Instructor-Led', 'live')}>
                <LiveTvIcon style={{ marginRight: '8px' }} /> Instructor-Led
              </div>
            </div>
            <div className="bm-item" onClick={goToSoftwareServices} style={{ borderBottom: 'solid' }}>
              <SmartphoneIcon style={{ marginRight: '8px' }} /> Teach & Get an App
            </div>
            <div className="bm-item" onClick={() => goToSeminars('Seminars', 'seminars')} style={{ borderBottom: 'solid' }}>
              <EventIcon style={{ marginRight: '8px' }} /> Seminars
            </div>
            <div className="bm-item" onClick={() => goToPAGE('Books', 'books')} style={{ borderBottom: 'solid' }}>
              <MenuBookIcon style={{ marginRight: '8px' }} /> Books
            </div>
            {/* <div style={{ borderBottom: 'solid' }}>
              <div className="bm-item" onClick={goToSummerCamp}>
                <ChildCareIcon style={{ marginRight: '8px' }} /> Kids Camp 2025
              </div>
            </div> */}
            <div className="bm-item-small" onClick={aboutOpen}>
              <InfoIcon style={{ marginRight: '8px' }} /> About Us
            </div>
            <div className="bm-item-small" onClick={contactOpen}>
              <ContactMailIcon style={{ marginRight: '8px' }} /> Contact Us
            </div>
            <div className="bm-item-small" onClick={goToCareers}>
              <WorkIcon style={{ marginRight: '8px' }} /> Careers
            </div>
            <div className="bm-item-small" onClick={goToHelp}>
              <HelpIcon style={{ marginRight: '8px' }} /> Help
            </div>
            <div>
              <BuyMembershipButton />
            </div>
            <div style={{ marginTop: '10px' }}>
              <GhummanRoboTrialButton />
            </div>
            <div>
              <ProfileButton />
            </div>
          </div>
        </SlideMenu>
      );
    }
  };

  return <div>{getMenu()}</div>;
};

export default HamburgerMenu;
import React from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

function CourseTitleComponent({ formData, handleChange, error, helperText}, ref ) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleInfoClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const titleDescription =
    "Enter the title of your course. The title should be descriptive and give potential students a clear idea of what the course is about.";

  return (
    <Box className="course-title" sx={{ marginBottom: 3 }} ref={ref}>
      <FormControl component="fieldset" fullWidth error={error}>
        <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Course Title *
        </FormLabel>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <TextField
              label="Course Title"
              name="courseName"
              placeholder="Enter the course title"
              value={formData.courseName}
              onChange={handleChange}
              fullWidth
              error={error}
              aria-describedby="course-title-helper-text"
              inputProps={{
                "aria-label": "Course Title",
              }}
            />
          </Grid>
          <Grid item>
            {!isMobile && (
              <Tooltip title={titleDescription} arrow>
                <InfoIcon
                  fontSize="small"
                  sx={{ marginLeft: 1, color: "action.active", cursor: "pointer" }}
                  aria-label="Course title information"
                />
              </Tooltip>
            )}
            {isMobile && (
              <Button
                variant="text"
                size="small"
                onClick={handleInfoClick}
                sx={{ textTransform: "none" }}
                aria-label="Course title information"
              >
                <InfoIcon fontSize="small" sx={{ color: "action.active" }} />
              </Button>
            )}
          </Grid>
        </Grid>
        {helperText && (
          <Typography
            id="course-title-helper-text"
            variant="caption"
            color={error ? "error" : "textSecondary"}
            sx={{ marginTop: 1, display: "block" }}
          >
            {helperText}
          </Typography>
        )}
      </FormControl>

      {/* Mobile Dialog for Title Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>About Course Title</DialogTitle>
        <DialogContent>
          <Typography>{titleDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const CourseTitle = React.forwardRef(CourseTitleComponent);

export default CourseTitle;
import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone"; // Import moment-timezone

const durationOptions = [
  "25 minutes per session",
  "30 minutes per session",
  "55 minutes per session",
  "60 minutes per session",
];

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const levelOptions = ["Beginner", "Intermediate", "Advanced", "K-12"];

function CourseTimingAndLevelComponent({ formData, handleChange, error, helperText }, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTime, setSelectedTime] = useState(new Date().setHours(19, 0, 0)); // Default to 7:00 PM
  const [timezone, setTimezone] = useState(formData.timeZone || moment.tz.guess()); // Use formData.timeZone or guess the user's timezone
  const [isTimeDisabled, setIsTimeDisabled] = useState(false); // State to track if TimePicker should be disabled

  // Get the list of timezones from moment-timezone
  const timeZones = moment.tz.names();

  useEffect(() => {
    // Initialize sessionSchedule with default values when the component mounts
    if (formData.sessionSchedule) {
      parseSessionSchedule(formData.sessionSchedule);
    } else {
      updateSessionSchedule(selectedDays, selectedTime, timezone);
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  const parseSessionSchedule = (sessionSchedule) => {
    try {
      const [daysPart, timePart, tzPart] = sessionSchedule.split(" at ");
      const days = daysPart.replace("Every ", "").split(", ");
      const time = new Date();
      const [hours, minutes] = timePart.split(" ")[0].split(":");
      const period = timePart.split(" ")[1];
      time.setHours(period === "PM" ? parseInt(hours) + 12 : parseInt(hours));
      time.setMinutes(parseInt(minutes));
      setSelectedDays(days);
      setSelectedTime(time);
    } catch (error) {
      console.error("Failed to parse session schedule:", error);
      setSelectedDays(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]);
      setSelectedTime(new Date().setHours(19, 0, 0)); // Default to 7:00 PM
      setTimezone(moment.tz.guess());
    }
  };

  const handleInfoClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDayChange = (day) => {
    const updatedDays = selectedDays.includes(day)
      ? selectedDays.filter((d) => d !== day)
      : [...selectedDays, day];
    setSelectedDays(updatedDays);

    // Check if all days are deselected
    if (updatedDays.length === 0) {
      setIsTimeDisabled(true); // Disable the TimePicker
      handleChange({
        target: {
          name: "sessionSchedule",
          value: "Self Paced",
        },
      });
    } else {
      setIsTimeDisabled(false); // Enable the TimePicker
      updateSessionSchedule(updatedDays, selectedTime, timezone);
    }
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    updateSessionSchedule(selectedDays, time, timezone);
  };

  const handleTimezoneChange = (e) => {
    const newTimezone = e.target.value;
    setTimezone(newTimezone);
    handleChange({
      target: {
        name: "timeZone",
        value: newTimezone,
      },
    });
    updateSessionSchedule(selectedDays, selectedTime, newTimezone);
  };

  const updateSessionSchedule = (days, time, timezone) => {
    const formattedTime = new Date(time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    const sessionSchedule = `Every ${days.join(", ")} at ${formattedTime} ${timezone}`;
    handleChange({
      target: {
        name: "sessionSchedule",
        value: sessionSchedule,
      },
    });
  };

  const timingDescription =
    "Set the duration, schedule, and level of your course. The session schedule will automatically update based on your selections.";

  return (
    <Box className="course-timing-and-level" sx={{ marginBottom: 3 }}>
      <FormControl component="fieldset" fullWidth error={error}>
        <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Course Timing and Level *
        </FormLabel>
        <Grid container spacing={3}>
          {/* Duration */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Duration</InputLabel>
              <Select
                value={formData.duration || "55 minutes per session"}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "duration",
                      value: e.target.value,
                    },
                  })
                }
                label="Duration"
              >
                {durationOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Level */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Level</InputLabel>
              <Select
                value={formData.level || "Beginner"}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "level",
                      value: e.target.value,
                    },
                  })
                }
                label="Level"
              >
                {levelOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Session Schedule */}
          <Grid item xs={12} ref={ref}>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              Session Schedule
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {daysOfWeek.map((day) => (
                <Button
                  key={day}
                  variant={selectedDays.includes(day) ? "contained" : "outlined"}
                  onClick={() => handleDayChange(day)}
                >
                  {day}
                </Button>
              ))}
            </Box>
          </Grid>

          {/* Time and Timezone */}
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="Session Time"
                value={selectedTime}
                onChange={handleTimeChange}
                disabled={isTimeDisabled} // Disable the TimePicker directly
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Timezone</InputLabel>
              <Select
                value={timezone}
                onChange={handleTimezoneChange}
                label="Timezone"
              >
                {timeZones.map((tz) => (
                  <MenuItem key={tz} value={tz}>
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Helper Text */}
          {helperText && (
            <Grid item xs={12}>
              <Typography variant="caption" color={error ? "error" : "textSecondary"}>
                {helperText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </FormControl>

      {/* Mobile Dialog for Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>About Course Timing and Level</DialogTitle>
        <DialogContent>
          <Typography>{timingDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const CourseTimingAndLevel = React.forwardRef(CourseTimingAndLevelComponent);

export default CourseTimingAndLevel;
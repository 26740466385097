import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import { Pagination, PaginationItem, Card, CardContent, CardMedia, Typography, Box, Alert, CircularProgress, Rating, Chip } from "@mui/material";
import { FirstPage, LastPage, FavoriteBorder } from "@mui/icons-material";
import capitalizeName from "../../Utils/capitalizeName";

const FavoriteCoursesTabPanel = () => {
  const [favoriteCourses, setFavoriteCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFavoriteCourses = async () => {
      try {
        const email = localStorage.getItem('email');
        const baseUrl = getBaseUrl();

        const dataRequest = {
          page: currentPage,
          pageSize: 10,
          favorite_only: true,
          user_email: email
        };

        const response = await fetch(`${baseUrl}/api/products`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setFavoriteCourses(data.products);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFavoriteCourses();
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const goToProduct = (product) => {
    navigate({
      pathname: `/Categories/Product/${product.sku}`,
      state: { productClicked: product },
    });
  };

  if (loading) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100%"><CircularProgress /></Box>;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box>
      {favoriteCourses.length > 0 ? (
        <Box>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {favoriteCourses.map(course => (
              <Card 
                key={course.id} 
                sx={{ width: 345, m: 2, display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
                onClick={() => goToProduct(course)}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={course.images[0]}
                  alt={course.title}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="div">
                    {course.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {course.price === 0 ? (
                      <Chip label="FREE!" color="success" />
                    ) : (
                      `${course.currencyFormat}${course.price} per month`
                    )}
                  </Typography>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Rating value={parseFloat(course.average_rating)} precision={0.5} readOnly />
                    <Typography variant="body2" color="text.secondary" ml={1}>
                      ({course.total_ratings} ratings)
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    {course.owner === 'ghummantech@gmail.com' ? (
                      <Chip label="Ghumman Tech" color="error" />
                    ) : (
                      <Chip label={capitalizeName(course.owner_name)} sx={{ backgroundColor: 'black', color: 'white' }} />
                    )}
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              showFirstButton
              showLastButton
              renderItem={(item) => (
                <PaginationItem
                  components={{ first: FirstPage, last: LastPage }}
                  {...item}
                />
              )}
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
          <FavoriteBorder sx={{ fontSize: 60, color: 'gray' }} />
          <Typography variant="h6" color="textSecondary" mt={2}>
            No favorite courses found.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FavoriteCoursesTabPanel;
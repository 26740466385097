import React, { useState, useEffect } from "react";
import "./style.css";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { useNavigate } from "react-router-dom";
import { authStore } from '../../stores/storeContext';
import { observer } from "mobx-react-lite";


const Payment = observer((props) => {

  const navigate = useNavigate();

  const [paymentMessage, setPaymentMessage] = useState('');
  const [globalUsername, setGlobalUsername] = useState('');
  const [globalPassword, setGlobalPassword] = useState('');
  const [globalEmail, setGlobalEmail] = useState('');
  const [globalLoginType, setGlobalLoginType] = useState('');

  const signMeOut = () => {
    localStorage.setItem('USERNAME', '');
    localStorage.setItem('PASSWORD', '');
    localStorage.setItem('LOGIN_TYPE', '');
    localStorage.setItem('ACCOUNT_TYPE', '');
    localStorage.setItem('EMAIL', '');
    // props.signout();
    authStore.signout();
    navigate({ pathname: '/' });
  };


  useEffect(() => {
    setGlobalLoginType(localStorage.getItem('login_type'));

    if (localStorage.getItem('login_type') !== 'special') {
      setGlobalUsername(localStorage.getItem('username'));
      setGlobalPassword(localStorage.getItem('password'));
    } else {
      setGlobalEmail(localStorage.getItem('email'));
    }
  }, []);


  const handleToken = () => {
    if (globalLoginType !== 'special') {
      fetch('https://stealthdocumentation.com/dourbeen_api_pdo.php?apicall=silverMember', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `username=${globalUsername.trim()}&password=${globalPassword.trim()}&accountType=silver`
      })
        .then(async function (data) {
          data.json().then(async function (data) {
            if (data.message == 'Became silver member') {
              setPaymentMessage('Thank you. Payment was successful. You are a silver member now. Ads will be removed');
            } else {
              setPaymentMessage(data.message);
            }
          });
        });
    } else {
      fetch('https://stealthdocumentation.com/dourbeen_api_pdo.php?apicall=silverMemberSpecial', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `email=${globalEmail.trim()}&accountType=silver`
      })
        .then(async function (data) {
          data.json().then(async function (data) {
            if (data.message == 'Became silver member') {
              setPaymentMessage('Thank you. Payment was successful. You are a silver member now. Ads will be removed');
            } else {
              setPaymentMessage(data.message);
            }
          });
        });
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <Button onClick={() => signMeOut()}>SIGN OUT</Button>
        <p style={{ paddingTop: 10 }}>
          Remove ads by becoming Silver Member for just $2.
        </p>
        <div style={{ padding: 20 }}>
          <StripeCheckout
            token={handleToken}
            stripeKey="pk_live_zed2dWneRMXvc1gfUmwFU99t"
            name="Stealth Documentation" // the pop-in header title
            description="Thank you for becoming silver member" // the pop-in header subtitle
            image="https://www.stealthdocumentation.com/SD_logo4.png"
            ComponentClass="div"
            label="Buy Using Secured Stripe Payment" // text inside the Stripe button
            panelLabel="Give Money" // prepended to the amount in the bottom pay button
            amount={200} // cents
            currency="USD"
            // Note: Enabling either address option will give the user the ability to
            // fill out both. Addresses are sent as a second parameter in the token callback.
            billingAddress={true}
            zipCode={true}
            allowRememberMe // "Remember Me" option (default true)
          />
        </div>
        {paymentMessage}

        <Link to="/Profile" className="btn btn-link">
          Back
        </Link>
      </header>
    </div>
  );
})

export default Payment;
import React, { useState, useRef } from "react";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { PlayCircleFill } from "react-bootstrap-icons"; // For the round play button
import "./ProductCarousel.css"; // Import custom styles

const ProductCarousel = ({ images = [], product = {}, videoUrl }) => {
  const [showModal, setShowModal] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  // Function to check if a URL is valid
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  // Check if product.video_url is present and valid
  const hasVideo = (product?.video_url && isValidUrl(product.video_url)) || videoUrl;

  // Check if the video URL is hosted on S3
  const isS3VideoUrl = (url) => {
    return url?.startsWith("https://ghummantech-website.s3.us-east-2.amazonaws.com");
  };

  // Convert Google Drive shareable link to embed URL
  const getGoogleDriveEmbedUrl = (url) => {
    if (!url) return null;

    // Extract the file ID from the Google Drive URL
    const fileIdMatch = url.match(/\/d\/([^\/]+)/);
    if (fileIdMatch && fileIdMatch[1]) {
      return `https://drive.google.com/file/d/${fileIdMatch[1]}/preview`;
    }

    return null;
  };

  // Handle video play event
  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };

  // Handle video pause event
  const handleVideoPause = () => {
    setIsVideoPlaying(false);
  };

  // Handle play button click
  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div className="product-carousel-container">
      {/* Carousel */}
      <Carousel interval={isVideoPlaying ? null : 5000}>
        {/* Add the video as the first item in the carousel if it's hosted on S3 */}
        {hasVideo && isS3VideoUrl(product.video_url) && (
          <Carousel.Item key="video">
            <div className="carousel-video-container">
              <video
                ref={videoRef}
                className="carousel-video"
                controls
                playsInline
                onPlay={handleVideoPlay}
                onPause={handleVideoPause}
              >
                <source src={product.video_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {!isVideoPlaying && (
                <div className="play-button-overlay" onClick={handlePlayButtonClick}>
                  <PlayCircleFill className="play-button" />
                </div>
              )}
            </div>
          </Carousel.Item>
        )}

        {/* Render the images */}
        {images.map((image, index) => (
          <Carousel.Item key={image}>
            <div className="carousel-image-container">
              <img
                className="carousel-image"
                src={image}
                alt={`Product slide ${index + 1}`}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Show the "Watch Teacher Video!" button if there's a video */}
      {hasVideo && (
        <div className="watch-video-container">
          <Button
            variant="primary"
            onClick={() => setShowModal(true)}
            disabled={isVideoPlaying} // Disable button when video is playing
          >
            Watch Teacher Video!
          </Button>
        </div>
      )}

      {/* Modal for displaying the video */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{product.title || "Video"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video-container">
            {/* Render the video in the modal */}
            {isS3VideoUrl(product.video_url) ? (
              <video
                className="modal-video"
                controls
                playsInline
                autoPlay
              >
                <source src={product.video_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : videoUrl?.includes("youtube.com") || videoUrl?.includes("youtu.be") ? (
              <iframe
                className="modal-video"
                src={videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <iframe
                className="modal-video"
                src={getGoogleDriveEmbedUrl(product.video_url)}
                title="Google Drive video player"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProductCarousel;
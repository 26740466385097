import React from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { toast } from "react-toastify";

const CouponManager = ({ coupons, setCoupons }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Allowed discount values
  const allowedDiscounts = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  // Helper function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => new Date().toISOString().split("T")[0];

  // Helper function to validate dates
  const validateDates = (validFrom, validUntil) => {
    const today = getTodayDate();
    return (
      validFrom >= today && // valid_from must be today or later
      validUntil >= today && // valid_until must be today or later
      validUntil > validFrom // valid_until must be after valid_from
    );
  };

  const addCoupon = () => {
    if (coupons.length < 10) {
      setCoupons([
        ...coupons,
        {
          discount: "",
          valid_from: getTodayDate(), // Default to today's date
          valid_until: "",
          reusable: false,
        },
      ]);
    } else {
      toast.info("Maximum of 10 coupons allowed.");
    }
  };

  const removeCoupon = (index) => {
    const newCoupons = [...coupons];
    newCoupons.splice(index, 1);
    setCoupons(newCoupons);
  };

  const handleCouponChange = (index, field, value) => {
    const newCoupons = [...coupons];
    newCoupons[index][field] = value;

    // Validate dates when either valid_from or valid_until changes
    if (field === "valid_from" || field === "valid_until") {
      const validFrom = newCoupons[index].valid_from;
      const validUntil = newCoupons[index].valid_until;

      if (validFrom && validUntil && !validateDates(validFrom, validUntil)) {
        toast.error("Invalid dates: valid_until must be after valid_from and both must be today or later.");
        return; // Prevent updating the state if dates are invalid
      }
    }

    setCoupons(newCoupons);
  };

  return (
    <Grid item xs={12}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        Coupons
      </Typography>
      {coupons.length === 0 ? (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addCoupon}
          sx={{ mb: 2 }}
        >
          Create Coupon
        </Button>
      ) : (
        <>
          {coupons.map((coupon, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id={`discount-label-${index}`}>Discount (%)</InputLabel>
                  <Select
                    labelId={`discount-label-${index}`}
                    value={coupon.discount}
                    onChange={(e) =>
                      handleCouponChange(index, "discount", e.target.value)
                    }
                    label="Discount (%)"
                    size={isMobile ? "small" : "medium"}
                  >
                    {allowedDiscounts.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}%
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Valid From"
                  type="date"
                  value={coupon.valid_from}
                  onChange={(e) =>
                    handleCouponChange(index, "valid_from", e.target.value)
                  }
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  size={isMobile ? "small" : "medium"}
                  inputProps={{
                    min: getTodayDate(), // Prevent selecting a date before today
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Valid Until"
                  type="date"
                  value={coupon.valid_until}
                  onChange={(e) =>
                    handleCouponChange(index, "valid_until", e.target.value)
                  }
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  size={isMobile ? "small" : "medium"}
                  inputProps={{
                    min: coupon.valid_from || getTodayDate(), // Prevent selecting a date before valid_from
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <RadioGroup
                  value={coupon.reusable ? "true" : "false"}
                  onChange={(e) =>
                    handleCouponChange(index, "reusable", e.target.value === "true")
                  }
                  row={!isMobile}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio size={isMobile ? "small" : "medium"} />}
                    label="One Time Use"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio size={isMobile ? "small" : "medium"} />}
                    label="Unlimited Use"
                  />
                </RadioGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <IconButton
                  color="error"
                  onClick={() => removeCoupon(index)}
                  size={isMobile ? "small" : "medium"}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addCoupon}
            disabled={coupons.length >= 10}
            sx={{ mt: 1 }}
          >
            Add Coupon
          </Button>
        </>
      )}
    </Grid>
  );
};

export default CouponManager;
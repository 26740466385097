// components/ProductElaboratedDetailsHeader.js
import React from "react";
import { Typography, Paper, Divider, Grid, useTheme } from "@mui/material";
import styled, { ThemeProvider } from "styled-components";
import { DateTime } from "luxon";
import {
  AccessTime as TimeIcon,
  CalendarToday as CalendarIcon,
  School as LevelIcon,
  Language as LanguageIcon,
  People as SeatsIcon,
  ChildFriendly as AgeIcon,
  CardMembership as CertificationIcon,
  PlayCircleFilled as DemoIcon,
} from "@mui/icons-material";

// Styled components
const HeaderContainer = styled(Paper)`
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const DetailItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IconWrapper = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
`;

const ProductElaboratedDetailsHeader = ({ product }) => {
  const theme = useTheme(); // Get the Material-UI theme

  const {
    duration,
    level,
    language,
    total_seats,
    min_age,
    max_age,
    certification,
    free_demos_offered,
    start_date,
    end_date,
    session_schedule,
  } = product;

  // Function to determine age range category
  const getAgeRangeCategory = (minAge, maxAge) => {
    minAge = minAge === null || minAge === undefined ? 0 : minAge;
    maxAge = maxAge === null || maxAge === undefined || maxAge > 18 ? 99 : maxAge;

    const categories = [];

    if (minAge <= 2 && maxAge >= 0) categories.push("Toddler");
    if (minAge <= 5 && maxAge >= 3) categories.push("Preschooler");
    if (minAge <= 10 && maxAge >= 6) categories.push("Elementary");
    if (minAge <= 13 && maxAge >= 11) categories.push("Junior");
    if (minAge <= 17 && maxAge >= 14) categories.push("Senior");
    if (minAge >= 18) categories.push("Adults");

    return categories.join(", ");
  };

  // Function to check if session_schedule contains valid days
  const isValidSessionSchedule = (schedule) => {
    const daysRegex = /Every\s+(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)/i;
    return daysRegex.test(schedule);
  };

// Function to convert time to user's local timezone
const convertToLocalTime = (schedule) => {
    // Updated regex to capture the time and timezone correctly
    const timeRegex = /(\d{1,2}:\d{2}\s+[AP]M)\s+([A-Za-z_]+\/[A-Za-z_]+)/i;
    const match = schedule.match(timeRegex);
  
    if (!match) return schedule;
  
    const [_, time, timezone] = match;
  
    // Parse the time and timezone using Luxon
    const teacherTime = DateTime.fromString(time, "hh:mm a", { zone: timezone });
  
    // Convert to the user's local timezone
    const localTime = teacherTime.setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const formattedLocalTime = localTime.toFormat("hh:mm a");
  
    // Replace the original time and timezone with the converted time and user's timezone
    return schedule.replace(timeRegex, `${formattedLocalTime} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`);
  };

  // Function to format date in a descriptive way (e.g., "January 1, 2023")
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = DateTime.fromISO(dateString);
    return date.toFormat("MMMM d, yyyy");
  };

  const ageRange = getAgeRangeCategory(min_age, max_age);
  const showSessionSchedule = session_schedule && isValidSessionSchedule(session_schedule);
  const localSessionSchedule = showSessionSchedule ? convertToLocalTime(session_schedule) : null;

  const formattedStartDate = formatDate(start_date);
  const formattedEndDate = formatDate(end_date);

  return (
    <ThemeProvider theme={theme}>
      <HeaderContainer elevation={3}>
        <Grid container spacing={3}>
          {duration && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <TimeIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Duration
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {duration}
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}

          {level && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <LevelIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Level
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {level}
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}

          {language && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <LanguageIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Language
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {language}
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}

          {total_seats > 0 && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <SeatsIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Total Seats
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {total_seats}
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}

          {ageRange && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <AgeIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Age Range
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {ageRange}
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}

          {certification === 1 && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <CertificationIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Certification
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    Certificate Provided
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}

          {free_demos_offered === 1 && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <DemoIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Free Demos
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    Free Demo Classes Available
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}

          {formattedStartDate && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <CalendarIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Start Date
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {formattedStartDate}
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}

          {formattedEndDate && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <CalendarIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    End Date
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {formattedEndDate}
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}

          {showSessionSchedule && (
            <Grid item xs={12} sm={6} md={4}>
              <DetailItem>
                <IconWrapper>
                  <TimeIcon />
                </IconWrapper>
                <div>
                  <Typography variant="subtitle1" color="textSecondary">
                    Schedule
                  </Typography>
                  <Typography variant="body1" fontWeight="500">
                    {localSessionSchedule}
                  </Typography>
                </div>
              </DetailItem>
            </Grid>
          )}
        </Grid>
        <Divider sx={{ my: 2 }} />
      </HeaderContainer>
    </ThemeProvider>
  );
};

export default ProductElaboratedDetailsHeader;
import React from 'react';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import Button from '@mui/material/Button';
import roboticsArm from './trialClass.jpg';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

const FreeTrialClassMainPageAd = () => {
    const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });
    const navigate = useNavigate();
    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', flexDirection: isDesktopOrLarger ? 'row' : 'column', marginBottom: '60px', marginTop: '60px' }}>
            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', justifyContent: 'flex-start', margin: '20px', alignContent: 'center' }}>
                <div style={{ flex: 1, marginLeft: '20px', fontSize: '30px', fontWeight: 'bold', fontFamily: 'Courier New', color: '#333' }}>
                    Create. Explore. Innovate.
                </div>
                <div style={{ flex: 2, marginLeft: '20px', fontSize: '40px', fontWeight: 'bold', fontFamily: 'Courier New', color: '#007BFF' }}>
                    Unlock Your Potential with Ghumman Tech!
                </div>
                <div style={{ flex: 2, marginLeft: '20px', fontSize: '24px', fontWeight: 'bold', fontFamily: 'Courier New', color: '#FFA500' }}>
                    From Robotics and AI to Academic Excellence, Specialized Learning, and Extracurricular Activities – We Offer It All!
                </div>
                <div style={{ flex: 2, marginLeft: '20px', fontSize: '20px', fontFamily: 'Courier New', color: '#333', marginTop: '20px' }}>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                        <li><strong>Academic Excellence:</strong> Math, Science, Languages, and more for K-12 students.</li>
                        <li><strong>Tech & Coding:</strong> Python, Scratch, Java, C++, Robotics, AI, and Game Development.</li>
                        <li><strong>Outdoor & Extracurricular Activities:</strong> Hands-on learning beyond traditional classrooms.</li>
                        <li><strong>Specialized Learning:</strong> Tailored programs for Dentistry, Autism, Special Needs Education, and Healthcare.</li>
                    </ul>
                </div>
                <div style={{ flex: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <Button
                        variant="outlined"
                        startIcon={<PrecisionManufacturingIcon />}
                        style={{ color: 'red', borderColor: 'red', padding: '20px', fontSize: '20px' }}
                        onClick={() => navigate('/FreeCourseTrial')}
                    >
                        Book Free Trial Class
                    </Button>
                </div>
            </div>
            <div style={{ display: 'flex', flex: 4, margin: '20px', overflow: 'auto' }}>
                <img src={roboticsArm} alt="Robotics" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </div>
        </div>
    );
};

export default FreeTrialClassMainPageAd;
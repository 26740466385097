import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useStore } from "../../stores/storeContext";
import { useProductDetails } from "./hooks/useProductDetails";
import ProductDetailsUI from "./components/ProductDetailsUI";
import SignInPopup from '../Header/SignInPopup';
import { getBaseUrl } from "../Utils/getBaseUrl";
import ProductDetailsContent from "./ProductDetailsContent";
import ProductCarouselComponent from "./ProductCarouselComponent";

const EMAIL = "email";

const ProductDetails = () => {
  const { id } = useParams();
  const currentUrl = useLocation().pathname;
  const navigate = useNavigate();
  const [showSignInPopup, setShowSignInPopup] = useState(false);

  const { cartStore, totalStore } = useStore();

  const {
    email,
    setEmail,
    menuHidden,
    setMenuHidden,
    availableSizes,
    setAvailableSizes,
    isLoading,
    setIsLoading,
    selectedSizeIndex,
    setSelectedSizeIndex,
    selectedSizeName,
    setSelectedSizeName,
    selectedKey,
    setSelectedKey,
    copied,
    setCopied,
    eligible,
    setEligible,
    isCourseOwner,
    setIsCourseOwner,
    open,
    setOpen,
    message,
    setMessage,
    emailError,
    setEmailError,
    messageError,
    setMessageError,
    handleClickOpen,
    handleClose,
    handleSend,
    validateEmail,
    handleEmailChange,
    handleMessageChange,
    products,
    handleClick,
    handleFetchProduct,
    signMeIn,
    fillAvailableSizes,
    canItemBeAdded,
    notify,
    addThisProductToCart,
    sizeSelectedPressed,
    callbackRoutingCloseMenu,
    itemSizes,
    handleScheduleMeeting,
  } = useProductDetails({ setShowSignInPopup });

  const toggleFavorite = async (item) => {
    const userEmail = localStorage.getItem(EMAIL);
    if (!userEmail) {
      setShowSignInPopup(true);
      return;
    }

    const baseUrl = getBaseUrl();
    const dataRequest = { 
      email: userEmail,
      product_id: item.id,
    };

    const response = await fetch(`${baseUrl}/api/favorite-product`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataRequest),
    });

    if (response.ok) {
      const result = await response.json();
      const updatedFavoriteStatus = result.success && result.message === 'Product favorited';
      
      const updatedProducts = products.map(product => 
        product.id === item.id ? { ...product, favorite: updatedFavoriteStatus } : product
      );
      
      // Update the local products state in the hook
      setProducts(updatedProducts);
    }
  };

  const closeSignInPopup = () => {
    setShowSignInPopup(false);
  };

  const getYouTubeEmbedUrl = (url) => {
    if (!url) return null;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? `https://www.youtube.com/embed/${match[2]}` : null;
  };

  const videoUrl = products[0]?.video_url ? getYouTubeEmbedUrl(products[0].video_url) : null;

  return (
    <>
      <ProductDetailsUI
        products={products}
        showCarousel={<ProductCarouselComponent products={products} videoUrl={videoUrl}/>}
        productDetails={<ProductDetailsContent
          localProducts={products}
          selectedSizeIndex={selectedSizeIndex}
          sizeSelectedPressed={sizeSelectedPressed}
          itemSizes={itemSizes}
          addThisProductToCart={addThisProductToCart}
          handleClickOpen={handleClickOpen}
          open={open}
          handleClose={handleClose}
          email={email}
          message={message}
          handleEmailChange={handleEmailChange}
          handleMessageChange={handleMessageChange}
          handleSend={handleSend}
          emailError={emailError}
          messageError={messageError}
          eligible={eligible}
          navigate={navigate}
          handleClick={handleClick}
          selectedKey={selectedKey}
          handleScheduleMeeting={handleScheduleMeeting}
        />}
        isCourseOwner={isCourseOwner}
        videoUrl={videoUrl}
        isLoading={isLoading}
        toggleFavorite={toggleFavorite}
        currentUrl={currentUrl}
      />
      {showSignInPopup && <SignInPopup closePopup={closeSignInPopup} />}
    </>
  );
};

export default ProductDetails;
import React, { useState } from "react";
import "./style.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getBaseUrl } from '../Utils/getBaseUrl';
import { useNavigate } from "react-router-dom";

import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';

const Forgot = (props) => {

  const navigate = useNavigate();
  const [localEmail, setLocalEmail] = useState('');
  const [localPassword, setLocalPassword] = useState('');
  const [errorServerMessage, setErrorServerMessage] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const signMeIn = () => {
    navigate('/Login');
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const handleChangeEmail = (event) => {
    setFormEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = formEmail.trim();

    if (!email) {
      setErrorServerMessage('Email not provided');
      return;
    }

    try {
      const response = await fetch(`${getBaseUrl()}/api/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setErrorServerMessage("Password reset link is sent to your email.");
      } else {
        setErrorServerMessage(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cancelPressed = () => {
    navigate({
      pathname: "/Login"
    });
  }

  var errorMessage;
  if (errorServerMessage != "") {
    errorMessage = (
      <p
        style={{
          paddingTop: 20,
          color: "red"
        }}
      >
        {errorServerMessage}
      </p>
    );
  } else {
    errorMessage = null;
  }
  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />
        <div className="App">
          <header className="App-header">
            <p style={{ textDecorationLine: 'underline' }}>Welcome to Ghumman Tech</p>
            <p
              style={{
                borderStyle: "solid",
                borderColor: "black",
                borderRadius: 5,
                padding: 5
              }}
            >
              Forgot Password
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="loginFormEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={formEmail}
                  placeholder="Enter Email"
                  onChange={handleChangeEmail}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                FORGOT
              </Button>
            </Form>

            <div>
              <p
                style={{ fontSize: 14, paddingTop: 20 }}
                onClick={cancelPressed}
              >
                Cancel
              </p>
            </div>

            {errorMessage}
          </header>
        </div>
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
}

export default Forgot;
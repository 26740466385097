import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { School, CalendarToday } from '@mui/icons-material';

const ProductCarouselHeader = ({ product }) => {
  const { course_format, level } = product || {};

  // Tooltip content for course_format
  const courseFormatTooltip = {
    live: 'Live: Real-time sessions with an instructor.',
    'self-paced': 'Self-paced: Learn at your own pace with pre-recorded materials.',
    hybrid: 'Hybrid: A mix of live sessions and self-paced learning.',
  };

  // Tooltip content for level
  const levelTooltip = {
    Beginner: 'Beginner: No prior knowledge required.',
    Intermediate: 'Intermediate: Some prior knowledge recommended.',
    Advanced: 'Advanced: Designed for experienced learners.',
    'K-12': 'K-12: Tailored for school students.',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        padding: 2,
        backgroundColor: 'background.paper',
        borderRadius: 2,
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        marginBottom: 4, // Add bottom margin
        textAlign: 'center',
      }}
    >
      {/* Course Format */}
      {course_format && (
        <Tooltip
          title={courseFormatTooltip[course_format] || 'No details available.'}
          arrow
          placement="top"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer', // Indicate interactivity
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: 'primary.dark', // Dark background for icon
                color: 'white', // Icon color
              }}
            >
              <CalendarToday fontSize="small" />
            </Box>
            <Typography
              variant="subtitle1"
              sx={{ color: 'primary.dark', fontWeight: 'bold' }} // Match text color to icon background
            >
              {course_format.toUpperCase()}
            </Typography>
          </Box>
        </Tooltip>
      )}

      {/* Level */}
      {level && (
        <Tooltip
          title={levelTooltip[level] || 'No details available.'}
          arrow
          placement="top"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer', // Indicate interactivity
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: 'secondary.dark', // Dark background for icon
                color: 'white', // Icon color
              }}
            >
              <School fontSize="small" />
            </Box>
            <Typography
              variant="subtitle1"
              sx={{ color: 'secondary.dark', fontWeight: 'bold' }} // Match text color to icon background
            >
              {level.toUpperCase()}
            </Typography>
          </Box>
        </Tooltip>
      )}

      {/* Fallback Message */}
      {!course_format && !level && (
        <Typography variant="subtitle1" color="text.secondary">
          No course details available.
        </Typography>
      )}
    </Box>
  );
};

export default ProductCarouselHeader;
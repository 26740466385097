// components/ChatDialog.js
import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";

const ChatDialog = ({ open, handleClose, email, message, handleEmailChange, handleMessageChange, handleSend, emailError, messageError }) => {
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
      };
      
    return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Chat Now</DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you have any questions for the teacher of this course, please ask them now. The teacher will get back to you shortly.
        </DialogContentText>
        {/* <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address *"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError}
        /> */}
        <TextField
          margin="dense"
          id="message"
          label="Message"
          type="text"
          fullWidth
          variant="standard"
          multiline
          rows={4}
          value={message}
          onChange={handleMessageChange}
          error={!!messageError}
          helperText={messageError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSend}
          color="primary"
          disabled={
            !validateEmail(email) ||
            message.length === 0 ||
            message.length > 1000
          }
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatDialog;
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../Utils/getBaseUrl";
import Spinner from "../Spinner";
import { Button, TextField, Grid, Typography, Box, Paper } from "@mui/material";
import FileInput from "../CommonComponents/FileInput/FileInput";
import VideoUpload from "../CommonComponents/VideoUpload/VideoUpload";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CouponManager from "./CouponManager";

const uploadVideo = async (videoBlob) => {
  const baseUrl = getBaseUrl();
  const dataRequest = { folder: "TeachersVideos" };

  try {
    const response = await fetch(
      `${baseUrl}/api/get-presigned-url-for-aws-bucket-generic`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      const presignedUrl = responseData.bucket_url;

      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        headers: { "Content-Type": "video/webm" },
        body: videoBlob,
      });

      if (uploadResponse.ok) {
        return presignedUrl.split("?")[0];
      } else {
        throw new Error("Error uploading video");
      }
    } else {
      throw new Error("Error getting presigned URL");
    }
  } catch (error) {
    throw new Error(`Error: ${error.message}`);
  }
};

const formatDateTimeLocal = (dateTime) => {
  const date = new Date(dateTime);
  const pad = (num) => String(num).padStart(2, '0');
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
};

const getCurrentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

function SeminarEditDetails({ seminarData }) {
  const navigate = useNavigate();
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const presenterRef = useRef(null);
  const priceRef = useRef(null);
  const locationRef = useRef(null);
  const seminarDatetimeRef = useRef(null);
  const languageRef = useRef(null);
  const imagesRef = useRef(null);
  const videoChunksRef = useRef([]);

  const [formData, setFormData] = useState({
    seminar_id: seminarData.id,
    title: seminarData.title || "",
    description: seminarData.description || "",
    presenter: seminarData.presenter || "",
    price: seminarData.price || "",
    location: seminarData.location || "",
    seminar_datetime: seminarData.seminar_datetime ? formatDateTimeLocal(seminarData.seminar_datetime) : "",
    language: seminarData.language || "",
    type: "seminar",
    user_email: localStorage.getItem("email"),
    video_link: seminarData.video_link || "",
    coupons: seminarData.coupons || [],
    images: seminarData.images || [null, null, null],
    total_seats: seminarData.total_seats || "", // Add this line
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [currentTimezone, setCurrentTimezone] = useState(getCurrentTimezone());

  useEffect(() => {
    if (seminarData) {
      setFormData({
        seminar_id: seminarData.id,
        title: seminarData.title || "",
        description: seminarData.description || "",
        presenter: seminarData.presenter || "",
        price: seminarData.price || "",
        location: seminarData.location || "",
        seminar_datetime: seminarData.seminar_datetime ? formatDateTimeLocal(seminarData.seminar_datetime) : "",
        language: seminarData.language || "",
        type: "seminar",
        user_email: localStorage.getItem("email"),
        video_link: seminarData.video_link || "",
        coupons: seminarData.coupons || [],
        images: seminarData.images || [null, null, null],
        total_seats: seminarData.total_seats || "", // Add this line
      });
    }
  }, [seminarData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e, index) => {
    const files = e.target.files;
    const newImages = [...formData.images];
    newImages[index] = files[0];
    setFormData({ ...formData, images: newImages });
  };

  const handleSubmit = async () => {
    setErrors({});
    const errors = {};

    if (!formData.title) {
      errors.title = "Title is required";
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.description) {
      errors.description = "Description is required";
      descriptionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.presenter) {
      errors.presenter = "Presenter is required";
      presenterRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.price) {
      errors.price = "Price is required";
      priceRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.location) {
      errors.location = "Location is required";
      locationRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.seminar_datetime) {
      errors.seminar_datetime = "Seminar date and time are required";
      seminarDatetimeRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.language) {
      errors.language = "Language is required";
      languageRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.total_seats || !Number.isInteger(Number(formData.total_seats))) {
      errors.total_seats = "Total Participants Allowed must be a positive integer";
    } else if (Number(formData.total_seats) <= 0) {
      errors.total_seats = "Total Participants Allowed must be a positive integer";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setIsLoading(true);

      let videoUrl = formData.video_link;

      // If a recorded video is present, upload it first
      if (videoChunksRef.current.length > 0) {
        const videoBlob = new Blob(videoChunksRef.current, { type: "video/webm" });
        videoUrl = await uploadVideo(videoBlob);
      }

      const baseUrl = getBaseUrl();
      const formDataToSend = new FormData();
      formDataToSend.append("seminar_id", seminarData.id);
      formDataToSend.append("user_email", formData.user_email);

      // Append only the changed fields
      if (formData.title !== seminarData.title) {
        formDataToSend.append('title', formData.title);
      }
      if (formData.description !== seminarData.description) {
        formDataToSend.append('description', formData.description);
      }
      if (formData.presenter !== seminarData.presenter) {
        formDataToSend.append('presenter', formData.presenter);
      }
      if (formData.price !== seminarData.price) {
        formDataToSend.append('price', formData.price);
      }
      if (formData.location !== seminarData.location) {
        formDataToSend.append('location', formData.location);
      }
      if (formData.seminar_datetime !== seminarData.seminar_datetime) {
        formDataToSend.append('seminar_datetime', formData.seminar_datetime);
        formDataToSend.append('timezone', currentTimezone); // Append the current timezone
      }
      if (formData.language !== seminarData.language) {
        formDataToSend.append('language', formData.language);
      }
      if (videoUrl !== seminarData.video_link) {
        formDataToSend.append('video_link', videoUrl);
      }
      if (formData.total_seats !== seminarData.total_seats) {
        formDataToSend.append('total_seats', formData.total_seats); // Add this line
      }

      // Append coupons if they have changed
      if (JSON.stringify(formData.coupons) !== JSON.stringify(seminarData.coupons)) {
        formDataToSend.append('coupons', JSON.stringify(formData.coupons));
      }

      // Append images if they have changed
      formData.images.forEach((image, index) => {
        if (image && image !== seminarData.images[index]) {
          formDataToSend.append(`images`, image);
        }
      });

      const response = await fetch(`${baseUrl}/api/seminar/update`, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to update seminar");
      }
      toast.success("Seminar updated successfully");
      setErrorMessage("Seminar updated successfully.");
      navigate("/MyAccount/Teaching");
    } catch (error) {
      toast.error("Failed to update seminar. Please try again later.");
      console.error("Error updating seminar:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold" }}>
        Edit Seminar
      </Typography>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              inputRef={titleRef}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              inputRef={descriptionRef}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Presenter"
              name="presenter"
              value={formData.presenter}
              onChange={handleChange}
              fullWidth
              inputRef={presenterRef}
              error={!!errors.presenter}
              helperText={errors.presenter}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Price (USD)"
              name="price"
              value={formData.price}
              onChange={handleChange}
              fullWidth
              inputRef={priceRef}
              error={!!errors.price}
              helperText={errors.price}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              fullWidth
              inputRef={locationRef}
              error={!!errors.location}
              helperText={errors.location}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Seminar Date & Time"
              name="seminar_datetime"
              type="datetime-local"
              value={formData.seminar_datetime}
              onChange={handleChange}
              fullWidth
              inputRef={seminarDatetimeRef}
              error={!!errors.seminar_datetime}
              helperText={errors.seminar_datetime}
              InputLabelProps={{ shrink: true }}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Current Timezone: {currentTimezone}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Language"
              name="language"
              value={formData.language}
              onChange={handleChange}
              fullWidth
              inputRef={languageRef}
              error={!!errors.language}
              helperText={errors.language}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Total Participants Allowed"
              name="total_seats"
              value={formData.total_seats}
              onChange={handleChange}
              fullWidth
              type="number"
              inputProps={{ min: 1 }}
              error={!!errors.total_seats}
              helperText={errors.total_seats}
            />
          </Grid>
          <CouponManager coupons={[...formData.coupons]} setCoupons={(coupons) => setFormData({ ...formData, coupons })} />
          <Grid item xs={12}>
            <FileInput
              formData={formData}
              handleFileChange={handleFileChange}
              errors={errors}
              setErrors={setErrors}
              refs={{ imagesRef }}
            />
          </Grid>
          <Grid item xs={12}>
            <VideoUpload formData={formData} setFormData={setFormData} videoChunksRef={videoChunksRef} />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
              sx={{ px: 4, py: 1.5, fontSize: "1.1rem", textTransform: "none" }}
            >
              {isLoading ? <Spinner /> : "Update Seminar"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

export default SeminarEditDetails;
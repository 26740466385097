import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Chip,
  Card,
  CardContent,
  Rating,
  Box,
  IconButton,
  Typography,
  Tooltip,
  Modal,
  Button,
} from "@mui/material";
import {
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import capitalizeName from "../../../Utils/capitalizeName";
import { getBaseUrl } from "../../../Utils/getBaseUrl";
import SignInPopup from "../../../Header/SignInPopup";
import ProductCarousel from "./ProductCarousel";

const EMAIL = "email";

const formatPrice = (x, currency) => {
  if (x == null) return x;
  switch (currency) {
    case "BRL":
      return x.toFixed(2).replace(".", ",");
    default:
      return x.toFixed(2);
  }
};

const Product = ({ product, history, currency }) => {
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState(product.favorite);
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [quickViewOpen, setQuickViewOpen] = useState(false);

  product.quantity = 1;

  let formattedPrice = formatPrice(product.price, product.currencyId);

  const goToProduct = (product) => {
    navigate({
      pathname: `/Categories/Product/${product.sku}`,
      state: { productClicked: product },
    });
  };

  const toggleFavorite = async (e) => {
    e.stopPropagation();
    const userEmail = localStorage.getItem(EMAIL);
    if (!userEmail) {
      setShowSignInPopup(true);
      return;
    }

    const baseUrl = getBaseUrl();
    const dataRequest = {
      email: userEmail,
      product_id: product.id,
    };

    const response = await fetch(`${baseUrl}/api/favorite-product`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    });

    if (response.ok) {
      const result = await response.json();
      setFavorite(result.success && result.message === "Product favorited");
    }
  };

  const closeSignInPopup = () => {
    setShowSignInPopup(false);
  };

  const handleQuickViewOpen = (e) => {
    e.stopPropagation();
    setQuickViewOpen(true);
  };

  const handleQuickViewClose = () => {
    setQuickViewOpen(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Card
        onClick={() => goToProduct(product)}
        data-sku={product.sku}
        sx={{
          margin: "10px",
          cursor: "pointer",
          width: "250px",
          height: "450px",
          overflow: "hidden",
          borderRadius: "15px",
          boxShadow: isHovered
            ? "0 8px 16px rgba(0, 0, 0, 0.2)"
            : "0 4px 8px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          transform: isHovered ? "scale(1.05)" : "scale(1)",
          backgroundColor: isHovered ? "#f5f5f5" : "white",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            {/* <img
              style={{
                width: "100%",
                height: "150px",
                objectFit: "contain",
                borderRadius: "10px",
              }}
              src={product.images[0]}
              alt={product.title}
            /> */}
            <ProductCarousel images={product.images} product={product} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Times New Roman, serif',
                  fontWeight: "600",
                  fontSize: "18px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {product.title}
              </Typography>
              <Tooltip title={favorite ? "Remove from Favorites" : "Add to Favorites"}>
                <IconButton
                  onClick={toggleFavorite}
                  sx={{
                    color: favorite ? "red" : "gray",
                    "&:hover": {
                      color: favorite ? "darkred" : "red",
                    },
                  }}
                >
                  {favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </div>
          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              {product.price === 0 ? (
                <Chip
                  label="FREE !"
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "green",
                    color: "white",
                  }}
                />
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Times New Roman, serif',
                    fontWeight: "600",
                    color: "#333",
                  }}
                >
                  ${formattedPrice} <small>per month</small>
                </Typography>
              )}
              <Tooltip title="Quick View">
                <IconButton
                  onClick={handleQuickViewOpen}
                  sx={{
                    color: "gray",
                    "&:hover": {
                      color: "#007bff",
                    },
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {product.included_in_membership === 1 && (
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Times New Roman, serif',
                  color: "green",
                  marginTop: "5px",
                }}
              >
                Included in Membership
              </Typography>
            )}
            <Chip
              label={
                product.owner === "ghummantech@gmail.com" &&
                product.owner_name === "Ahmed Ghumman"
                  ? "Ghumman Tech"
                  : capitalizeName(product.owner_name)
              }
              variant="outlined"
              sx={{
                backgroundColor:
                  product.owner === "ghummantech@gmail.com" &&
                  product.owner_name === "Ahmed Ghumman"
                    ? "#f80103"
                    : "black",
                color: "white",
                padding: "5px 10px",
                marginTop: "10px",
                borderRadius: "20px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Rating
                name="read-only"
                value={parseFloat(product.average_rating)}
                precision={0.1}
                readOnly
                size="small"
              />
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Times New Roman, serif',
                  marginLeft: "5px",
                  fontWeight: "bold",
                }}
              >
                ({product.total_ratings})
              </Typography>
            </Box>
          </div>
        </CardContent>
      </Card>

      {/* Quick View Modal */}
      <Modal
        open={quickViewOpen}
        onClose={handleQuickViewClose}
        aria-labelledby="quick-view-modal"
        aria-describedby="quick-view-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "800px",
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "15px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            background: "linear-gradient(135deg, #f5f7fa, #c3cfe2)",
          }}
        >
          {/* Close Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 1,
              backgroundColor: "rgba(0, 0, 0, 0.05)",
            }}
          >
            <IconButton onClick={handleQuickViewClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Scrollable Content */}
          <Box
            sx={{
              overflowY: "auto",
              p: 3,
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "20px",
              }}
            >
              {/* Product Image */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={product.images[0]}
                  alt={product.title}
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    height: "auto",
                    borderRadius: "10px",
                    objectFit: "cover",
                    aspectRatio: "16/9",
                  }}
                />
              </Box>

              {/* Product Details */}
              <Box sx={{ flex: 2 }}>
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                  {product.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ mb: 2 }}
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
                <Typography variant="h6" sx={{ mb: 2 }}>
                  ${formattedPrice} <small>per month</small>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Rating
                    value={parseFloat(product.average_rating)}
                    precision={0.1}
                    readOnly
                  />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    ({product.total_ratings} reviews)
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Duration:</strong> {product.duration}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Level:</strong> {product.level}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Schedule:</strong> {product.session_schedule}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Time Zone:</strong> {product.time_zone}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => goToProduct(product)}
                  sx={{ mt: 2 }}
                >
                  View Full Details
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {showSignInPopup && <SignInPopup closePopup={closeSignInPopup} />}
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

export default Product;
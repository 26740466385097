// components/ProductFormat.js
import React from "react";
import { Button, Chip } from "@mui/material";
import CourseRating from "../CourseRating";
import capitalizeName from "../../Utils/capitalizeName";

const ProductFormatWithCurrency = ({ item, handleScheduleMeeting }) => {
  return (
    <>
      {item.price === 0 ? null : (
        <div style={{ textAlign: "center", fontWeight: "400", margin: "0 0 0.5em", lineHeight: "1.4", fontFamily: "auto" }}>
          {item.price !== item.actualPrice && (
            <div>
              🏷️ $<span style={{ textDecoration: "line-through" }}>{item.actualPrice} USD</span>
            </div>
          )}
          ${item.price} USD per month
        </div>
      )}
      {item.included_in_membership === 1 && (
        <div style={{ textAlign: "center", fontWeight: "400", margin: "0 0 0.5em", lineHeight: "1.4", fontFamily: "auto" }}>
          Included in Membership
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
        <Chip
          label={item.owner === "ghummantech@gmail.com" && item.owner_name === "Ahmed Ghumman" ? "Ghumman Tech" : capitalizeName(item.owner_name)}
          style={{
            backgroundColor: item.owner === "ghummantech@gmail.com" && item.owner_name === "Ahmed Ghumman" ? "#f80103" : "black",
            color: "white",
            padding: "5px 10px",
            borderRadius: "20px",
          }}
        />
      </div>
      <CourseRating item={item} />
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
        <Button variant="outlined" color="inherit" style={{ borderColor: "black", color: "black" }} onClick={() => handleScheduleMeeting(item.sku)}>
          SCHEDULE MEETING
        </Button>
      </div>
    </>
  );
};

export default ProductFormatWithCurrency;
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import PhoneAndGradeComponent from './PhoneAndGradeComponent';
import TimeSlotComponent from './TimeSlotComponent';
import StudentDetailsComponent from './StudentDetailsComponent';
import ShelfCollectionNew from './ShelfCollectionNew/ShelfCollectionNew';
import useRegisterClass from './useRegisterClass';
import Spinner from '../Spinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TrialDetails = () => {
    const isDesktopOrLarger = useMediaQuery({ minWidth: 768 });

    const [phone, setPhone] = useState('');
    const [childName, setChildName] = useState(null);
    const [parentName, setParentName] = useState(null);
    const [customerEmail, setCustomerEmail] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null); // New state for selected Product
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [errorPhone, setErrorPhone] = useState('');
    const [errorGrade, setErrorGrade] = useState('');
    const [errorZone, setErrorZone] = useState('');
    const [errorSlot, setErrorSlot] = useState('');
    const [errorDate, setErrorDate] = useState('');
    const [errorChildName, setErrorChildName] = useState('');
    const [errorParentName, setErrorParentName] = useState('');
    const [errorCustomerEmail, setErrorCustomerEmail] = useState('');
    const [selectGradeReady, setSelectGradeReady] = useState(false);
    const [selectProductReady, setSelectProductReady] = useState(false); 
    const [selectSlotReady, setSelectSlotReady] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [isLoading, setIsLoading] = useState(false);

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

    const dayAfterDayAfterTomorrow = new Date(today);
    dayAfterDayAfterTomorrow.setDate(dayAfterDayAfterTomorrow.getDate() + 3);
    const options = { weekday: 'long', day: '2-digit', month: 'short' };
    const availableDates = [tomorrow, dayAfterTomorrow, dayAfterDayAfterTomorrow].map(date =>
        new Intl.DateTimeFormat('en-US', options).format(date)
    );

    const availableTimeSlots = ['07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM'];

    const registerClass = useRegisterClass();

    useEffect(() => {
        setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }, []);

    const handleGradeSelectClick = () => {
        if (!phone) {
            setErrorPhone('Please enter your phone number');
        }
        if (!selectedGrade) {
            setErrorGrade('Please select a grade');
        }
        if (!phone || !selectedGrade) {
            return;
        }
        setSelectGradeReady(true);
    };

    const handleProductSelectClick = () => {
        if (!selectedProduct) {
            toast.warning('Please select a Class');
            return;
        }
        setSelectProductReady(true);
    };

    const handleTimeSlotClick = () => {
        if (!selectedTimezone) {
            setErrorZone('Please enter your time zone');
        }
        if (!selectedDate) {
            setErrorDate('Please select a date');
        }
        if (!selectedSlot) {
            setErrorSlot('Please select a time slot');
        }
        if (!selectedTimezone || !selectedDate || !selectedSlot) {
            return;
        }
        setSelectSlotReady(true);
    };

    const handleSubmitTrial = () => {
        if (!childName) {
            setErrorChildName('Please enter your child name');
        }
        if (!parentName) {
            setErrorParentName('Please enter your parent name');
        }
        let emailCorrect = true;
        if (!customerEmail) {
            setErrorCustomerEmail('Please enter your email');
            emailCorrect = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(customerEmail)) {
                setErrorCustomerEmail('Please enter a valid email');
                emailCorrect = false;
            }
        }
        if (!childName || !parentName || !emailCorrect) {
            return;
        }
        finalizeTrial();
    };

    const finalizeTrial = async () => {
        try {
            setIsLoading(true);
            const responseRegister = await registerClass(
                phone,
                childName,
                parentName,
                customerEmail,
                selectedGrade,
                selectedProduct,
                selectedSlot,
                selectedTimezone.value || selectedTimezone,
                selectedDate
            );
        } catch (error) {
            console.error('Error registering class', error);
        } finally {
            setIsLoading(false);
        };
    };

    return (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', flexDirection: isDesktopOrLarger ? 'row' : 'column' }}>
            {isLoading && <Spinner />}
            <div style={{ display: 'flex', flex: 4, margin: '20px', flexDirection: 'column'}}>
                {!selectGradeReady && (
                    <PhoneAndGradeComponent
                        onNext={handleGradeSelectClick}
                        setPhone={setPhone}
                        setSelectedGrade={setSelectedGrade}
                        phone={phone}
                        selectedGrade={selectedGrade}
                        errorPhone={errorPhone}
                        errorGrade={errorGrade}
                    />
                )}
                        {selectGradeReady && !selectProductReady && (
          <ShelfCollectionNew
            onNext={handleProductSelectClick}
            onBack={() => setSelectGradeReady(false)}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        )}
                {selectProductReady && !selectSlotReady && (
                    <TimeSlotComponent
                        onNext={handleTimeSlotClick}
                        onBack={() => setSelectProductReady(false)}
                        selectedTimezone={selectedTimezone}
                        setSelectedTimezone={setSelectedTimezone}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        errorZone={errorZone}
                        errorDate={errorDate}
                        errorSlot={errorSlot}
                        availableDates={availableDates}
                        availableTimeSlots={availableTimeSlots}
                    />
                )}
                {selectGradeReady && selectSlotReady && (
                    <StudentDetailsComponent
                        onSubmit={handleSubmitTrial}
                        onBack={() => setSelectSlotReady(false)}
                        childName={childName}
                        setChildName={setChildName}
                        parentName={parentName}
                        setParentName={setParentName}
                        customerEmail={customerEmail}
                        setCustomerEmail={setCustomerEmail}
                        errorChildName={errorChildName}
                        errorParentName={errorParentName}
                        errorCustomerEmail={errorCustomerEmail}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </div>
    );
};

export default TrialDetails;
import React, { useState, useEffect } from 'react';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import DocumentationDetails from './DocumentationDetails';
import { useNavigate } from 'react-router-dom';


const Documentation = () => {

  const [state, setState] = useState({
    isOpen: false,
    menuHidden: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.menuHidden) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'static';
    } else {
      document.body.style.overflow = 'visible';
      document.body.style.position = 'static';
    }
  }, [state.menuHidden]);

  useEffect(() => {
    document.body.style.overflow = 'visible';
    document.body.style.position = 'static';
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = 'All the session data will be lost. Are you sure you want to proceed?';
      event.preventDefault();
      event.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // For some older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const openFloatCart = () => {
    setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
  };

  const openMenuWindow = () => {
    setState((prevState) => ({ ...prevState, menuHidden: false }));
  };

  const isMenuOpen = (state) => {
    const menuHidden = state.isOpen === false;
    setState((prevState) => ({ ...prevState, menuHidden }));
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setState((prevState) => ({ ...prevState, menuHidden: true }));
  };

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={state.menuHidden}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} />
        <DocumentationDetails />
        <Footer />
        <FloatCart isTheCartOpen={state.isOpen} history={navigate} />
      </div>
    </div>
  );
};

export default Documentation;
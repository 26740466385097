import React, { useState, useEffect } from "react";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserInfo } from "../../Utils/userServices";
import { Button, Box, Typography, Paper } from "@mui/material";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import "./Auth.css";
import Spinner from "../../Spinner";
import { authStore } from '../../../stores/storeContext';
import { observer } from "mobx-react-lite";

const EMAIL = "email";

const Auth = observer(({ setIsAuthenticated, setIsEmailVerified, ...props }) => {
  const navigate = useNavigate();
  const [stage, setStage] = useState("login");
  const [errorServerMessage, setErrorServerMessage] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formFirstName, setFormFirstName] = useState("");
  const [formLastName, setFormLastName] = useState("");
  const [formPassword2, setFormPassword2] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChangeEmail = (event) => setFormEmail(event.target.value);
  const handleChangePassword = (event) => setFormPassword(event.target.value);
  const handleChangeFirstName = (event) => setFormFirstName(event.target.value);
  const handleChangeLastName = (event) => setFormLastName(event.target.value);
  const handleChangePassword2 = (event) => setFormPassword2(event.target.value);

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    if (formEmail.trim() === "" || formPassword.trim() === "") {
      toast.error("Please fill in all fields");
      return;
    }
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        email: formEmail.trim(),
        password: formPassword.trim(),
      };
      const response = await fetch(`${baseUrl}/api/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok && data.message === "Login successful") {
        toast.success("Login successful");
        // props.login(formEmail, formPassword);
        authStore.login(formEmail, formPassword);
        localStorage.setItem(EMAIL, dataRequest.email);
        checkUserVerification(dataRequest.email);
      } else {
        toast.error(data.error);
        setErrorServerMessage(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitRegister = async (event) => {
    event.preventDefault();
    const requiredFields = [
      formFirstName,
      formLastName,
      formEmail,
      formPassword,
      formPassword2,
    ];
    const emptyFields = requiredFields.filter((field) => !field.trim());
    if (emptyFields.length) {
      toast.error("Please fill in all required fields.");
      setErrorServerMessage("Please fill in all required fields.");
      return;
    }
    if (formPassword !== formPassword2) {
      toast.error("Passwords must match.");
      setErrorServerMessage("Passwords must match.");
      return;
    }
    const dataRequest = {
      first_name: formFirstName.trim(),
      last_name: formLastName.trim(),
      email: formEmail.trim(),
      password: formPassword.trim(),
    };
    try {
      const response = await fetch(`${getBaseUrl()}/api/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });
      const data = await response.json();
      if (
        response.ok &&
        data.message ===
          "Registration successful. Please check your email to verify your account."
      ) {
        toast.success(
          "Registration successful. Please check your email to verify your account."
        );
        setErrorServerMessage(data.message);
        localStorage.setItem(EMAIL, dataRequest.email);
        // props.login(dataRequest.email, dataRequest.password);
        authStore.login(dataRequest.email, dataRequest.password);
        checkUserVerification(dataRequest.email);
      } else {
        toast.error(data.error || "An error occurred. Please try again.");
        setErrorServerMessage(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
      setErrorServerMessage(error.message);
    }
  };

  const handleSubmitForgot = async (event) => {
    event.preventDefault();
    const email = formEmail.trim();
    if (!email) {
      setErrorServerMessage("Email not provided");
      return;
    }
    try {
      const response = await fetch(`${getBaseUrl()}/api/forgot-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success("Password reset link is sent to your email.");
        setErrorServerMessage("Password reset link is sent to your email.");
      } else {
        toast.error(data.error);
        setErrorServerMessage(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const signInUsingGoogle = async () => {
    const baseUrl = getBaseUrl();
    const state = "onboarding"; // Set the state to indicate onboarding
    window.location.href = `${baseUrl}/auth/google?state=${state}`;
  };

  const checkUserVerification = async (email) => {
    try {
      const userData = await getUserInfo(email);
      if (userData.email_verified === 1) {
        setIsAuthenticated(true);
        setIsEmailVerified(true);
        setMessage(
          <Paper
            elevation={3}
            sx={{
              padding: "20px",
              textAlign: "center",
              backgroundColor: "#e0f7fa",
              borderRadius: "10px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#00796b",
                fontFamily: "Cursive",
              }}
            >
              You're successfully logged in. Please proceed to next step.
            </Typography>
          </Paper>
        );
      } else {
        setIsAuthenticated(true);
        setIsEmailVerified(false);
        setMessage(
          <>
            <Typography variant="body1" align="center">
              Please click on the verification email sent to you and verify your
              account to proceed to next step.
            </Typography>
            <Box textAlign="center" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/MyAccount/Settings`)}
              >
                Resend Verification Email or Login with a Different Email
              </Button>
            </Box>
          </>
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      const baseUrl = getBaseUrl();

      const dataRequest = {
        token,
      };

      fetch(`${baseUrl}/decode-token-for-oauth-email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Login successful") {
            // props.login(data.email, null);
            authStore.login(data.email, null);
            localStorage.setItem(EMAIL, data.email);
            checkUserVerification(data.email);

            // Clear the token from the URL
            urlParams.delete("token");
            const newUrl = urlParams.toString()
              ? `${window.location.pathname}?${urlParams.toString()}`
              : window.location.pathname;
            window.history.replaceState({}, document.title, newUrl);
          } else {
            // Handle error state or message
          }
        })
        .catch((err) => {
          console.error(err);
          // Handle error message
        });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const email = localStorage.getItem("email");
      try {
        setIsLoading(true);
        if (email) {
          const userData = await getUserInfo(email);
          if (userData.email_verified !== 1) {
            toast.error("User should verify email before creating a course");
            setIsAuthenticated(true);
            setIsEmailVerified(false);
            setMessage(
              <>
                <Typography variant="body1" align="center">
                  Please click on the verification email sent to you and verify
                  your account to proceed to next step.
                </Typography>
                <Box textAlign="center" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/MyAccount/Settings`)}
                  >
                    Resend Verification Email or Login with a Different Email
                  </Button>
                </Box>
              </>
            );
          } else {
            setIsAuthenticated(true);
            setIsEmailVerified(true);
            setMessage(
              <Paper
                elevation={3}
                sx={{
                  padding: "20px",
                  textAlign: "center",
                  backgroundColor: "#e0f7fa",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "#00796b",
                    fontFamily: "Cursive",
                  }}
                >
                  You're successfully logged in. Please proceed to next step.
                </Typography>
              </Paper>
            );
          }
        }
      } catch (error) {
        console.error("Error:", error);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (message) {
    return (
      <div className="auth-container">
        <div className="message">{message}</div>
      </div>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="auth-container">
      {stage === "login" && (
        <div className="scrollable-content">
          <Login
            formEmail={formEmail}
            formPassword={formPassword}
            handleChangeEmail={handleChangeEmail}
            handleChangePassword={handleChangePassword}
            handleSubmitLogin={handleSubmitLogin}
            setStage={setStage}
            signInUsingGoogle={signInUsingGoogle}
            navigate={navigate}
          />
        </div>
      )}
      {stage === "register" && (
        <div className="scrollable-content">
          <Register
            formFirstName={formFirstName}
            formLastName={formLastName}
            formEmail={formEmail}
            formPassword={formPassword}
            formPassword2={formPassword2}
            handleChangeFirstName={handleChangeFirstName}
            handleChangeLastName={handleChangeLastName}
            handleChangeEmail={handleChangeEmail}
            handleChangePassword={handleChangePassword}
            handleChangePassword2={handleChangePassword2}
            handleSubmitRegister={handleSubmitRegister}
            setStage={setStage}
            signInUsingGoogle={signInUsingGoogle}
          />
        </div>
      )}
      {stage === "forgot" && (
        <div className="scrollable-content">
          <ForgotPassword
            formEmail={formEmail}
            handleChangeEmail={handleChangeEmail}
            handleSubmitForgot={handleSubmitForgot}
            setStage={setStage}
          />
        </div>
      )}
    </div>
  );
});

export default Auth; 
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Container,
  TextField,
  Button,
  Modal,
  Box,
  CircularProgress,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getBaseUrl } from '../../Utils/getBaseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SeminarsSection = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [seminars, setSeminars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [seminarToDelete, setSeminarToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSeminars = async () => {
      setIsLoading(true);
      try {
        const baseUrl = getBaseUrl();
  
        const dataRequest = {
          user_email: localStorage.getItem("email"),
        };
  
        const response = await fetch(`${baseUrl}/api/all-teacher-seminars`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataRequest),
        });
  
        if (response.ok) {
          const data = await response.json();
          setSeminars(data.seminars); // Access the seminars array from the response
        }
      } catch (e) {
        console.error('Error fetching seminars:', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSeminars();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEditClick = (event, seminar) => {
    event.stopPropagation();
    navigate('/SeminarEdit', { state: { seminar } });
  }

  const handleOpenSeminarClick = (seminar) => {
    navigate(`/Seminars/${seminar.id}`);
  }

  const handleDeleteClick = (event, seminar) => {
    event.stopPropagation();
    setSeminarToDelete(seminar);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = {
        seminar_id: seminarToDelete.id,
        user_email: localStorage.getItem("email"),
      };

      const response = await fetch(`${baseUrl}/api/seminar/delete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      if (response.ok) {
        setSeminars(seminars.filter(seminar => seminar.id !== seminarToDelete.id));
        setShowModal(false);
        setSeminarToDelete(null);
        toast.success('Seminar deleted successfully');
      } else {
        console.error('Error deleting seminar');
        toast.error('Error deleting seminar');
      }
    } catch (e) {
      console.error('Error deleting seminar:', e);
      toast.error('Error deleting seminar');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSeminarToDelete(null);
  };

  const handleCopyCouponCode = (event, couponCode) => {
    event.stopPropagation();
    navigator.clipboard.writeText(couponCode);
    toast.success('Coupon code copied to clipboard!');
  };

  const filteredSeminars = seminars.filter(seminar => {
    const matchesSearch = seminar.title.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesSearch;
  });

  return (
    <div style={{ fontFamily: 'Times New Roman' }}>
      {isLoading && <CircularProgress />}
      <div>
        <TextField
          type="text"
          placeholder="Search by Name"
          value={searchQuery}
          onChange={handleSearchChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </div>
      {filteredSeminars && filteredSeminars.length > 0 ? (
        <Container>
          <Grid container spacing={3}>
            {filteredSeminars.map(seminar => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={seminar.id}>
                <Card 
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
                  onClick={() => handleOpenSeminarClick(seminar)}
                >
                  <Tooltip title="Click on image or title to go to seminar and there you can edit the seminar content" arrow>
                    {seminar.image_urls && seminar.image_urls[0] && (
                      <CardMedia
                        component="img"
                        style={{ objectFit: 'cover', height: '140px' }}
                        image={seminar.image_urls[0]}
                        alt={seminar.title}
                      />
                    )}
                  </Tooltip>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {seminar.title}
                    </Typography>
                    {seminar.coupons && seminar.coupons.length > 0 && (
                      <Box mt={2}>
                        <Typography variant="h6">Coupons:</Typography>
                        {seminar.coupons.map((coupon, index) => (
                          <Box key={index} mt={1} p={1} border={1} borderColor="divider" borderRadius={2}>
                            <Typography variant="body2">
                              <strong>Code:</strong> {coupon.coupon_code}
                              <IconButton size="small" onClick={(event) => handleCopyCouponCode(event, coupon.coupon_code)}>
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Typography>
                            <Typography variant="body2">
                              <strong>Discount:</strong> {coupon.discount}%
                            </Typography>
                            <Typography variant="body2">
                              <strong>Valid From:</strong> {coupon.valid_from}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Valid Until:</strong> {coupon.valid_until === "0000-00-00" ? "Never Expires" : coupon.valid_until}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Status:</strong> {coupon.already_used === "1" ? "Used" : "Available"}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Usage:</strong> {coupon.reusable === "0" ? "One Time" : "Unlimited"}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </CardContent>
                  <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Button variant="contained" color="primary" onClick={(event) => handleOpenSeminarClick(event, seminar)} style={{ marginRight: '10px' }}>
                        Go To Seminar
                      </Button>
                      <Tooltip title="Click GO TO SEMINAR to go to the seminar and check it out" arrow>
                        <IconButton style={{ color: 'blue' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Button variant="contained" color="primary" onClick={(event) => handleEditClick(event, seminar)} style={{ marginRight: '10px' }}>
                        Edit Seminar
                      </Button>
                      <Tooltip title="Click EDIT SEMINAR to change the content of the seminar like name, description, pricing, images and others" arrow>
                        <IconButton style={{ color: 'blue' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Button variant="contained" color="secondary" onClick={(event) => handleDeleteClick(event, seminar)} style={{ marginRight: '10px' }}>
                        Delete
                      </Button>
                      <Tooltip title="Click Delete to delete this seminar and all the associated files and data related to this seminar" arrow>
                        <IconButton style={{ color: 'purple' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>You haven't created any seminars yet!</div>
      )}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button variant="contained" color="success" onClick={() => navigate('/CreateNewSeminar')}>
          Create New Seminar
        </Button>
      </div>

      <Modal open={showModal} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2">
            Confirm Delete
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to delete this seminar?
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleCloseModal} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default SeminarsSection;
import React from "react";
import { Box, Typography } from "@mui/material";

const MessageItem = ({ msg, currentUserId }) => {
  return (
    <Box
      p={2}
      border={1}
      borderColor="grey.300"
      borderRadius="8px"
      boxShadow={1}
      sx={{
        backgroundColor:
          msg.sender_id === currentUserId ? "lightgreen" : "lightblue",
        alignSelf:
          msg.sender_id === currentUserId ? "flex-end" : "flex-start",
        maxWidth: "60%",
      }}
    >
      <Typography variant="body2">
        <strong>{msg.sender_name}:</strong> {msg.message}
      </Typography>
      <Typography variant="caption" display="block" mt={1}>
        {new Date(msg.created_at).toLocaleString()}
      </Typography>
    </Box>
  );
};

export default MessageItem;
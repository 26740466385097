import { getBaseUrl } from "./getBaseUrl"

const uploadVideo = async (videoBlob) => {
  const baseUrl = getBaseUrl();
  const dataRequest = { folder: "TeachersVideos" };

  try {
    const response = await fetch(
      `${baseUrl}/api/get-presigned-url-for-aws-bucket-generic`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      const presignedUrl = responseData.bucket_url;

      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        headers: { "Content-Type": "video/webm" },
        body: videoBlob,
      });

      if (uploadResponse.ok) {
        return presignedUrl.split("?")[0];
      } else {
        throw new Error("Error uploading video");
      }
    } else {
      throw new Error("Error getting presigned URL");
    }
  } catch (error) {
    throw new Error(`Error: ${error.message}`);
  }
};

export default uploadVideo;
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBaseUrl } from "../Utils/getBaseUrl";
import { getUserInfo } from "../Utils/userServices";
import { tabNumberMap } from "./constants";
import FloatCart from "../FloatCart";
import Header from "../Header";
import Footer from "../Footer";
import HamburgerMenu from "../HamburgerMenu";
import Spinner from "../Spinner";
import MyAccountTabs from "./MyAccountTabs";
import "./style.css";


const MyAccount = (props) => {
  const navigate = useNavigate();
  const { tabName } = useParams();
  if (tabName) {
    if (!tabNumberMap[tabName]) {
      navigate("/MyAccount");
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [customerHistoryItems, setCustomerHistoryItems] = useState([]);
  const [tabValue, setTabValue] = useState(tabNumberMap[tabName] || 0);
  const [eligibleBooks, setEligibleBooks] = useState([]);
  const [eligibleCourses, setEligibleCourses] = useState([]);
  const [eligibleCommunityCourses, setEligibleCommunityCourses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const userEmail = localStorage.getItem("email") || "";
      if (!userEmail) {
        navigate({ pathname: "/123" });
      }
      const userId = localStorage.getItem("user_id");
      if (!userId) {
        const userData = await getUserInfo(userEmail);
        if (userData && userData.user_id) {
          localStorage.setItem("user_id", userData.user_id);
        }
      }
    };
    fetchData();
  }, []);

  const signMeIn = () => {
    navigate("/123");
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  const checkIfEligible = async (email) => {
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = { email: email };
      const response = await fetch(`${baseUrl}/api/eligible-books`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });
      if (response.ok) {
        const responseData = await response.json();
        setEligibleBooks(responseData.eligibleBooks);
      } else {
        setEligibleBooks([]);
      }
    } catch (e) {
      setEligibleBooks([]);
    }
  };

  const getProductDetails = async (email) => {
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = { email: email };
      const response = await fetch(`${baseUrl}/api/purchase-history`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });
      if (response.ok) {
        const data = await response.json();
        setCustomerHistoryItems(data.purchaseHistory);
      }
    } catch (e) {
      console.error("Error fetching product details:", e);
    }
  };

  const getUserCourses = async (email) => {
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = { user_email: email };
      const response = await fetch(`${baseUrl}/api/all-user-courses`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });
      if (response.ok) {
        const data = await response.json();
        setEligibleCourses(data);
      }
    } catch (e) {
      console.error("Error fetching user courses:", e);
    }
  };

  const getUserCommunityCourses = async (email) => {
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = { user_email: email };
      const response = await fetch(`${baseUrl}/api/all-user-community-courses`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });
      if (response.ok) {
        const data = await response.json();
        setEligibleCommunityCourses(data);
      }
    } catch (e) {
      console.error("Error fetching user courses:", e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        document.body.setAttribute("style", "overflow: visible; position: static;");
        const resultEmail = localStorage.getItem("email");
        await checkIfEligible(resultEmail);
        if (resultEmail !== "") {
          await getUserCourses(resultEmail);
          await getUserCommunityCourses(resultEmail);
          await getProductDetails(resultEmail);
        } else {
          navigate("/123");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        document.body.setAttribute("style", "overflow: visible; position: static;");
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const openFloatCart = () => {
    setIsOpen((prevState) => !prevState);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    if (!state.isOpen) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
          history={props.history}
        />
        <div style={{ marginTop: "2rem" }}>
          <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT" }}>MY ACCOUNT</h1>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
          &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
        </a>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            {isLoading && <Spinner />}
            {!isLoading && (
              <MyAccountTabs
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                customerHistoryItems={customerHistoryItems}
                eligibleBooks={eligibleBooks}
                eligibleCourses={eligibleCourses}
                eligibleCommunityCourses={eligibleCommunityCourses}
                history={props.history}
              />
            )}
          </div>
        </div>
      </div>
      <FloatCart isTheCartOpen={isOpen} history={props.history} />
      <Footer history={props.history} />
    </div>
  );
};

export default MyAccount;
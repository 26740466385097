import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Link,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules"; // Removed Pagination
import "swiper/swiper-bundle.css"; // Import Swiper styles
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Hardcoded teacher data
const teachers = [
  {
    id: 1,
    name: "Daniela Agnehäll",
    course: "Mathematics",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1741695601097_2024-02-24-17-07-43-647.jpg",
    profilePage: "ScheduleTeacher/GT_Course_564",
  },
  {
    id: 2,
    name: "Rick Nelson",
    course: "Physics",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1741644168431_IMG_1493.jpg",
    profilePage: "ScheduleTeacher/GT_Course_561",
  },
  {
    id: 3,
    name: "Blessings Hunga",
    course: "Chemistry",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1741442978815_IMG_0804.jpeg",
    profilePage: "ScheduleTeacher/GT_Course_551",
  },
  {
    id: 4,
    name: "Foster Kirkpatrick",
    course: "Biology",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1741395227177_IMG_1694.jpeg",
    profilePage: "ScheduleTeacher/GT_Course_547",
  },
  {
    id: 5,
    name: "Dennis Cheng",
    course: "History",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1741360593263_download.jpg",
    profilePage: "ScheduleTeacher/GT_Course_544",
  },
  {
    id: 6,
    name: "Garima",
    course: "Geography",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1741283149708_20250302_204347.jpg",
    profilePage: "ScheduleTeacher/GT_Course_538",
  },
  {
    id: 7,
    name: "Priyanshu Choudhary",
    course: "English",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1741147771925_Profile Picture - Circle _ Rectangle.jpg",
    profilePage: "ScheduleTeacher/GT_Course_519",
  },
  {
    id: 8,
    name: "Lincy Alex",
    course: "Art",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1740857366123_1000100316.jpg",
    profilePage: "ScheduleTeacher/GT_Course_511",
  },
  {
    id: 9,
    name: "Kartik Dhiman",
    course: "Music",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1740805373303_2--.jpg",
    profilePage: "ScheduleTeacher/GT_Course_509",
  },
  {
    id: 10,
    name: "Ajmal Hussain",
    course: "Drama",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1740769768076_IMG_20240918_100715_622.jpg",
    profilePage: "ScheduleTeacher/GT_Course_508",
  },
  {
    id: 11,
    name: "Vishwajit Pardeshi",
    course: "Computer Science",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1740550581524_IMG-20240709-WA0006.jpg",
    profilePage: "ScheduleTeacher/GT_Course_504",
  },
  {
    id: 12,
    name: "Mary Skondras",
    course: "Economics",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1738276552074_Screenshot_20240428_153230_Gallery.jpg",
    profilePage: "ScheduleTeacher/GT_Course_338",
  },
  {
    id: 13,
    name: "Marie Paz Oclarit",
    course: "Business Studies",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1738064519028_ImportedPhoto_1736232686421.jpg",
    profilePage: "ScheduleTeacher/GT_Course_316",
  },
  {
    id: 14,
    name: "Viktorija Möller",
    course: "Physical Education",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1737849059254_Viktorija MÃ¶ller.JPG",
    profilePage: "ScheduleTeacher/GT_Course_305",
  },
  {
    id: 15,
    name: "Diba Hakimelahi",
    course: "Psychology",
    image:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersPictures/1739045842352_IMG_7883.jpeg",
    profilePage: "ScheduleTeacher/GT_Course_391",
  },
];

// Styled component for the carousel container
const CarouselContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "auto", // Adjusted height to auto for better responsiveness
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "linear-gradient(135deg,rgb(224, 191, 191),rgb(227, 48, 48))", // Gradient background
  fontFamily: "Times New Roman, serif", // Times New Roman font
  padding: "20px 0", // Adjusted padding
}));

const TeachersCarousel = () => {
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box sx={{ textAlign: "center", padding: "20px 0" }}>
      {/* Title */}
      <Typography
        variant="h1"
        sx={{
          fontFamily: "Times New Roman, serif",
          color: "#333",
          fontWeight: "bold",
          fontSize: isSmallScreen ? "1.5rem" : "2rem", // Adjust font size based on screen size
          marginBottom: "20px", // Add margin to separate title from carousel
        }}
      >
        Meet Recently Onboarded Teachers
      </Typography>

      {/* Swiper Carousel */}
      <CarouselContainer>
        <Swiper
          modules={[Navigation, Autoplay]} // Removed Pagination
          spaceBetween={isSmallScreen ? 10 : 20} // Adjust space between slides
          slidesPerView={isSmallScreen ? 1 : 3} // Adjust slides per view based on screen size
          loop={true} // Enable infinite loop
          autoplay={{ delay: 3000, disableOnInteraction: false }} // Auto-slide every 3 seconds
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          style={{ width: "100%", padding: "0 40px" }} // Added padding to prevent truncation
        >
          {teachers.map((teacher) => (
            <SwiperSlide key={teacher.id}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  transform:
                    selectedTeacher?.id === teacher.id
                      ? "scale(1.1)"
                      : "scale(1)",
                  zIndex: selectedTeacher?.id === teacher.id ? 2 : 1,
                  "&:hover": { transform: "scale(1.1)" }, // Hover effect
                  marginBottom: "20px", // Added margin to prevent name truncation
                }}
                onClick={() => setSelectedTeacher(teacher)}
              >
                <Avatar
                  src={teacher.image}
                  alt={teacher.name}
                  sx={{
                    width: isSmallScreen ? 120 : 200, // Adjust avatar size based on screen size
                    height: isSmallScreen ? 120 : 200,
                    borderRadius: "50%",
                    boxShadow: 3,
                    border: "3px solid white", // Add border
                  }}
                />
                {selectedTeacher?.id === teacher.id && (
                  <Box sx={{ textAlign: "center", mt: 2 }}>
                    <Link href={teacher.profilePage} color="inherit">
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Times New Roman, serif",
                          fontSize: isSmallScreen ? "0.9rem" : "1.2rem", // Adjust text size based on screen size
                        }}
                      >
                        {teacher.name}
                      </Typography>
                    </Link>
                  </Box>
                )}
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Custom Navigation Buttons */}
        <IconButton
          className="swiper-button-prev"
          sx={{
            position: "absolute",
            left: "10px",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          className="swiper-button-next"
          sx={{
            position: "absolute",
            right: "10px",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </CarouselContainer>
    </Box>
  );
};

export default TeachersCarousel;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Select, MenuItem, FormControl } from '@mui/material';

const sortByOptions = [
  { value: 'latest', label: 'Date: Newest First' },
  { value: 'oldest', label: 'Date: Oldest First' },
  { value: 'lowestprice', label: 'Price: Low to High' },
  { value: 'highestprice', label: 'Price: High to Low' },
];

const ShelfHeader = ({ productsLength, onSortChange }) => {
  const [sort, setSort] = useState('latest');

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSort(value);
    onSortChange(value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 20px',
        backgroundColor: '#f8f8f8',
        borderBottom: '1px solid #ddd',
        flexWrap: 'wrap', // Allow wrapping on smaller screens
      }}
    >
      {/* Products Found */}
      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333' }}>
        {productsLength} Product(s) found.
      </Typography>

      {/* Sort Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Typography variant="body1" sx={{ color: '#333' }}>
          Order by
        </Typography>

        {/* MUI Select Component */}
        <FormControl variant="outlined" size="small">
          <Select
            value={sort}
            onChange={handleSortChange}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '4px',
              '&:hover': {
                borderColor: '#007bff',
              },
              '&:focus': {
                borderColor: '#007bff',
                outline: 'none',
              },
            }}
          >
            {sortByOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

ShelfHeader.propTypes = {
  productsLength: PropTypes.number.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

export default ShelfHeader;
import React, { useState, useEffect } from "react";
import FloatCart from "../FloatCart";
import Header from "../Header";
import Footer from "../Footer";
import HamburgerMenu from "../HamburgerMenu";
import CreateNewSeminarDetails from "./CreateNewSeminarDetails";
import "react-vertical-timeline-component/style.min.css";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../Utils/userServices";
import { toast } from "react-toastify";
import Spinner from "../Spinner";


const CreateNewSeminar = () => {
  const [state, setState] = useState({
    isOpen: false,
    menuHidden: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const email = localStorage.getItem("email");
      try {
        setIsLoading(true);
        if (email) {
          const userData = await getUserInfo(email);
          if (userData.email_verified !== 1) {
            toast.error("User should verify email before creating a seminar");
            navigate("/MyAccount/Settings");
          }
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error:", error);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Run this effect after every render
    if (!state.menuHidden) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "static";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.position = "static";
    }
  }, [state.menuHidden]); // Only re-run the effect if menuHidden changes

  useEffect(() => {
    // Run this effect only once after mounting
    document.body.style.overflow = "visible";
    document.body.style.position = "static";
  }, []);

  const openFloatCart = () => {
    setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
  };

  const openMenuWindow = () => {
    setState((prevState) => ({ ...prevState, menuHidden: false }));
  };

  const isMenuOpen = (state) => {
    const menuHidden = state.isOpen === false;
    setState((prevState) => ({ ...prevState, menuHidden }));
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setState((prevState) => ({ ...prevState, menuHidden: true }));
  };

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={state.menuHidden}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} />
        {isLoading && <Spinner />}
        {!isLoading && (
          <div
            style={{
              backgroundSize: "cover",
              backgroundBlendMode: "overlay",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CreateNewSeminarDetails />
            </div>
          </div>
        )}
        <Footer />
        <FloatCart isTheCartOpen={state.isOpen} history={navigate} />
      </div>
    </div>
  );
};

export default CreateNewSeminar;

import React from "react";
import Product from "./Product";

const ProductList = ({
  products,
  history,
  currency,
  selectedProduct,
  setSelectedProduct,
}) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
    >
      {(products ?? []).map((p) => (
        <Product
          product={p}
          key={p.id}
          history={history}
          currency={currency}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      ))}
    </div>
  );
};

export default ProductList;

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useStore } from "../../../stores/storeContext";
import {
  checkIfEligible,
  checkSubscriptionStatus,
} from "../../Utils/eligibilityService";
import { getBaseUrl } from "../../Utils/getBaseUrl";

const EMAIL = "email";

export const useProductDetails = (props) => {
  const { setShowSignInPopup } = props;
  const navigate = useNavigate();
  const { id } = useParams();

  // Access MobX stores
  const { cartStore } = useStore();

  const [email, setEmail] = useState(localStorage.getItem(EMAIL) || "");
  const [menuHidden, setMenuHidden] = useState(true);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
  const [selectedSizeName, setSelectedSizeName] = useState("");
  const [selectedKey, setSelectedKey] = useState(null);
  const [copied, setCopied] = useState(false);
  const [eligible, setEligible] = useState(false);
  const [isCourseOwner, setIsCourseOwner] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [products, setProducts] = useState([]); // Local state for products

  // Fetch product data from the API
  const handleFetchProduct = async (sku = id) => {
    setIsLoading(true);
    const baseUrl = getBaseUrl();
    const dataRequest = {
      sku: { email: localStorage.getItem(EMAIL), sku: sku },
      user_email: localStorage.getItem(EMAIL),
    };

    try {
      const response = await fetch(`${baseUrl}/api/product`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataRequest),
      });

      const data = await response.json();
      setProducts([data]); // Update local products state
      setAvailableSizes(data.availableSizes || []); // Update available sizes
    } catch (error) {
      toast.error("Failed to fetch product details");
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch product data when the component mounts or the ID changes
  useEffect(() => {
    handleFetchProduct(id);
  }, [id]);

  // Check eligibility for the product
  useEffect(() => {
    if (products.length > 0) {
      const checkEligibility = async () => {
        const courseOwner = products[0].owner === localStorage.getItem(EMAIL);
        setIsCourseOwner(courseOwner);
        const isMember = await checkSubscriptionStatus(email);
        if (!isMember || !products[0].included_in_membership) {
          const isEligible = await checkIfEligible(email, products[0].course_id);
          setEligible(isEligible || courseOwner);
        } else if (isMember && products[0].included_in_membership) {
          setEligible(true);
        }
      };
      checkEligibility();
    }
  }, [products, email]);

  // Handle opening the message dialog
  const handleClickOpen = () => {
    const userEmail = localStorage.getItem(EMAIL);
    if (!userEmail) {
      setShowSignInPopup(true); // Show the sign-in popup if the user is not logged in
      return; // Exit the function early
    }
    setEmail(userEmail);
    setOpen(true);
  };

  // Handle closing the message dialog
  const handleClose = () => {
    setOpen(false);
    setEmailError("");
    setMessageError("");
  };

  // Handle sending a message
  const handleSend = async () => {
    setIsLoading(true);
    const baseUrl = getBaseUrl();
    const dataRequest = {
      sender_email: localStorage.getItem(EMAIL),
      message: message.trim(),
      product_id: products[0].id,
    };
    const response = await fetch(
      `${baseUrl}/api/send-messages-about-products`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      }
    );
    if (response.ok) {
      toast.success("Message sent successfully");
      setMessage("");
    } else {
      const errorData = await response.json();
      if (errorData.error === "User email is not verified") {
        toast.error("Email is not verified. Please verify your email.");
      } else {
        toast.error("Failed to send message");
      }
    }
    setIsLoading(false);
    handleClose();
  };

  // Validate email format
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!validateEmail(e.target.value)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  // Handle message input change
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (e.target.value.length === 0) {
      setMessageError("Message cannot be empty");
    } else if (e.target.value.length > 1000) {
      setMessageError("Message cannot exceed 1000 characters");
    } else {
      setMessageError("");
    }
  };

  // Handle size selection
  const sizeSelectedPressed = (productSize, index) => {
    setSelectedSizeName(productSize);
    setSelectedSizeIndex(index);
  };

  // Add product to cart using MobX store
  const addThisProductToCart = (item) => {
    if (canItemBeAdded(item)) {
      const selectedSize =
        selectedSizeName ||
        (products[0] &&
          products[0].availableSizes &&
          products[0].availableSizes[0]);
      cartStore.addProduct({ ...item, selectedSize, quantity: 1 }); // Use MobX store
    } else {
      notify();
    }
  };

  // Check if an item can be added to the cart
  const canItemBeAdded = (item) => {
    for (let i = 0; i < cartStore.products.length; i++) {
      if (cartStore.products[i].id === item.id) {
        return false;
      }
    }
    return true;
  };

  // Notify user if the product is already in the cart
  const notify = () => {
    toast("You already have added this product to the cart.");
  };

  // Handle scheduling a meeting
  const handleScheduleMeeting = (skuInput) => {
    navigate(`/ScheduleTeacher/${skuInput}`);
  };

  // Generate size options for the product
  let itemSizes;
  if (availableSizes != null) {
    itemSizes = availableSizes.map((item) => (
      <span key={item.index}> {item}&nbsp;&nbsp;</span>
    ));
  } else {
    itemSizes = null;
  }

  return {
    email,
    setEmail,
    menuHidden,
    setMenuHidden,
    availableSizes,
    setAvailableSizes,
    isLoading,
    setIsLoading,
    selectedSizeIndex,
    setSelectedSizeIndex,
    selectedSizeName,
    setSelectedSizeName,
    selectedKey,
    setSelectedKey,
    copied,
    setCopied,
    eligible,
    setEligible,
    isCourseOwner,
    setIsCourseOwner,
    open,
    setOpen,
    message,
    setMessage,
    emailError,
    setEmailError,
    messageError,
    setMessageError,
    handleClickOpen,
    handleClose,
    handleSend,
    validateEmail,
    handleEmailChange,
    handleMessageChange,
    products, // Return the locally managed products
    sizeSelectedPressed,
    addThisProductToCart,
    canItemBeAdded,
    notify,
    handleScheduleMeeting,
    itemSizes,
  };
};
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../Utils/getBaseUrl";
import Spinner from "../Spinner";
import { Button, TextField, Grid, Typography, Box, Paper } from "@mui/material";
import FileInput from "../CommonComponents/FileInput/FileInput";
import VideoUpload from "../CommonComponents/VideoUpload/VideoUpload";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CouponManager from "./CouponManager"; // Import the new component
import uploadVideo from "../Utils/uploadVideo";

const getCurrentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

function CreateNewSeminarDetails() {
  const navigate = useNavigate();
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const presenterRef = useRef(null);
  const priceRef = useRef(null);
  const locationRef = useRef(null);
  const seminarDatetimeRef = useRef(null);
  const languageRef = useRef(null);
  const imagesRef = useRef(null);
  const videoChunksRef = useRef([]);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    presenter: "",
    price: "",
    location: "",
    seminar_datetime: "",
    language: "",
    type: "seminar",
    user_email: localStorage.getItem("email"),
    video_link: "",
    coupons: [],
    images: [null, null, null],
    total_seats: "", // Add this line
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [currentTimezone, setCurrentTimezone] = useState(getCurrentTimezone());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e, index) => {
    const files = e.target.files;
    const newImages = [...formData.images];
    newImages[index] = files[0];
    setFormData({ ...formData, images: newImages });
  };

  const handleSubmit = async () => {
    setErrors({});
    const errors = {};

    if (!formData.title) {
      errors.title = "Title is required";
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.description) {
      errors.description = "Description is required";
      descriptionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.presenter) {
      errors.presenter = "Presenter is required";
      presenterRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.price) {
      errors.price = "Price is required";
      priceRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.location) {
      errors.location = "Location is required";
      locationRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.seminar_datetime) {
      errors.seminar_datetime = "Seminar date and time are required";
      seminarDatetimeRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.language) {
      errors.language = "Language is required";
      languageRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (formData.images.every((image) => !image)) {
      errors.images = "At least one image is required";
      imagesRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (!formData.total_seats || !Number.isInteger(Number(formData.total_seats))) {
      errors.total_seats = "Total Participants Allowed must be a positive integer";
    } else if (Number(formData.total_seats) <= 0) {
      errors.total_seats = "Total Participants Allowed must be a positive integer";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setIsLoading(true);

      let videoUrl = formData.video_link;

      // If a recorded video is present, upload it first
      if (videoChunksRef.current.length > 0) {
        const videoBlob = new Blob(videoChunksRef.current, { type: "video/webm" });
        videoUrl = await uploadVideo(videoBlob);
      }

      const baseUrl = getBaseUrl();
      const formDataToSend = new FormData();

      // Append all form data fields
      formDataToSend.append('title', formData.title);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('presenter', formData.presenter);
      formDataToSend.append('price', formData.price);
      formDataToSend.append('location', formData.location);
      formDataToSend.append('seminar_datetime', formData.seminar_datetime);
      formDataToSend.append('timezone', currentTimezone); // Append the timezone
      formDataToSend.append('language', formData.language);
      formDataToSend.append('user_email', formData.user_email);
      formDataToSend.append('video_link', videoUrl);
      formDataToSend.append('total_seats', formData.total_seats); // Add this line

      // Append coupons as JSON
      formDataToSend.append('coupons', JSON.stringify(formData.coupons));

      // Append images
      formData.images.forEach((image, index) => {
        if (image) {
          formDataToSend.append(`images`, image); // Use the same key for all images
        }
      });

      const response = await fetch(`${baseUrl}/api/seminar-images-coupons/create`, {
        method: "POST",
        body: formDataToSend, // Send FormData directly
      });

      if (!response.ok) {
        throw new Error("Failed to create seminar");
      }
      toast.success("Seminar created successfully");
      setErrorMessage("Seminar created successfully.");
      navigate("/MyAccount/Teaching");
    } catch (error) {
      toast.error("Failed to create seminar. Please try again later.");
      console.error("Error creating seminar:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold" }}>
        Create New Seminar
      </Typography>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              inputRef={titleRef}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              inputRef={descriptionRef}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Presenter"
              name="presenter"
              value={formData.presenter}
              onChange={handleChange}
              fullWidth
              inputRef={presenterRef}
              error={!!errors.presenter}
              helperText={errors.presenter}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Price (USD)"
              name="price"
              value={formData.price}
              onChange={handleChange}
              fullWidth
              inputRef={priceRef}
              error={!!errors.price}
              helperText={errors.price}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              fullWidth
              inputRef={locationRef}
              error={!!errors.location}
              helperText={errors.location}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Seminar Date & Time"
              name="seminar_datetime"
              type="datetime-local"
              value={formData.seminar_datetime}
              onChange={handleChange}
              fullWidth
              inputRef={seminarDatetimeRef}
              error={!!errors.seminar_datetime}
              helperText={errors.seminar_datetime}
              InputLabelProps={{ shrink: true }}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Current Timezone: {currentTimezone}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Language"
              name="language"
              value={formData.language}
              onChange={handleChange}
              fullWidth
              inputRef={languageRef}
              error={!!errors.language}
              helperText={errors.language}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Total Participants Allowed"
              name="total_seats"
              value={formData.total_seats}
              onChange={handleChange}
              fullWidth
              type="number"
              inputProps={{ min: 1 }}
              error={!!errors.total_seats}
              helperText={errors.total_seats}
            />
          </Grid>
          <CouponManager coupons={formData.coupons} setCoupons={(coupons) => setFormData({ ...formData, coupons })} />
          <Grid item xs={12}>
            <FileInput
              formData={formData}
              handleFileChange={handleFileChange}
              errors={errors}
              setErrors={setErrors}
              refs={{ imagesRef }}
            />
          </Grid>
          <Grid item xs={12}>
            <VideoUpload formData={formData} setFormData={setFormData} videoChunksRef={videoChunksRef} />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
              sx={{ px: 4, py: 1.5, fontSize: "1.1rem", textTransform: "none" }}
            >
              {isLoading ? <Spinner /> : "Create Seminar"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

export default CreateNewSeminarDetails;
import React from "react";
import TeacherProfileData from "./TeacherProfileData";
import TeacherCalendar from "./TeacherCalendar";
import TeacherBankInfo from "./TeacherBankInfo";

const TeacherProfileTabPanel = () => {
  return (
    <div>
      <TeacherProfileData />
      <TeacherCalendar />
      <TeacherBankInfo />
    </div>
  );
};

export default TeacherProfileTabPanel;
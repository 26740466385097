import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import OrderHistoryIcon from "@mui/icons-material/History";
import BookshelfIcon from "@mui/icons-material/Book";
import GTCoursesIcon from "@mui/icons-material/School";
import CommunityCoursesIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import TeacherProfileIcon from "@mui/icons-material/Person";
import TeachingIcon from "@mui/icons-material/Work";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MessageIcon from "@mui/icons-material/Message";
import TabPanel from "./TabPanel";
import CustomerHistoryTabPanel from "./CustomerHistoryTabPanel";
import BookshelfTabPanel from "./BookshelfTabPanel";
import CoursesTabPanel from "./CoursesTabPanel";
import CoursesCommunityTabPanel from "./CoursesCommunityTabPanel";
import SettingsTabPanel from "./SettingsTabPanel/SettingsTabPanel";
import TeacherProfileTabPanel from "./TeacherProfileTabPanel/TeacherProfileTabPanel";
import TeachingTabPanel from "./TeachingTabPanel";
import FavoriteCoursesTabPanel from "./FavoriteCoursesTabPanel/FavoriteCoursesTabPanel";
import MessagesTabPanel from "./MessagesTabPanel/MessagesTabPanel"; // Import the new MessagesTabPanel component
import useMediaQuery from '@mui/material/useMediaQuery';

const MyAccountTabs = ({ tabValue, handleTabChange, customerHistoryItems, eligibleBooks, eligibleCourses, eligibleCommunityCourses, favoriteCourses, history }) => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered={!isSmallScreen}
        variant={isSmallScreen ? "scrollable" : "fullWidth"}
        scrollButtons="auto"
        style={{ overflowX: "auto" }}
      >
        <Tab 
          icon={<MessageIcon sx={{ color: "red" }} />} 
          label={<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>MESSAGES</span>} 
          sx={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: { xs: '12px', sm: '14px', md: '16px' }, minWidth: 'unset', padding: '6px 12px' }} 
        />
        <Tab 
          icon={<FavoriteIcon sx={{ color: "red" }} />} 
          label={<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>FAVORITE<br />COURSES</span>} 
          sx={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: { xs: '12px', sm: '14px', md: '16px' }, minWidth: 'unset', padding: '6px 12px' }} 
        />
        <Tab 
          icon={<OrderHistoryIcon sx={{ color: "red" }} />} 
          label={<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>ORDER<br />HISTORY</span>} 
          sx={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: { xs: '12px', sm: '14px', md: '16px' }, minWidth: 'unset', padding: '6px 12px' }} 
        />
        <Tab 
          icon={<BookshelfIcon sx={{ color: "red" }} />} 
          label={<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>BOOK<br />SHELF</span>} 
          sx={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: { xs: '12px', sm: '14px', md: '16px' }, minWidth: 'unset', padding: '6px 12px' }} 
        />
        <Tab 
          icon={<GTCoursesIcon sx={{ color: "red" }} />} 
          label={<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>GT<br />COURSES</span>} 
          sx={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: { xs: '12px', sm: '14px', md: '16px' }, minWidth: 'unset', padding: '6px 12px' }} 
        />
        <Tab 
          icon={<CommunityCoursesIcon sx={{ color: "red" }} />} 
          label={<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>COMMUNITY<br />COURSES</span>} 
          sx={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: { xs: '12px', sm: '14px', md: '16px' }, minWidth: 'unset', padding: '6px 12px' }} 
        />
        <Tab 
          icon={<SettingsIcon sx={{ color: "red" }} />} 
          label={<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>SETTINGS</span>} 
          sx={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: { xs: '12px', sm: '14px', md: '16px' }, minWidth: 'unset', padding: '6px 12px' }} 
        />
        <Tab 
          icon={<TeacherProfileIcon sx={{ color: "red" }} />} 
          label={<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>TEACHER<br />PROFILE</span>} 
          sx={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: { xs: '12px', sm: '14px', md: '16px' }, minWidth: 'unset', padding: '6px 12px' }} 
        />
        <Tab 
          icon={<TeachingIcon sx={{ color: "red" }} />} 
          label={<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>TEACHING</span>} 
          sx={{ textAlign: "center", fontFamily: "Times New Roman", fontSize: { xs: '12px', sm: '14px', md: '16px' }, minWidth: 'unset', padding: '6px 12px' }} 
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <MessagesTabPanel />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <FavoriteCoursesTabPanel favoriteCourses={favoriteCourses} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <CustomerHistoryTabPanel customerHistoryItems={customerHistoryItems} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <BookshelfTabPanel eligibleBooks={eligibleBooks} history={history} />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <CoursesTabPanel eligibleCourses={eligibleCourses} history={history} />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <CoursesCommunityTabPanel eligibleCourses={eligibleCommunityCourses} history={history} />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <SettingsTabPanel />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <TeacherProfileTabPanel />
      </TabPanel>
      <TabPanel value={tabValue} index={8}>
        <TeachingTabPanel />
      </TabPanel>
    </Box>
  );
};

export default MyAccountTabs;
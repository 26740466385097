import React from 'react';
import { Box, Chip, Typography, Container, useTheme, useMediaQuery } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer'; // MUI icon for tags

const ProductTags = ({ product }) => {
  const tags = product?.tags || [];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check for mobile view

  if (!tags.length) {
    return null; // Return null if tags array is empty
  }

  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', my: 2 }}> {/* Reduced margin */}
      <Typography
        variant="h6" // Smaller heading size
        sx={{
          mb: 1, // Reduced margin bottom
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          fontWeight: 'bold',
          color: theme.palette.text.primary,
          fontSize: isMobile ? '1rem' : '1.25rem', // Responsive font size
        }}
      >
        <LocalOfferIcon fontSize="small" /> {/* Smaller icon */}
        Tags
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1, // Reduced gap between tags
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            sx={{
              backgroundColor: theme.palette.primary.light, // Light primary color
              color: theme.palette.primary.contrastText, // Contrast text color
              fontWeight: 'medium', // Slightly lighter font weight
              borderRadius: '12px', // Slightly rounded corners
              padding: isMobile ? '4px 8px' : '6px 12px', // Compact padding
              fontSize: isMobile ? '0.75rem' : '0.875rem', // Smaller font size
              '&:hover': {
                backgroundColor: theme.palette.primary.main, // Darker primary color on hover
                transform: 'scale(1.05)', // Slight zoom effect on hover
              },
              transition: 'all 0.3s ease', // Smooth transition
            }}
          />
        ))}
      </Box>
    </Container>
  );
};

export default ProductTags;
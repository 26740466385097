import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Skeleton } from '@mui/material';

const CreateCoursesAd = ({ setShowSignInPopup }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Loading state for the image
  const [imageError, setImageError] = useState(false); // Fallback for image error

  const navigateUser = () => {
    const userEmail = localStorage.getItem('email') || '';
    if (!userEmail) {
      setShowSignInPopup(true);
    } else {
      navigate('/MyAccount/Teaching');
    }
  };

  return (
    <Box
      onClick={navigateUser}
      onKeyPress={(e) => e.key === 'Enter' && navigateUser()}
      role="button"
      tabIndex={0}
      sx={{
        position: 'relative',
        width: '100%',
        height: '0',
        paddingBottom: '56.25%',
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover img': {
          transform: 'scale(1.05)',
        },
        '&:hover .cta-overlay': {
          opacity: 1,
        },
      }}
    >
      {/* Loading Skeleton */}
      {isLoading && (
        <Skeleton
          variant="rectangular"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      )}

      {/* Image with Fallback */}
      <img
        src={
          imageError
            ? 'https://via.placeholder.com/800x450.png?text=Fallback+Image'
            : 'https://ghummantech-website.s3.us-east-2.amazonaws.com/CreateCourses.gif'
        }
        alt="Create Courses"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          display: isLoading ? 'none' : 'block',
          transition: 'transform 0.3s ease',
        }}
        onLoad={() => setIsLoading(false)}
        onError={() => setImageError(true)}
      />

      {/* CTA Overlay */}
      <Box
        className="cta-overlay"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0,
          transition: 'opacity 0.3s ease',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            mb: 2,
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          }}
        >
          Ready to Share Your Knowledge?
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            mb: 2,
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
          }}
        >
          Empower Your Teaching with AI-Powered Efficiency
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#ff7e5f',
            color: 'white',
            fontSize: '1.2em',
            padding: '10px 30px',
            borderRadius: '25px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.2s, box-shadow 0.2s',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)',
              backgroundColor: '#ff7e5f',
            },
          }}
        >
          Create Your Course Now
        </Button>
      </Box>
    </Box>
  );
};

export default CreateCoursesAd;
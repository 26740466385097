import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import './Filter.css';

const availableSizes = ['Free', 'Paid', 'Ghumman Tech Courses', 'Custom Courses'];

const Filter = ({ onFilterChange, initialFilters = [] }) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(new Set(initialFilters));

  const toggleCheckbox = (label) => {
    const newSelectedCheckboxes = new Set(selectedCheckboxes);
    if (newSelectedCheckboxes.has(label)) {
      newSelectedCheckboxes.delete(label);
    } else {
      newSelectedCheckboxes.add(label);
    }
    setSelectedCheckboxes(newSelectedCheckboxes);
    onFilterChange(Array.from(newSelectedCheckboxes)); // Notify parent component of filter changes
  };

  const resetFilters = () => {
    setSelectedCheckboxes(new Set());
    onFilterChange([]); // Notify parent component that filters have been reset
  };

  const createCheckbox = (label) => (
    <FormControlLabel
      control={
        <Checkbox
          checked={selectedCheckboxes.has(label)}
          onChange={() => toggleCheckbox(label)}
          name={label}
          color="primary"
        />
      }
      label={label}
      key={label}
    />
  );

  const createCheckboxes = () => availableSizes.map(createCheckbox);

  return (
    <div className="filters">
      <span style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '10px', paddingRight: '10px', color: 'purple' }}>
        FILTERS :
      </span>
      {createCheckboxes()}
      <IconButton color="secondary" onClick={resetFilters}>
        <RefreshIcon />
      </IconButton>
    </div>
  );
};

Filter.propTypes = {
  onFilterChange: PropTypes.func.isRequired, // Callback to notify parent of filter changes
  initialFilters: PropTypes.array, // Initial filters passed from parent
};

export default Filter;
import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

function CourseFormatComponent({ formData, handleChange, error, helperText }, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = React.useState(null);

  // Mapping between internal values and display labels
  const formatOptions = [
    { value: "live", label: "Live" },
    { value: "self-paced", label: "Self Paced" },
    { value: "hybrid", label: "Hybrid" },
  ];

  const handleInfoClick = (format) => {
    if (isMobile) {
      setOpenDialog(format);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  const formatDescriptions = {
    Live: "Choose 'Live' if you want to teach the course in virtual live settings, either in one-on-one sessions or group classes.",
    "Self Paced": "Choose 'Self Paced' if you are either not available at all or have very limited availability throughout the course. The course will be recorded and automated.",
    Hybrid: "Choose 'Hybrid' if you will have a few live meetings throughout the course and can be reached through messages. You will be available to reply to messages and queries and/or check homework.",
  };

  return (
    <Box className="course-format" sx={{ marginBottom: 3 }} ref={ref}>
      <FormControl component="fieldset" fullWidth error={error}>
        <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Course Format *
        </FormLabel>
        <RadioGroup
          aria-label="courseFormat"
          name="courseFormat"
          value={formData.courseFormat}
          onChange={handleChange}
        >
          {formatOptions.map((format) => (
            <Grid container alignItems="center" key={format.value}>
              <Grid item>
                <FormControlLabel
                  value={format.value} // Store the internal value (e.g., 'live')
                  control={<Radio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <Typography>{format.label}</Typography> {/* Display the label (e.g., 'Live') */}
                      {!isMobile && (
                        <Tooltip title={formatDescriptions[format.label]} arrow>
                          <InfoIcon
                            fontSize="small"
                            sx={{ marginLeft: 1, color: "action.active", cursor: "pointer" }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
              </Grid>
              {isMobile && (
                <Grid item>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => handleInfoClick(format.label)}
                    sx={{ textTransform: "none" }}
                  >
                    <InfoIcon fontSize="small" sx={{ color: "action.active" }} />
                  </Button>
                </Grid>
              )}
            </Grid>
          ))}
        </RadioGroup>
        {helperText && (
          <Typography variant="caption" color={error ? "error" : "textSecondary"} sx={{ marginTop: 1 }}>
            {helperText}
          </Typography>
        )}
      </FormControl>

      {/* Mobile Dialog for Format Descriptions */}
      <Dialog open={Boolean(openDialog)} onClose={handleCloseDialog}>
        <DialogTitle>{openDialog}</DialogTitle>
        <DialogContent>
          <Typography>{formatDescriptions[openDialog]}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const CourseFormat = React.forwardRef(CourseFormatComponent);

export default CourseFormat;
import React from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import StepItem from "./StepItem";
import steps from "./steps";
import backgroundImage from "./background.jpg";

const OnboardingCard = () => {
  return (
    <Paper
      elevation={5}
      sx={{
        padding: "30px",
        borderRadius: "15px",
        margin: "20px 0",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "linear-gradient(45deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3))",
          borderRadius: "15px",
          zIndex: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          borderRadius: "10px",
          padding: "20px",
          zIndex: 1,
          position: "relative",
          backdropFilter: "blur(5px)",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#D32F2F", // Darker red for better contrast
            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          4 Easy Steps to Become a Ghumman Tech Teacher
        </Typography>
      </Box>
      <Box
        sx={{
          position: "relative",
          paddingLeft: { xs: "10px", md: "40px" },
          paddingRight: { xs: "10px", md: "40px" },
        }}
      >
        {steps.map((step, index) => (
          <StepItem key={index} step={step} index={index} />
        ))}
      </Box>
      <Box textAlign="center" mt={4}>
        <Button
          variant="contained"
          sx={{
            background: "linear-gradient(45deg, #E53935 30%, #D32F2F 90%)",
            color: "#fff",
            fontSize: "1.1rem",
            padding: "10px 30px",
            "&:hover": {
              background: "linear-gradient(45deg, #D32F2F 30%, #E53935 90%)",
              transform: "scale(1.05)",
              transition: "transform 0.3s ease",
            },
          }}
          onClick={() => (window.location.href = "/Onboarding")}
        >
          JOIN NOW
        </Button>
      </Box>
    </Paper>
  );
};

export default OnboardingCard;

import React, { useState, useEffect } from 'react';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './style.css';
import TeamInfo from './TeamInfo';

import { useNavigate } from 'react-router-dom';

const AboutUs = (props) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  return (
    <div id="outer-container" style={{ fontFamily: 'Times New Roman' }}>
      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />

        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT", margin: '20px 0' }}>About Us</h1>
        <Container>
          <Row>
            <Col>
              <section className="intro-section" style={{ textAlign: 'center', margin: '20px 0' }}>
                <div className="intro-header">
                  <h2 style={{ fontSize: '2em', fontWeight: 'bold' }}>Ghumman Tech: Empowering Educators and Innovators</h2>
                  <p style={{ fontSize: '1.3em' }}>At Ghumman Tech, we believe in the transformative power of knowledge and technology. Our mission is to create a platform that enables educators to share their expertise, connect with learners, and shape the future through innovative courses, free app development, and engaging seminars.</p>
                </div>

                <div className="intro-services" style={{ textAlign: 'center', margin: '20px 0' }}>
                  <h2 style={{ fontSize: '1.7em', fontWeight: 'bold' }}>What We Offer</h2>
                  <ul style={{ fontSize: '1.3em', textAlign: 'left', display: 'inline-block' }}>
                    <li><strong>Courses Designed for Success:</strong> Our platform hosts a diverse range of courses created by talented instructors. From Scratch programming to Unity game development, each course is crafted to inspire and educate learners of all ages.</li>
                    <li><strong>Free App Creation for Popular Courses:</strong> Teachers with popular paid courses on our platform receive a personalized Android and iOS app tailored specifically for their course. This initiative helps educators expand their reach and provide an immersive learning experience for their students.</li>
                    <li><strong>Seminars and Workshops:</strong> We regularly host seminars and workshops where instructors share insights about their courses, teaching techniques, and the latest trends in education and technology. These events foster a collaborative learning environment for everyone involved.</li>
                  </ul>
                </div>

                <div className="intro-vision" style={{ textAlign: 'center', margin: '20px 0' }}>
                  <h2 style={{ fontSize: '1.7em', fontWeight: 'bold' }}>Our Vision</h2>
                  <p style={{ fontSize: '1.3em' }}>Ghumman Tech is more than a platform—it’s a community. We aim to bridge the gap between educators and learners by combining innovative technology with quality education. By empowering teachers and providing tools like free course-specific apps, we pave the way for a brighter, more connected future.</p>
                </div>

                <div className="intro-explore" style={{ textAlign: 'center', margin: '20px 0' }}>
                  <h2 style={{ fontSize: '1.7em', fontWeight: 'bold' }}>Explore the Possibilities with Ghumman Tech</h2>
                  <p style={{ fontSize: '1.3em' }}>Join us in revolutionizing education through creativity, collaboration, and cutting-edge technology!</p>
                </div>
              </section>
            </Col>
          </Row>
          <TeamInfo />
        </Container>

        <Footer history={props.history} />
      </div>
    </div>
  );
}

export default AboutUs;
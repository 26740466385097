import React from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import "./style.css"; // Import the CSS file

function CourseCategories({ formData, handleCheckboxChange, error, helperText, refs }) {
  return (
    <FormControl component="fieldset" fullWidth error={error}>
      <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
        Course Category *
      </FormLabel>
      <FormGroup className="checkboxGroup" ref={refs.categoriesRef}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.categories.kids}
              onChange={handleCheckboxChange}
              name="kids"
            />
          }
          label="Kids"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.categories.programming}
              onChange={handleCheckboxChange}
              name="programming"
            />
          }
          label="Software Programming"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.categories.languages}
              onChange={handleCheckboxChange}
              name="languages"
            />
          }
          label="Spoken Languages"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.categories.certifications}
              onChange={handleCheckboxChange}
              name="certifications"
            />
          }
          label="Certifications"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.categories.live}
              onChange={handleCheckboxChange}
              name="live"
            />
          }
          label="Live"
        />
      </FormGroup>
      {helperText && (
        <FormHelperText error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default CourseCategories;
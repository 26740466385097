import React, { useState, useEffect } from 'react';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const ThankyouTrialClass = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };


  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  let userName = location?.state?.name ? `, ${location.state.name}` : '';

  return (
    <div id="outer-container">
      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />

        <FloatCart isTheCartOpen={isOpen} history={props.history} />

        <h1 style={{ textAlign: "center", fontFamily: "Old Standard TT" }}>Thank you</h1>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <a style={{ display: "inline-block", borderTopStyle: "solid" }}>
            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
          </a>
        </div>
        <div style={{ fontFamily: "serif", padding: "20px", textAlign: "center" }}>
          <Container>
            <Row>
              <Col>
                <p style={{ fontWeight: "bold" }}>Trial Class Registration completed Successfully{userName}! We're thrilled you're taking our trial class! Look for an email with the class link shortly.</p>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer history={props.history} />

      </div>
    </div>
  );

}

export default ThankyouTrialClass;


import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme, useMediaQuery } from '@mui/material';

const StudentDetailsComponent = ({
    onSubmit,
    onBack,
    childName,
    setChildName,
    parentName,
    setParentName,
    customerEmail,
    setCustomerEmail,
    errorChildName,
    errorParentName,
    errorCustomerEmail,
    isLoading,
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isAdult, setIsAdult] = useState(false); // State for the checkbox

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        setIsAdult(event.target.checked);
        if (event.target.checked) {
            setParentName(childName); // Set Parent's Name equal to Student's Name
        } else {
            setParentName(''); // Clear Parent's Name if unchecked
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 800,
                margin: '0 auto',
                padding: 4,
                textAlign: 'center',
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <Typography
                variant="h1"
                sx={{
                    fontFamily: 'Times New Roman, serif',
                    color: 'red',
                    fontWeight: 'bold',
                    marginBottom: 4,
                    fontSize: isSmallScreen ? '2rem' : '2.5rem',
                }}
            >
                Student Details
            </Typography>

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        color: 'black',
                        marginBottom: 2,
                        fontSize: isSmallScreen ? '1.25rem' : '1.5rem',
                    }}
                >
                    Student's Name
                </Typography>
                <TextField
                    id="student-name"
                    variant="outlined"
                    placeholder="Enter student's name"
                    fullWidth
                    value={childName}
                    onChange={(e) => setChildName(e.target.value)}
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                        },
                    }}
                />
                {errorChildName && !childName && (
                    <Typography
                        sx={{
                            color: 'red',
                            fontSize: '0.875rem',
                            marginTop: 1,
                            fontFamily: 'Times New Roman, serif',
                        }}
                    >
                        {errorChildName}
                    </Typography>
                )}
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isAdult}
                            onChange={handleCheckboxChange}
                            sx={{
                                color: 'red',
                                '&.Mui-checked': {
                                    color: 'red',
                                },
                            }}
                        />
                    }
                    label="Age: 18+"
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        fontSize: '1.25rem',
                        color: 'black',
                    }}
                />
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        color: 'black',
                        marginBottom: 2,
                        fontSize: isSmallScreen ? '1.25rem' : '1.5rem',
                    }}
                >
                    Parent's Name
                </Typography>
                <TextField
                    id="parent-name"
                    variant="outlined"
                    placeholder="Enter parent's name"
                    fullWidth
                    value={isAdult ? childName : parentName} // Set value to Student's Name if checkbox is checked
                    onChange={(e) => setParentName(e.target.value)}
                    disabled={isAdult} // Disable if checkbox is checked
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                        },
                        '& .Mui-disabled': {
                            backgroundColor: '#f5f5f5',
                        },
                    }}
                />
                {errorParentName && !parentName && !isAdult && ( // Show error only if checkbox is not checked
                    <Typography
                        sx={{
                            color: 'red',
                            fontSize: '0.875rem',
                            marginTop: 1,
                            fontFamily: 'Times New Roman, serif',
                        }}
                    >
                        {errorParentName}
                    </Typography>
                )}
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        color: 'black',
                        marginBottom: 2,
                        fontSize: isSmallScreen ? '1.25rem' : '1.5rem',
                    }}
                >
                    Email
                </Typography>
                <TextField
                    id="email"
                    variant="outlined"
                    placeholder="Enter email"
                    fullWidth
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                        },
                    }}
                />
                {errorCustomerEmail && (
                    <Typography
                        sx={{
                            color: 'red',
                            fontSize: '0.875rem',
                            marginTop: 1,
                            fontFamily: 'Times New Roman, serif',
                        }}
                    >
                        {errorCustomerEmail}
                    </Typography>
                )}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                }}
            >
                <Button
                    variant="contained"
                    startIcon={<SendIcon />}
                    sx={{
                        backgroundColor: 'red',
                        color: 'white',
                        padding: '12px 24px',
                        fontSize: '1rem',
                        borderRadius: '8px',
                        fontFamily: 'Times New Roman, serif',
                        '&:hover': {
                            backgroundColor: 'darkred',
                            transform: 'translateY(-2px)',
                            boxShadow: 2,
                        },
                    }}
                    onClick={onSubmit}
                    disabled={isLoading}
                >
                    Submit
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        borderColor: 'blue',
                        color: 'blue',
                        padding: '12px 24px',
                        fontSize: '1rem',
                        borderRadius: '8px',
                        fontFamily: 'Times New Roman, serif',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 255, 0.1)',
                            borderColor: 'darkblue',
                            transform: 'translateY(-2px)',
                            boxShadow: 2,
                        },
                    }}
                    onClick={onBack}
                >
                    Back
                </Button>
            </Box>
        </Box>
    );
};

export default StudentDetailsComponent;
import React, { useState, useEffect } from "react";
import FloatCart from "../FloatCart";
import Header from "../Header";
import Footer from "../Footer";
import HamburgerMenu from "../HamburgerMenu";
import ProductDetails from "./ProductDetails";

const Product = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);


  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute(
        "style",
        "overflow: hidden; position: static;"
      );
    } else {
      document.body.setAttribute(
        "style",
        "overflow: visible; position: static;"
      );
    }
    return () => {
      document.body.setAttribute(
        "style",
        "overflow: visible; position: static;"
      );
    };
  }, [menuHidden]);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    if (state.isOpen === false) {
      setMenuHidden(true);
    } else {
      setMenuHidden(false);
    }
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        history={props.history}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
        />
        <ProductDetails/>
        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />
      </div>
    </div>
  );
};


export default Product;

import { makeAutoObservable } from "mobx";

export default class TotalStore {
  productQuantity = 0;
  installments = 0;
  totalPrice = 0;
  currencyId = 'PKR';
  currencyFormat = 'Rs';

  constructor() {
    makeAutoObservable(this);
  }

  updateCart(cartProducts) {
    this.productQuantity = cartProducts.reduce((sum, p) => sum + p.quantity, 0);
    this.totalPrice = cartProducts.reduce((sum, p) => sum + p.price * p.quantity, 0);
    this.installments = cartProducts.reduce((greater, p) => p.installments > greater ? p.installments : greater, 0);
  }
}
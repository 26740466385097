// components/ProductElaboratedDetails.js
import React from "react";
import styled from "styled-components";
import {
  School as LearningIcon,
  Build as MaterialIcon,
  Checklist as PrerequisitesIcon,
  MenuBook as ResourcesIcon,
  Assignment as SyllabusIcon,
} from "@mui/icons-material";
import { Typography, Paper, Divider, Grow } from "@mui/material";
import ProductElaboratedDetailsHeader from "./ProductElaboratedDetailsHeader";

const Container = styled(Paper)`
  padding: 24px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
`;

const Section = styled.div`
  margin-bottom: 24px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const SectionContent = styled(Typography)`
  font-size: 16px;
  color: #555;
  line-height: 1.8;
  white-space: pre-wrap;
  padding-left: 36px; // Align with the icon
  transition: color 0.3s ease;

  &:hover {
    color: #1976d2; // Primary color
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #1976d2; // Primary color
  border-radius: 50%;
  color: white;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const ProductElaboratedDetails = ({ product }) => {
  const { learning_objectives, material_needed, pre_requisites, resources, syllabus } = product;

  return (
    <Grow in={true} timeout={500}>
      <Container elevation={3}>
        <ProductElaboratedDetailsHeader product={product} />

        {learning_objectives && (
          <Section>
            <SectionHeader>
              <IconWrapper>
                <LearningIcon fontSize="medium" />
              </IconWrapper>
              <Typography variant="h6" color="primary">
                Learning Objectives
              </Typography>
            </SectionHeader>
            <SectionContent>{learning_objectives}</SectionContent>
          </Section>
        )}

        {material_needed && (
          <Section>
            <SectionHeader>
              <IconWrapper>
                <MaterialIcon fontSize="medium" />
              </IconWrapper>
              <Typography variant="h6" color="primary">
                Material Needed
              </Typography>
            </SectionHeader>
            <SectionContent>{material_needed}</SectionContent>
          </Section>
        )}

        {pre_requisites && (
          <Section>
            <SectionHeader>
              <IconWrapper>
                <PrerequisitesIcon fontSize="medium" />
              </IconWrapper>
              <Typography variant="h6" color="primary">
                Pre-requisites
              </Typography>
            </SectionHeader>
            <SectionContent>{pre_requisites}</SectionContent>
          </Section>
        )}

        {resources && (
          <Section>
            <SectionHeader>
              <IconWrapper>
                <ResourcesIcon fontSize="medium" />
              </IconWrapper>
              <Typography variant="h6" color="primary">
                Resources
              </Typography>
            </SectionHeader>
            <SectionContent>{resources}</SectionContent>
          </Section>
        )}

        {syllabus && (
          <Section>
            <SectionHeader>
              <IconWrapper>
                <SyllabusIcon fontSize="medium" />
              </IconWrapper>
              <Typography variant="h6" color="primary">
                Syllabus
              </Typography>
            </SectionHeader>
            <SectionContent>{syllabus}</SectionContent>
          </Section>
        )}

        <Divider sx={{ my: 2 }} />
      </Container>
    </Grow>
  );
};

export default ProductElaboratedDetails;
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ShelfCollection from '../ShelfCollection';
import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import './style.css';

const CollectionCategory = () => {
  const { id, searchText } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const [pageCategoryNameState, setPageCategoryNameState] = useState(id);

  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (id !== pageCategoryNameState) {
      setPageCategoryNameState(id);
    }
  }, [id, pageCategoryNameState]);

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute('style', 'overflow: hidden; position: static;');
    } else {
      document.body.setAttribute('style', 'overflow: visible; position: static;');
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute('style', 'overflow: visible; position: static;');
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute('style', 'overflow: visible; position: static;');
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  const tempResult = pageCategoryNameState.replace(/([A-Z])/g, ' $1');
  const pageTitle = tempResult.charAt(0).toUpperCase() + tempResult.slice(1);

  return (
    <div id="outer-container">
      <HamburgerMenu
        isMenuOpenFunc={isMenuOpen}
        pageWrapId="page-wrap"
        outerContainerId="outer-container"
        isOpenStatus={menuHidden}
        callbackClose={callbackRoutingCloseMenu}
      />
      <div id="page-wrap">
        <Header
          openMenu={openMenuWindow}
          openCart={openFloatCart}
          signIn={signMeIn}
        />

        {/* Improved Page Title Section */}
        <div style={{ textAlign: 'center', padding: '40px 20px', backgroundColor: '#f9f9f9' }}>
          <h1
            style={{
              fontFamily: 'Times New Roman, serif',
              fontSize: '2.5rem',
              fontWeight: '700',
              color: '#333',
              marginBottom: '10px',
              transition: 'color 0.3s ease',
            }}
          >
            {pageTitle}
          </h1>
          <p
            style={{
              fontFamily: 'Roboto, sans-serif',
              fontSize: '1rem',
              color: '#666',
              marginTop: '0',
            }}
          >
            Explore our curated collection of {pageTitle.toLowerCase()}.
          </p>
          <div
            style={{
              width: '60px',
              height: '4px',
              backgroundColor: '#ff6f61',
              margin: '20px auto',
              borderRadius: '2px',
            }}
          ></div>
        </div>

        <React.Fragment>
          <div>
            <ShelfCollection categoryName={pageCategoryNameState} searchText={searchText} />
          </div>
          <FloatCart isTheCartOpen={isOpen} />
        </React.Fragment>

        <Footer />
      </div>
    </div>
  );
};

export default CollectionCategory;
import React, { useState, useRef, memo } from 'react';
import Spinner from '../Spinner'

const DocumentationDetails = memo(() => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const hasRun = useRef(false);

  const handleIframeLoad = () => {
    if (!hasRun.current) {
      setIframeLoaded(true);
      hasRun.current = true;
    }
  };


  return (
    <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      {!iframeLoaded && <Spinner />}
      <iframe
        key="documentation-iframe"
        src="https://ghummantech.github.io/Documentation/"
        style={{ width: '100%', height: '100%', border: 'none', display: iframeLoaded ? 'block' : 'none' }}
        title="Documentation"
        onLoad={handleIframeLoad}
      />
    </div>
  );
});

export default DocumentationDetails;
import React, {useState, useEffect} from 'react';
import FloatCart from '../FloatCart';
import { Box } from '@mui/material';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import Image from 'react-bootstrap/Image'
import chartSizes from '../../assets/chartSizes.jpg'
import './style.css';
import { useNavigate } from 'react-router-dom';

const SizeChart = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };


  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };

  return (
      <div id="outer-container">
        <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu}/>
        <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn}  history={props.history}/>



          <h1 style={{textAlign: "center", fontFamily: "Old Standard TT"}}>Size Chart</h1>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <a style={{display: "inline-block", borderTopStyle: "solid"}}>
            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
            </a>  
          </div>

          <FloatCart isTheCartOpen={isOpen} history={props.history}/>



          <div style={{marginBottom: "100px", marginTop: "100px"}}>
          <Box
            display="flex"
            justifyContent="center"
            p={1}
            m={1}
            bgcolor="white"
          >
            <Box p={1}>
          <Image alt="Size Chart" src={chartSizes} /> 
          </Box>
          </Box>
          </div>

          <Footer history={props.history} />

          

        </div>
      </div>
    );

}

export default SizeChart;
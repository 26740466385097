import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Box,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBaseUrl } from "../../Utils/getBaseUrl";

function VideoUpload({ formData, setFormData, videoChunksRef }) {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videoLink, setVideoLink] = useState(formData.video_link || "");
  const [timeLeft, setTimeLeft] = useState(60);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [countdown, setCountdown] = useState(3);
  const [showCountdown, setShowCountdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("record"); // Default to "record"
  const mediaRecorderRef = useRef(null);

  const videoRef = useRef(null);
  const streamRef = useRef(null);

  useEffect(() => {
    let timer;
    if (isRecording && !isPaused) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            stopRecording();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRecording, isPaused]);

  useEffect(() => {
    let countdownTimer;
    if (showCountdown) {
      countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownTimer);
            startRecording(); // Start recording when countdown ends
            setShowCountdown(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdownTimer);
  }, [showCountdown]);

  useEffect(() => {
    if (streamRef.current && videoRef.current) {
      videoRef.current.srcObject = streamRef.current;
    }
  }, [streamRef.current]);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 100 * 1024 * 1024) {
      uploadVideo(file);
    } else {
      toast.error("Video file size exceeds 100 MB. Upload not permitted.");
    }
  };

  const startRecording = async () => {
    if (!streamRef.current) {
      toast.error("Camera stream not available.");
      return;
    }

    mediaRecorderRef.current = new MediaRecorder(streamRef.current);
    mediaRecorderRef.current.ondataavailable = (event) => {
      videoChunksRef.current.push(event.data);
    };
    mediaRecorderRef.current.start();
    setIsRecording(true);
    setIsPaused(false);
    setTimeLeft(60);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = () => {
        const videoBlob = new Blob(videoChunksRef.current, {
          type: "video/webm",
        });
        if (videoBlob.size > 100 * 1024 * 1024) {
          toast.error("Video file size exceeds 100 MB. Upload not permitted.");
          return;
        }
        const videoUrl = URL.createObjectURL(videoBlob);
        setRecordedVideo(videoUrl);
        setVideoLink("");
      };
    }
    setIsRecording(false);
    setIsPaused(false);
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      if (isPaused) {
        mediaRecorderRef.current.resume();
      } else {
        mediaRecorderRef.current.pause();
      }
      setIsPaused(!isPaused);
    }
  };

  const uploadVideo = async (videoBlob) => {
    const baseUrl = getBaseUrl();
    const dataRequest = { folder: "TeachersVideos" };

    setIsLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/api/get-presigned-url-for-aws-bucket-generic`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(dataRequest),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const presignedUrl = responseData.bucket_url;

        const uploadResponse = await fetch(presignedUrl, {
          method: "PUT",
          headers: { "Content-Type": "video/webm" },
          body: videoBlob,
        });

        if (uploadResponse.ok) {
          const fileUrl = presignedUrl.split("?")[0];
          setVideoLink(fileUrl);
          setFormData({ ...formData, video_link: fileUrl });
          toast.success("Video uploaded successfully");
        } else {
          console.error("Error uploading video:", uploadResponse.statusText);
          toast.error(`Error uploading video: ${uploadResponse.statusText}`);
        }
      } else {
        console.error("Error getting presigned URL:", response.statusText);
        toast.error(`Error getting presigned URL: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setRecordedVideo(null);
    videoChunksRef.current = [];
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setCountdown(3);
      setShowCountdown(true);
    } catch (error) {
      toast.error("Error accessing camera: " + error.message);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  return (
    <Box sx={{ fontFamily: "Times New Roman, Times, serif", p: 3 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: "bold" }}>
        Video Upload
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <RadioGroup
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ mb: 3 }}
          >
            <FormControlLabel
              value="record"
              control={<Radio />}
              label="Record a new video"
            />
            <FormControlLabel
              value="upload"
              control={<Radio />}
              label="Upload a video file"
            />
            <FormControlLabel
              value="link"
              control={<Radio />}
              label="Provide a video link"
            />
          </RadioGroup>
        </Grid>

        {selectedOption === "link" && (
          <Grid item xs={12} md={8}>
            <TextField
              label="Video Link"
              value={videoLink}
              onChange={(e) => {
                setVideoLink(e.target.value);
                setFormData({ ...formData, video_link: e.target.value });
              }}
              fullWidth
              variant="outlined"
              sx={{ mb: 3 }}
            />
          </Grid>
        )}

        {selectedOption === "upload" && (
          <Grid item xs={12} md={8} sx={{ textAlign: "center" }}>
            <input
              accept="video/*"
              style={{ display: "none" }}
              id="upload-video"
              type="file"
              onChange={handleVideoChange}
            />
            <label htmlFor="upload-video">
              <Button
                variant="contained"
                color="primary"
                component="span"
                sx={{ px: 4, py: 1.5, fontSize: "1.1rem", textTransform: "none" }}
              >
                Upload Video
              </Button>
            </label>
          </Grid>
        )}

        {selectedOption === "record" && (
          <>
            {(showCountdown || isRecording) && (
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    paddingTop: "56.25%",
                    borderRadius: 2,
                    overflow: "hidden",
                    boxShadow: 3,
                  }}
                >
                  <video
                    ref={videoRef}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    autoPlay
                    muted
                  />
                </Box>
              </Grid>
            )}
            {showCountdown && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography variant="h2" sx={{ fontWeight: "bold", color: "red", mb: 2 }}>
                  Recording starts in {countdown}...
                </Typography>
                <CircularProgress size={80} thickness={4} sx={{ color: "red" }} />
              </Grid>
            )}
            {isRecording && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "text.secondary" }}
                >
                  {timeLeft} seconds left
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              {isRecording ? (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={stopRecording}
                    disabled={isLoading}
                    sx={{ px: 4, py: 1.5, fontSize: "1.1rem", textTransform: "none" }}
                  >
                    Stop Recording
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={pauseRecording}
                    disabled={isLoading}
                    sx={{ px: 4, py: 1.5, fontSize: "1.1rem", textTransform: "none" }}
                  >
                    {isPaused ? "Resume" : "Pause"}
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStartRecording}
                  disabled={isLoading || showCountdown}
                  sx={{ px: 4, py: 1.5, fontSize: "1.1rem", textTransform: "none" }}
                >
                  Record Video
                </Button>
              )}
            </Grid>
            {!isRecording && recordedVideo && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, mt: 4 }}
              >
                <Box sx={{ width: "100%", maxWidth: "600px", borderRadius: 2, overflow: "hidden", boxShadow: 3 }}>
                  <video src={recordedVideo} controls style={{ width: "100%", height: "auto" }} />
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                    sx={{ px: 4, py: 1.5, fontSize: "1.1rem", textTransform: "none" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}

export default VideoUpload;
import React, { useState } from "react";
import {
  Box,
  Typography,
  Link,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules"; // Removed Pagination
import "swiper/swiper-bundle.css"; // Import Swiper styles
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Hardcoded course data with video URLs
const courses = [
  {
    id: 1,
    course: "Fun With American History",
    videoUrl:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersVideos/teacher_video_1741723896314",
    coursePage: "Categories/Product/GT_Course_565",
  },
  {
    id: 2,
    course: "Let's Learn English and Enjoy Your Time",
    videoUrl:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersVideos/teacher_video_1741689025501",
    coursePage: "Categories/Product/GT_Course_563",
  },
  {
    id: 3,
    course: "English (South African Accent)",
    videoUrl:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersVideos/teacher_video_1741386300298",
    coursePage: "Categories/Product/GT_Course_546",
  },
  {
    id: 4,
    course: "Plant Physiology in Biology (English + Hindi)",
    videoUrl:
      "https://ghummantech-website.s3.us-east-2.amazonaws.com/TeachersVideos/teacher_video_1741277715491",
    coursePage: "Categories/Product/GT_Course_535",
  },
];

// Styled component for the carousel container
const CarouselContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "auto", // Adjusted height to auto for better responsiveness
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "linear-gradient(135deg,rgb(224, 191, 191),rgb(227, 48, 48))", // Gradient background
  fontFamily: "Times New Roman, serif", // Times New Roman font
  padding: "20px 0", // Adjusted padding
}));

const TeachersVideoCarousel = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [playingVideoId, setPlayingVideoId] = useState(null); // Track the currently playing video
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  // Handle video play event
  const handleVideoPlay = (id) => {
    setPlayingVideoId(id); // Set the currently playing video

    // Pause all other videos
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      if (video.id !== id.toString()) {
        video.pause();
      }
    });
  };

  // Handle video pause event
  const handleVideoPause = () => {
    setPlayingVideoId(null); // Reset the currently playing video
  };

  return (
    <Box sx={{ textAlign: "center", padding: "20px 0" }}>
      {/* Title */}
      <Typography
        variant="h1"
        sx={{
          fontFamily: "Times New Roman, serif",
          color: "#333",
          fontWeight: "bold",
          fontSize: isSmallScreen ? "1.5rem" : "2rem", // Adjust font size based on screen size
          marginBottom: "20px", // Add margin to separate title from carousel
        }}
      >
        Explore Recently Added Courses
      </Typography>

      {/* Swiper Carousel */}
      <CarouselContainer>
        <Swiper
          modules={[Navigation, Autoplay]} // Removed Pagination
          spaceBetween={isSmallScreen ? 10 : 20} // Adjust space between slides
          slidesPerView={isSmallScreen ? 1 : 2} // Adjust slides per view based on screen size
          loop={true} // Enable infinite loop
          autoplay={{
            delay: 3000,
            disableOnInteraction: true, // Disable autoplay on user interaction
            pauseOnMouseEnter: true, // Pause autoplay when hovering over the carousel
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          style={{ width: "100%", padding: "0 40px" }} // Added padding to prevent truncation
          allowTouchMove={!playingVideoId} // Disable swiping when a video is playing
        >
          {courses.map((course) => (
            <SwiperSlide key={course.id}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  transform:
                    selectedCourse?.id === course.id
                      ? "scale(1.1)"
                      : "scale(1)",
                  zIndex: selectedCourse?.id === course.id ? 2 : 1,
                  "&:hover": { transform: "scale(1.1)" }, // Hover effect
                  marginBottom: "20px", // Added margin to prevent name truncation
                }}
                onClick={() => setSelectedCourse(course)}
              >
                <video
                  id={course.id}
                  src={course.videoUrl}
                  controls
                  onPlay={() => handleVideoPlay(course.id)} // Handle play event
                  onPause={handleVideoPause} // Handle pause event
                  style={{
                    width: "100%",
                    height: isSmallScreen ? "250px" : "400px", // Increased video height
                    borderRadius: "10px",
                    boxShadow: 3,
                    border: "3px solid white", // Add border
                  }}
                />
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Link href={course.coursePage} color="inherit">
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Times New Roman, serif",
                        fontSize: isSmallScreen ? "0.9rem" : "1.2rem", // Adjust text size based on screen size
                      }}
                    >
                      {course.course}
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Custom Navigation Buttons */}
        <IconButton
          className="swiper-button-prev"
          sx={{
            position: "absolute",
            left: "10px",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          className="swiper-button-next"
          sx={{
            position: "absolute",
            right: "10px",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </CarouselContainer>
    </Box>
  );
};

export default TeachersVideoCarousel;

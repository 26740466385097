import React, { useState, useRef } from "react";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import Spinner from "../../Spinner";
import { Button, Box, Paper, Typography, Grid } from "@mui/material";
import { withAlert } from "react-alert";
import "./style.css";
import CourseCategories from "./CourseCategories";
import FileInput from "../../CommonComponents/FileInput/FileInput";
import { toast } from "react-toastify";
import CourseFormat from "./components/CourseFormat";
import CourseTags from "./components/CourseTags";
import CourseTitle from "./components/CourseTitle";
import CourseDescription from "./components/CourseDescription";
import CourseTimingAndLevel from "./components/CourseTimingAndLevel";
import CourseInstructor from "./components/CourseInstructor";
import CourseMeetingLink from "./components/CourseMeetingLink";
import CourseCost from "./components/CourseCost";
import CourseContentDetails from "./components/CourseContentDetails";
import CourseMiscellaneous from "./components/CourseMiscellaneous";
import VideoUpload from "../../CommonComponents/VideoUpload/VideoUpload";
import uploadVideo from "../../Utils/uploadVideo";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function CreateNewCourseDetails({ setStep2Complete }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    courseFormat: "live",
    courseTags: [],
    courseName: "",
    courseDescription: "",
    duration: "55 minutes per session",
    level: "Beginner",
    sessionSchedule: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    instructor: "",
    phoneNumber: "", // Ensure phoneNumber is initialized as an empty string
    timing: "",
    zoomLink: "Will be shared through email",
    monthlyCost: 40,
    preRequisites: "Basic understanding of the subject.",
    learningObjectives:
      "By the end of this course, students will be able to understand the fundamentals and apply them in real-world scenarios.",
    syllabus:
      "Week 1: Introduction\nWeek 2: Core Concepts\nWeek 3: Advanced Topics\nWeek 4: Practical Applications",
    materialNeeded: "Laptop, Notebook, Internet Connection",
    resources:
      "Relevant books, reading materials, and online resources will be shared during the course. Additional references, including website links and supplementary materials, may be provided as needed to support learning.",
    minAge: 3,
    maxAge: 18,
    language: "English",
    certification: false,
    totalSeats: null,
    startDate: null,
    endDate: null,
    freeDemosOffered: true,
    courseVideoLink: "", // New field for course video link
    categories: {
      kids: false,
      programming: false,
      languages: false,
      certifications: false,
      live: true,
    },
    images: [null, null, null],
  });

  // State for helper text
  const [helperText, setHelperText] = useState({
    courseFormat: "",
    courseTags: "",
    courseTitle: "",
    courseDescription: "",
    duration: "",
    sessionSchedule: "",
    level: "",
    timeZone: "",
    instructor: "",
    phoneNumber: "",
    zoomLink: "",
    monthlyCost: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  // Refs for scrolling to the first error field
  const courseFormatRef = useRef(null);
  const courseTagsRef = useRef(null);
  const courseTitleRef = useRef(null);
  const courseDescriptionRef = useRef(null);
  const durationRef = useRef(null);
  const sessionScheduleRef = useRef(null);
  const levelRef = useRef(null);
  const timeZoneRef = useRef(null);
  const instructorRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const zoomLinkRef = useRef(null);
  const monthlyCostRef = useRef(null);
  const categoriesRef = useRef(null);
  const imagesRef = useRef(null);
  const videoChunksRef = useRef([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear errors and helper text when the user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    setHelperText((prevHelperText) => ({ ...prevHelperText, [name]: "" }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneNumber: value || "", // Use an empty string if value is undefined
    }));
    setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: false }));
    setHelperText((prevHelperText) => ({
      ...prevHelperText,
      phoneNumber: "",
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      categories: { ...formData.categories, [name]: checked },
    });
    setErrors((prevErrors) => ({ ...prevErrors, categories: false }));
    setHelperText((prevHelperText) => ({ ...prevHelperText, categories: "" }));
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    const newImages = [...formData.images];
    const newErrors = { ...errors };

    if (file && file.size <= 1.8 * 1024 * 1024) {
      // 1.8 MB limit
      newImages[index] = file;
      setFormData({ ...formData, images: newImages });

      // Clear the specific image error
      if (newErrors.images && typeof newErrors.images === "object") {
        newErrors.images[index] = null;
        if (Object.values(newErrors.images).every((error) => error === null)) {
          delete newErrors.images;
        }
      }
    } else {
      if (!newErrors.images || typeof newErrors.images === "string") {
        newErrors.images = {};
      }
      newErrors.images[index] = "Each image must be less than 1.8MB";
    }
    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = {};
    const newHelperText = {};

    // Validate courseFormat
    if (!formData.courseFormat) {
      newErrors.courseFormat = true;
      newHelperText.courseFormat = "Course format is required.";
    }

    // Validate courseTags
    if (formData.courseTags.length === 0) {
      newErrors.courseTags = true;
      newHelperText.courseTags = "At least one tag is required.";
    }

    // Validate courseTitle
    if (!formData.courseName.trim()) {
      newErrors.courseTitle = true;
      newHelperText.courseTitle = "Course title is required.";
    } else if (formData.courseName.length > 100) {
      newErrors.courseTitle = true;
      newHelperText.courseTitle = "Course title cannot exceed 100 characters.";
    }

    // Validate courseDescription
    if (!formData.courseDescription.trim()) {
      newErrors.courseDescription = true;
      newHelperText.courseDescription = "Course description is required.";
    } else if (formData.courseDescription.length > 5000) {
      newErrors.courseDescription = true;
      newHelperText.courseDescription =
        "Course description cannot exceed 5000 characters.";
    }

    // Validate duration
    if (!formData.duration) {
      newErrors.duration = true;
      newHelperText.duration = "Duration is required.";
    }

    // Validate sessionSchedule
    if (!formData.sessionSchedule) {
      newErrors.sessionSchedule = true;
      newHelperText.sessionSchedule = "Session schedule is required.";
    }

    // Validate level
    if (!formData.level) {
      newErrors.level = true;
      newHelperText.level = "Level is required.";
    }

    // Validate timeZone
    if (!formData.timeZone) {
      newErrors.timeZone = true;
      newHelperText.timeZone = "Timezone is required.";
    }

    // Validate instructor
    if (!formData.instructor.trim()) {
      newErrors.instructor = true;
      newHelperText.instructor = "Instructor name is required.";
    }

    // Validate phoneNumber
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = true;
      newHelperText.phoneNumber = "Phone number is required.";
    }

    // Validate zoomLink
    if (!formData.zoomLink.trim()) {
      newErrors.zoomLink = true;
      newHelperText.zoomLink = "Meeting link is required.";
    }

    // Validate monthlyCost
    if (!formData.monthlyCost) {
      newErrors.monthlyCost = true;
      newHelperText.monthlyCost = "Monthly cost is required.";
    } else if (
      isNaN(formData.monthlyCost) ||
      formData.monthlyCost < 0 ||
      formData.monthlyCost > 1000
    ) {
      newErrors.monthlyCost = true;
      newHelperText.monthlyCost = "Monthly cost must be between 0 and 1000.";
    }

    // Validate categories
    const selectedCategories = Object.keys(formData.categories).filter(
      (category) => formData.categories[category]
    );
    if (selectedCategories.length === 0) {
      newErrors.categories = true;
      newHelperText.categories = "At least one category must be selected.";
    }

    // Validate images
    if (formData.images.every((image) => image === null)) {
      newErrors.images = "At least one image is required";
    }

    // Set errors and helper text
    setErrors(newErrors);
    setHelperText(newHelperText);

    // Scroll to the first error field
    const firstErrorField = Object.keys(newErrors)[0];
    if (firstErrorField) {
      const refMap = {
        courseFormat: courseFormatRef,
        courseTags: courseTagsRef,
        courseTitle: courseTitleRef,
        courseDescription: courseDescriptionRef,
        duration: durationRef,
        sessionSchedule: sessionScheduleRef,
        level: levelRef,
        timeZone: timeZoneRef,
        instructor: instructorRef,
        phoneNumber: instructorRef,
        zoomLink: zoomLinkRef,
        monthlyCost: monthlyCostRef,
        categories: categoriesRef,
        images: imagesRef,
      };
      refMap[firstErrorField]?.current?.scrollIntoView({ behavior: "smooth" });
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);

      let videoUrl = formData.video_link;

      // If a recorded video is present, upload it first
      if (videoChunksRef.current.length > 0) {
        const videoBlob = new Blob(videoChunksRef.current, {
          type: "video/webm",
        });
        videoUrl = await uploadVideo(videoBlob);
      }

      const baseUrl = getBaseUrl();
      const formDataToSend = new FormData();
      formDataToSend.append("course_format", formData.courseFormat);
      formDataToSend.append("course_tags", JSON.stringify(formData.courseTags));
      formDataToSend.append("course_name", formData.courseName);
      formDataToSend.append("course_description", formData.courseDescription);
      formDataToSend.append("duration", formData.duration);
      formDataToSend.append("level", formData.level);
      formDataToSend.append("time_zone", formData.timeZone);
      formDataToSend.append("phone_number", formData.phoneNumber);
      formDataToSend.append(
        "meetingDetails",
        JSON.stringify({
          timing: formData.sessionSchedule,
          instructor: formData.instructor,
          zoomLink: formData.zoomLink,
        })
      );
      formDataToSend.append("session_schedule", formData.sessionSchedule);
      formDataToSend.append("monthly_cost", formData.monthlyCost);
      formDataToSend.append("pre_requisites", formData.preRequisites);
      formDataToSend.append("learning_objectives", formData.learningObjectives);
      formDataToSend.append("syllabus", formData.syllabus);
      formDataToSend.append("material_needed", formData.materialNeeded);
      formDataToSend.append("resources", formData.resources);
      formDataToSend.append("min_age", formData.minAge);
      formDataToSend.append("max_age", formData.maxAge);
      formDataToSend.append("language", formData.language);
      formDataToSend.append("certification", formData.certification);
      formDataToSend.append("total_seats", formData.totalSeats);
      formDataToSend.append("start_date", formData.startDate);
      formDataToSend.append("end_date", formData.endDate);
      formDataToSend.append("free_demos_offered", formData.freeDemosOffered);
      formDataToSend.append("user_email", localStorage.getItem("email")); // Append user email
      formDataToSend.append("course_video_link", videoUrl);
      
      const selectedCategories = Object.keys(formData.categories).filter(
        (category) => formData.categories[category]
      );
      selectedCategories.push("courses"); // Add 'courses' category
      formDataToSend.append("categories", JSON.stringify(selectedCategories));
      formData.images.forEach((image, index) => {
        if (image) {
          formDataToSend.append(`image_${index}`, image);
        }
      });

      const response = await fetch(`${baseUrl}/api/create-course`, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to create course");
      }
      toast.success("Course created successfully");
      setErrorMessage("Course created successfully.");
      
      if (location.pathname.includes("/Onboarding")) {
        setStep2Complete(true);
      } else if (location.pathname.includes("/CreateNewCourse")) {
        navigate("/MyAccount/Teaching");
      }
    } catch (error) {
      toast.error("Failed to create course. Please try again later.");
      console.error("Error creating course:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            maxWidth: "800px", // Adjust max-width as needed
            padding: "20px",
            borderRadius: "10px",
            overflow: "auto",
            marginBottom: "150px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontFamily: "Times New Roman",
              marginBottom: 4, // Add spacing below the title
            }}
          >
            Design Your Course: Tell Us What You'll Teach
          </Typography>
  
          {/* Use Grid to structure the form components */}
          <Grid container spacing={3}>
            {/* Course Format */}
            <Grid item xs={12}>
              <CourseFormat
                formData={formData}
                handleChange={handleChange}
                error={errors.courseFormat}
                helperText={helperText.courseFormat}
                ref={courseFormatRef}
              />
            </Grid>
  
            {/* Course Tags */}
            <Grid item xs={12}>
              <CourseTags
                formData={formData}
                handleChange={handleChange}
                error={errors.courseTags}
                helperText={helperText.courseTags}
                ref={courseTagsRef}
              />
            </Grid>
  
            {/* Course Title */}
            <Grid item xs={12}>
              <CourseTitle
                formData={formData}
                handleChange={handleChange}
                error={errors.courseTitle}
                helperText={helperText.courseTitle}
                ref={courseTitleRef}
              />
            </Grid>
  
            {/* Course Description */}
            <Grid item xs={12}>
              <CourseDescription
                formData={formData}
                handleChange={handleChange}
                error={errors.courseDescription}
                helperText={helperText.courseDescription}
                ref={courseDescriptionRef}
              />
            </Grid>
  
            {/* Course Timing and Level */}
            <Grid item xs={12}>
              <CourseTimingAndLevel
                formData={formData}
                handleChange={handleChange}
                error={errors.sessionSchedule}
                helperText={helperText.sessionSchedule}
                ref={sessionScheduleRef}
              />
            </Grid>
  
            {/* Course Instructor */}
            <Grid item xs={12}>
              <CourseInstructor
                formData={formData}
                handleChange={handleChange}
                handlePhoneChange={handlePhoneChange}
                error={errors}
                helperText={helperText}
                ref={instructorRef}
              />
            </Grid>
  
            {/* Course Meeting Link */}
            <Grid item xs={12}>
              <CourseMeetingLink
                formData={formData}
                handleChange={handleChange}
                error={errors.zoomLink}
                helperText={helperText.zoomLink}
                ref={zoomLinkRef}
              />
            </Grid>
  
            {/* Course Cost */}
            <Grid item xs={12}>
              <CourseCost
                formData={formData}
                handleChange={handleChange}
                error={errors.monthlyCost}
                helperText={helperText.monthlyCost}
                ref={monthlyCostRef}
              />
            </Grid>
  
            {/* Course Content Details */}
            <Grid item xs={12}>
              <CourseContentDetails
                formData={formData}
                handleChange={handleChange}
              />
            </Grid>
  
            {/* Course Miscellaneous */}
            <Grid item xs={12}>
              <CourseMiscellaneous
                formData={formData}
                handleChange={handleChange}
              />
            </Grid>
  
            {/* Video Upload */}
            <Grid item xs={12}>
              <VideoUpload
                formData={formData}
                setFormData={setFormData}
                videoChunksRef={videoChunksRef}
              />
            </Grid>
  
            {/* Course Categories */}
            <Grid item xs={12}>
              <CourseCategories
                formData={formData}
                handleCheckboxChange={handleCheckboxChange}
                error={errors.categories}
                helperText={helperText.categories}
                refs={{ categoriesRef }}
              />
            </Grid>
  
            {/* File Input */}
            <Grid item xs={12}>
              <FileInput
                formData={formData}
                handleFileChange={handleFileChange}
                errors={errors}
                setErrors={setErrors}
                refs={{ imagesRef }}
              />
            </Grid>
  
            {/* Submit Button */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 4, // Add spacing above the button
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  className="submitButton"
                >
                  Create Course
                </Button>
              </Box>
            </Grid>
  
            {/* Error Message */}
            {errorMessage && (
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ textAlign: "center", marginTop: 2 }} // Add spacing above the error message
                >
                  {errorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default withAlert()(CreateNewCourseDetails);

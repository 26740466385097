import React, { useState, useRef } from "react";
import { getBaseUrl } from "../Utils/getBaseUrl";
import Spinner from "../Spinner";
import { Button, Box, Paper, Typography, Grid } from "@mui/material";
import { withAlert } from "react-alert";
import "./style.css";
import CourseCategories from "../OnboardTeacher/CreateNewCourse/CourseCategories";
import FileInput from "../CommonComponents/FileInput/FileInput";
import { toast } from 'react-toastify';
import CourseFormat from "../OnboardTeacher/CreateNewCourse/components/CourseFormat";
import CourseTags from "../OnboardTeacher/CreateNewCourse/components/CourseTags";
import CourseTitle from "../OnboardTeacher/CreateNewCourse/components/CourseTitle";
import CourseDescription from "../OnboardTeacher/CreateNewCourse/components/CourseDescription";
import CourseTimingAndLevel from "../OnboardTeacher/CreateNewCourse/components/CourseTimingAndLevel";
import CourseInstructor from "../OnboardTeacher/CreateNewCourse/components/CourseInstructor";
import CourseMeetingLink from "../OnboardTeacher/CreateNewCourse/components/CourseMeetingLink";
import CourseCost from "../OnboardTeacher/CreateNewCourse/components/CourseCost";
import CourseContentDetails from "../OnboardTeacher/CreateNewCourse/components/CourseContentDetails";
import CourseMiscellaneous from "../OnboardTeacher/CreateNewCourse/components/CourseMiscellaneous";
import VideoUpload from "../CommonComponents/VideoUpload/VideoUpload";
import uploadVideo from "../Utils/uploadVideo";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

function UpdateCourseDetails({metaData}) {
  const navigate = useNavigate();

  const initialCategories = {
    kids: false,
    programming: false,
    languages: false,
    certifications: false,
    live: false,
    courses: false,
  };

  const categories = metaData.categories.reduce((acc, category) => {
    if (acc.hasOwnProperty(category)) {
      acc[category] = true;
    }
    return acc;
  }, { ...initialCategories });

  const initialFormData = {
    courseFormat: metaData.course_format || "live",
    courseTags: metaData.course_tags || [],
    courseName: metaData.name || "",
    courseDescription: metaData.description || "",
    duration: metaData.duration || "55 minutes per session",
    level: metaData.level || "Beginner",
    sessionSchedule: metaData.timing || "",
    timeZone: metaData.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    instructor: metaData.instructor || "",
    phoneNumber: metaData.phone_number || "",
    timing: metaData.timing || "",
    zoomLink: metaData.class_meeting_link || "Will be shared through email",
    monthlyCost: metaData.cost || 40,
    preRequisites: metaData.pre_requisites || "Basic understanding of the subject.",
    learningObjectives: metaData.learning_objectives || "By the end of this course, students will be able to understand the fundamentals and apply them in real-world scenarios.",
    syllabus: metaData.syllabus || "Week 1: Introduction\nWeek 2: Core Concepts\nWeek 3: Advanced Topics\nWeek 4: Practical Applications",
    materialNeeded: metaData.material_needed || "Laptop, Notebook, Internet Connection",
    resources: metaData.resources || "Relevant books, reading materials, and online resources will be shared during the course. Additional references, including website links and supplementary materials, may be provided as needed to support learning.",
    minAge: metaData.min_age || 3,
    maxAge: metaData.max_age || 18,
    language: metaData.language || "English",
    certification: metaData.certification || false,
    totalSeats: metaData.total_seats || null,
    startDate: metaData.start_date ? new Date(metaData.start_date) : null,
    endDate: metaData.end_date ? new Date(metaData.end_date) : null,
    freeDemosOffered: metaData.free_demos_offered || true,
    courseVideoLink: metaData.promotional_video_link || "",
    categories: categories,
    images: [null, null, null], // Initialize with null or existing images if available
  };

  const [formData, setFormData] = useState(initialFormData);

  // State for helper text
  const [helperText, setHelperText] = useState({
    courseFormat: "",
    courseTags: "",
    courseTitle: "",
    courseDescription: "",
    duration: "",
    sessionSchedule: "",
    level: "",
    timeZone: "",
    instructor: "",
    phoneNumber: "",
    zoomLink: "",
    monthlyCost: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  // Refs for scrolling to the first error field
  const courseFormatRef = useRef(null);
  const courseTagsRef = useRef(null);
  const courseTitleRef = useRef(null);
  const courseDescriptionRef = useRef(null);
  const durationRef = useRef(null);
  const sessionScheduleRef = useRef(null);
  const levelRef = useRef(null);
  const timeZoneRef = useRef(null);
  const instructorRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const zoomLinkRef = useRef(null);
  const monthlyCostRef = useRef(null);
  const categoriesRef = useRef(null);
  const imagesRef = useRef(null);
  const videoChunksRef = useRef([]);

  const getChangedFields = () => {
    const changedFields = {};
    const fieldMapping = {
      courseFormat: "course_format",
      courseTags: "course_tags",
      courseName: "name",
      courseDescription: "description",
      duration: "duration",
      level: "level",
      sessionSchedule: "timing",
      timeZone: "time_zone",
      instructor: "instructor",
      phoneNumber: "phone_number",
      timing: "timing",
      zoomLink: "class_meeting_link",
      monthlyCost: "cost",
      preRequisites: "pre_requisites",
      learningObjectives: "learning_objectives",
      syllabus: "syllabus",
      materialNeeded: "material_needed",
      resources: "resources",
      minAge: "min_age",
      maxAge: "max_age",
      language: "language",
      certification: "certification",
      totalSeats: "total_seats",
      startDate: "start_date",
      endDate: "end_date",
      freeDemosOffered: "free_demos_offered",
      courseVideoLink: "promotional_video_link",
      images: "images"
    };
  
    Object.keys(formData).forEach((key) => {
      const metaDataKey = fieldMapping[key];
      if (metaDataKey) {
        if (key === "startDate" || key === "endDate") {
          // Compare only the dates, not the times
          const formDate = formData[key] ? new Date(formData[key]).setHours(0, 0, 0, 0) : null;
          const metaDate = metaData[metaDataKey] ? new Date(metaData[metaDataKey]).setHours(0, 0, 0, 0) : null;
          if (!_.isEqual(formDate, metaDate)) {
            changedFields[key] = formData[key];
          }
        } else if (!_.isEqual(formData[key], metaData[metaDataKey])) {
          changedFields[key] = formData[key];
        }
      }
    });
  
    // Handle categories comparison separately
    const selectedCategories = Object.keys(formData.categories).filter(
      (category) => formData.categories[category]
    );
    if (!_.isEqual(selectedCategories.sort(), metaData.categories.sort())) {
      changedFields.categories = selectedCategories;
    }
  
    return changedFields;
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear errors and helper text when the user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    setHelperText((prevHelperText) => ({ ...prevHelperText, [name]: "" }));
    setIsChanged(true);
  };

  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneNumber: value || "", // Use an empty string if value is undefined
    }));
    setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: false }));
    setHelperText((prevHelperText) => ({
      ...prevHelperText,
      phoneNumber: "",
    }));
    setIsChanged(true);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      categories: { ...formData.categories, [name]: checked },
    });
    setErrors((prevErrors) => ({ ...prevErrors, categories: false }));
    setHelperText((prevHelperText) => ({ ...prevHelperText, categories: "" }));
    setIsChanged(true);
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    const newImages = [...formData.images];
    const newErrors = { ...errors };

    if (file && file.size <= 1.8 * 1024 * 1024) {
      // 1.8 MB limit
      newImages[index] = file;
      setFormData({ ...formData, images: newImages });

      // Clear the specific image error
      if (newErrors.images && typeof newErrors.images === "object") {
        newErrors.images[index] = null;
        if (Object.values(newErrors.images).every((error) => error === null)) {
          delete newErrors.images;
        }
      }
    } else {
      if (!newErrors.images || typeof newErrors.images === "string") {
        newErrors.images = {};
      }
      newErrors.images[index] = "Each image must be less than 1.8MB";
    }
    setErrors(newErrors);
    setIsChanged(true);
  };

  const validateForm = () => {
    const newErrors = {};
    const newHelperText = {};

    // Validate courseFormat
    if (!formData.courseFormat) {
      newErrors.courseFormat = true;
      newHelperText.courseFormat = "Course format is required.";
    }

    // Validate courseTags
    if (formData.courseTags.length === 0) {
      newErrors.courseTags = true;
      newHelperText.courseTags = "At least one tag is required.";
    }

    // Validate courseTitle
    if (!formData.courseName.trim()) {
      newErrors.courseTitle = true;
      newHelperText.courseTitle = "Course title is required.";
    } else if (formData.courseName.length > 100) {
      newErrors.courseTitle = true;
      newHelperText.courseTitle = "Course title cannot exceed 100 characters.";
    }

    // Validate courseDescription
    if (!formData.courseDescription.trim()) {
      newErrors.courseDescription = true;
      newHelperText.courseDescription = "Course description is required.";
    } else if (formData.courseDescription.length > 5000) {
      newErrors.courseDescription = true;
      newHelperText.courseDescription =
        "Course description cannot exceed 5000 characters.";
    }

    // Validate duration
    if (!formData.duration) {
      newErrors.duration = true;
      newHelperText.duration = "Duration is required.";
    }

    // Validate sessionSchedule
    if (!formData.sessionSchedule) {
      newErrors.sessionSchedule = true;
      newHelperText.sessionSchedule = "Session schedule is required.";
    }

    // Validate level
    if (!formData.level) {
      newErrors.level = true;
      newHelperText.level = "Level is required.";
    }

    // Validate timeZone
    if (!formData.timeZone) {
      newErrors.timeZone = true;
      newHelperText.timeZone = "Timezone is required.";
    }

    // Validate instructor
    if (!formData.instructor.trim()) {
      newErrors.instructor = true;
      newHelperText.instructor = "Instructor name is required.";
    }

    // Validate phoneNumber
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = true;
      newHelperText.phoneNumber = "Phone number is required.";
    }

    // Validate zoomLink
    if (!formData.zoomLink.trim()) {
      newErrors.zoomLink = true;
      newHelperText.zoomLink = "Meeting link is required.";
    }

    // Validate monthlyCost
    if (!formData.monthlyCost) {
      newErrors.monthlyCost = true;
      newHelperText.monthlyCost = "Monthly cost is required.";
    } else if (
      isNaN(formData.monthlyCost) ||
      formData.monthlyCost < 0 ||
      formData.monthlyCost > 1000
    ) {
      newErrors.monthlyCost = true;
      newHelperText.monthlyCost = "Monthly cost must be between 0 and 1000.";
    }

    // Validate categories
    const selectedCategories = Object.keys(formData.categories).filter(
      (category) => formData.categories[category]
    );
    if (selectedCategories.length === 0) {
      newErrors.categories = true;
      newHelperText.categories = "At least one category must be selected.";
    }

    // Set errors and helper text
    setErrors(newErrors);
    setHelperText(newHelperText);

    // Scroll to the first error field
    const firstErrorField = Object.keys(newErrors)[0];
    if (firstErrorField) {
      const refMap = {
        courseFormat: courseFormatRef,
        courseTags: courseTagsRef,
        courseTitle: courseTitleRef,
        courseDescription: courseDescriptionRef,
        duration: durationRef,
        sessionSchedule: sessionScheduleRef,
        level: levelRef,
        timeZone: timeZoneRef,
        instructor: instructorRef,
        phoneNumber: instructorRef,
        zoomLink: zoomLinkRef,
        monthlyCost: monthlyCostRef,
        categories: categoriesRef,
        images: imagesRef,
      };
      refMap[firstErrorField]?.current?.scrollIntoView({ behavior: "smooth" });
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);

      // If a recorded video is present, upload it first
      if (videoChunksRef.current.length > 0) {
        const videoBlob = new Blob(videoChunksRef.current, {
          type: "video/webm",
        });
        formData.video_link = await uploadVideo(videoBlob);
      }
      const baseUrl = getBaseUrl();
      const formDataToSend = new FormData();
      const changedFields = getChangedFields();

      formDataToSend.append("course_id", metaData.id);

      if (changedFields.courseFormat !== null && changedFields.courseFormat !== undefined) {
        formDataToSend.append("course_format", changedFields.courseFormat);
      }
      if (changedFields.courseTags !== null && changedFields.courseTags !== undefined) {
        formDataToSend.append("course_tags", JSON.stringify(changedFields.courseTags));
      }
      if (changedFields.courseName !== null && changedFields.courseName !== undefined) {
        formDataToSend.append("course_name", changedFields.courseName);
      }
      if (changedFields.courseDescription !== null && changedFields.courseDescription !== undefined) {
        formDataToSend.append("course_description", changedFields.courseDescription);
      }
      if (changedFields.duration !== null && changedFields.duration !== undefined) {
        formDataToSend.append("duration", changedFields.duration);
      }
      if (changedFields.level !== null && changedFields.level !== undefined) {
        formDataToSend.append("level", changedFields.level);
      }
      if (changedFields.sessionSchedule !== null && changedFields.sessionSchedule !== undefined) {
        formDataToSend.append("session_schedule", changedFields.sessionSchedule);
      }
      if (changedFields.timeZone !== null && changedFields.timeZone !== undefined) {
        formDataToSend.append("time_zone", changedFields.timeZone);
      }
      if (changedFields.phoneNumber !== null && changedFields.phoneNumber !== undefined) {
        formDataToSend.append("phone_number", changedFields.phoneNumber);
      }
      if (changedFields.categories !== null && changedFields.categories !== undefined) {
        const selectedCategories = Object.keys(formData.categories).filter(
          (category) => formData.categories[category]
        );
        formDataToSend.append("categories", JSON.stringify(selectedCategories));
      }
      if (changedFields.images !== null && changedFields.images !== undefined) {
        changedFields.images.forEach((image, index) => {
          if (image) {
            formDataToSend.append(`image_${index}`, image);
          }
        });
      }
      if (changedFields.sessionSchedule || changedFields.instructor || changedFields.zoomLink) {
        formDataToSend.append(
          "meetingDetails",
          JSON.stringify({
            timing: changedFields.sessionSchedule || formData.sessionSchedule,
            instructor: changedFields.instructor || formData.instructor,
            zoomLink: changedFields.zoomLink || formData.zoomLink,
          })
        );
      }
      if (changedFields.monthlyCost !== null && changedFields.monthlyCost !== undefined) {
        formDataToSend.append("monthly_cost", changedFields.monthlyCost);
      }
      if (changedFields.preRequisites !== null && changedFields.preRequisites !== undefined) {
        formDataToSend.append("pre_requisites", changedFields.preRequisites);
      }
      if (changedFields.learningObjectives !== null && changedFields.learningObjectives !== undefined) {
        formDataToSend.append("learning_objectives", changedFields.learningObjectives);
      }
      if (changedFields.syllabus !== null && changedFields.syllabus !== undefined) {
        formDataToSend.append("syllabus", changedFields.syllabus);
      }
      if (changedFields.materialNeeded !== null && changedFields.materialNeeded !== undefined) {
        formDataToSend.append("material_needed", changedFields.materialNeeded);
      }
      if (changedFields.resources !== null && changedFields.resources !== undefined) {
        formDataToSend.append("resources", changedFields.resources);
      }
      if (changedFields.minAge !== null && changedFields.minAge !== undefined) {
        formDataToSend.append("min_age", changedFields.minAge);
      }
      if (changedFields.maxAge !== null && changedFields.maxAge !== undefined) {
        formDataToSend.append("max_age", changedFields.maxAge);
      }
      if (changedFields.language !== null && changedFields.language !== undefined) {
        formDataToSend.append("language", changedFields.language);
      }
      if (changedFields.certification !== null && changedFields.certification !== undefined) {
        formDataToSend.append("certification", changedFields.certification);
      }
      if (changedFields.totalSeats !== null && changedFields.totalSeats !== undefined) {
        formDataToSend.append("total_seats", changedFields.totalSeats);
      }
      if (changedFields.startDate !== null && changedFields.startDate !== undefined) {
        formDataToSend.append("start_date", changedFields.startDate);
      }
      if (changedFields.endDate !== null && changedFields.endDate !== undefined) {
        formDataToSend.append("end_date", changedFields.endDate);
      }
      if (changedFields.freeDemosOffered !== null && changedFields.freeDemosOffered !== undefined) {
        formDataToSend.append("free_demos_offered", changedFields.freeDemosOffered);
      }
      if (changedFields.courseVideoLink !== null && changedFields.courseVideoLink !== undefined) {
        formDataToSend.append("course_video_link", changedFields.courseVideoLink);
      }
      formDataToSend.append("user_email", localStorage.getItem("email"));

      const response = await fetch(`${baseUrl}/api/update-course-metadata`, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to update course");
      }
      toast.success('Course updated successfully');
      setErrorMessage("Course updated successfully.");
      navigate("/MyAccount/Teaching");
    } catch (error) {
      toast.error('Failed to update course. Please try again later');
      setErrorMessage("Failed to update course. Please try again later.");
      console.error("Error creating course:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            maxWidth: "800px", // Adjust max-width as needed
            padding: "20px",
            borderRadius: "10px",
            overflow: "auto",
            marginBottom: "150px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontFamily: "Times New Roman",
              marginBottom: 4, // Add spacing below the title
            }}
          >
            Update Course Details
          </Typography>
  
          {/* Use Grid to structure the form components */}
          <Grid container spacing={3}>
            {/* Course Format */}
            <Grid item xs={12}>
              <CourseFormat
                formData={formData}
                handleChange={handleChange}
                error={errors.courseFormat}
                helperText={helperText.courseFormat}
                ref={courseFormatRef}
              />
            </Grid>
  
            {/* Course Tags */}
            <Grid item xs={12}>
              <CourseTags
                formData={formData}
                handleChange={handleChange}
                error={errors.courseTags}
                helperText={helperText.courseTags}
                ref={courseTagsRef}
              />
            </Grid>
  
            {/* Course Title */}
            <Grid item xs={12}>
              <CourseTitle
                formData={formData}
                handleChange={handleChange}
                error={errors.courseTitle}
                helperText={helperText.courseTitle}
                ref={courseTitleRef}
              />
            </Grid>
  
            {/* Course Description */}
            <Grid item xs={12}>
              <CourseDescription
                formData={formData}
                handleChange={handleChange}
                error={errors.courseDescription}
                helperText={helperText.courseDescription}
                ref={courseDescriptionRef}
              />
            </Grid>
  
            {/* Course Timing and Level */}
            <Grid item xs={12}>
              <CourseTimingAndLevel
                formData={formData}
                handleChange={handleChange}
                error={errors.sessionSchedule}
                helperText={helperText.sessionSchedule}
                ref={sessionScheduleRef}
              />
            </Grid>
  
            {/* Course Instructor */}
            <Grid item xs={12}>
              <CourseInstructor
                formData={formData}
                handleChange={handleChange}
                handlePhoneChange={handlePhoneChange}
                error={errors}
                helperText={helperText}
                ref={instructorRef}
              />
            </Grid>
  
            {/* Course Meeting Link */}
            <Grid item xs={12}>
              <CourseMeetingLink
                formData={formData}
                handleChange={handleChange}
                error={errors.zoomLink}
                helperText={helperText.zoomLink}
                ref={zoomLinkRef}
              />
            </Grid>
  
            {/* Course Cost */}
            <Grid item xs={12}>
              <CourseCost
                formData={formData}
                handleChange={handleChange}
                error={errors.monthlyCost}
                helperText={helperText.monthlyCost}
                ref={monthlyCostRef}
              />
            </Grid>
  
            {/* Course Content Details */}
            <Grid item xs={12}>
              <CourseContentDetails
                formData={formData}
                handleChange={handleChange}
              />
            </Grid>
  
            {/* Course Miscellaneous */}
            <Grid item xs={12}>
              <CourseMiscellaneous
                formData={formData}
                handleChange={handleChange}
              />
            </Grid>
  
            {/* Video Upload */}
            <Grid item xs={12}>
              <VideoUpload
                formData={formData}
                setFormData={setFormData}
                videoChunksRef={videoChunksRef}
              />
            </Grid>
  
            {/* Course Categories */}
            <Grid item xs={12}>
              <CourseCategories
                formData={formData}
                handleCheckboxChange={handleCheckboxChange}
                error={errors.categories}
                helperText={helperText.categories}
                refs={{ categoriesRef }}
              />
            </Grid>
  
            {/* File Input */}
            <Grid item xs={12}>
              <FileInput
                formData={formData}
                handleFileChange={handleFileChange}
                errors={errors}
                setErrors={setErrors}
                refs={{ imagesRef }}
              />
            </Grid>
  
            {/* Submit Button */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 4, // Add spacing above the button
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  className="submitButton"
                  disabled={!isChanged}
                >
                  Update Course
                </Button>
              </Box>
            </Grid>
  
            {/* Error Message */}
            {errorMessage && (
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ textAlign: "center", marginTop: 2 }} // Add spacing above the error message
                >
                  {errorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

export default withAlert()(UpdateCourseDetails);
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Pagination,
  Grid,
  Typography,
  CircularProgress,
  Skeleton,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  FilterList as FilterListIcon,
  Sort as SortIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import { getBaseUrl } from "../../components/Utils/getBaseUrl";
import Spinner from "../Spinner";
import ShelfHeader from "./ShelfHeader";
import ProductList from "./ProductList";
import Filter from "./Filter";
import "./style.css";
import { debounce } from "lodash";

const EMAIL = "email";

const ShelfCollection = ({ history, categoryName, currency, searchText }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState(searchText || "");
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState("latest");
  const [error, setError] = useState(null);
  const [showClearSearch, setShowClearSearch] = useState(false);

  const debouncedFetchProducts = debounce(() => {
    handleFetchProducts();
  }, 500);

  useEffect(() => {
    debouncedFetchProducts();
    return () => debouncedFetchProducts.cancel();
  }, [currentPage, pageSize, searchTerm, categoryName, filters, sort]);

  const handleFetchProducts = async () => {
    setIsLoading(true);
    setError(null);
    const baseUrl = getBaseUrl();
    const email = localStorage.getItem(EMAIL);

    const dataRequest = {
      page: currentPage,
      pageSize,
      search: searchTerm,
      category: categoryName || "",
      sortBy: sort || "latest",
      newFilters: filters,
      user_email: email,
    };

    try {
      const response = await fetch(`${baseUrl}/api/products`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }

      const data = await response.json();
      setProducts(data.products);
      setTotal(data.total);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Failed to load products. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowClearSearch(value.length > 0);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setShowClearSearch(false);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleSortChange = (newSort) => {
    setSort(newSort);
  };

  const handleCloseError = () => {
    setError(null);
  };

  return (
    <React.Fragment>
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
      <Box display="flex" justifyContent="center" flexWrap="wrap" p={2}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {/* Search Input */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search online classes and tutors"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "25px",
                  backgroundColor: "#f5f5f5",
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {showClearSearch && (
                      <IconButton
                        onClick={handleClearSearch}
                        sx={{ borderRadius: "50%" }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <IconButton sx={{ borderRadius: "50%" }}>
                      <SearchIcon />
                    </IconButton>
                  </>
                ),
              }}
            />
          </Grid>

          {/* Page Size Dropdown */}
          <Grid item xs={6} sm={3} md={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Page Size</InputLabel>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                label="Page Size"
                sx={{
                  borderRadius: "25px",
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Pagination Controls */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <IconButton
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
                aria-label="Previous Page"
                sx={{ borderRadius: "50%" }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                size="small"
                sx={{ borderRadius: "25px" }}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ margin: "0 10px" }}>
                Page {currentPage} of {totalPages}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
                aria-label="Next Page"
                sx={{ borderRadius: "50%" }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        {/* Filter and Sort */}
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={2}
          width="100%"
        >
          {" "}
          {/* Added gap and width */}
          <Box display="flex" justifyContent="center" mt={1}>
            <Filter
              onFilterChange={handleFilterChange}
              initialFilters={filters}
            />
          </Box>
          <Box display="flex" justifyContent="center" mt={1}>
            <ShelfHeader
              productsLength={total ?? 0}
              onSortChange={handleSortChange}
            />
          </Box>
        </Box>

        {/* Product List */}
        {isLoading ? (
          <Grid container spacing={2}>
            {Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" height={200} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <ProductList
            products={products}
            history={history}
            currency={currency}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

ShelfCollection.propTypes = {
  history: PropTypes.object.isRequired,
  categoryName: PropTypes.string.isRequired,
  currency: PropTypes.object,
  searchText: PropTypes.string,
};

export default ShelfCollection;

import React from "react";
import { Box, Chip } from "@mui/material";

const StudentList = ({ students, selectedStudentId, onStudentClick }) => {
  return (
    <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
      {students.map((student) => (
        <Chip
          key={student.sender_id}
          label={student.sender_name}
          onClick={() => onStudentClick(student.sender_id)}
          color={selectedStudentId === student.sender_id ? "primary" : "default"}
        />
      ))}
    </Box>
  );
};

export default StudentList;
import React, {useState, useEffect} from 'react';


import FloatCart from '../FloatCart';
import Header from '../Header';
import Footer from '../Footer';
import HamburgerMenu from '../HamburgerMenu';
import './style.css';
import Card from 'react-bootstrap/Card';
import booksImage from '../../static/products/GhummanTech/books.jpg';
import coursesImage from '../../static/products/GhummanTech/courses.jpg';
import { useNavigate, useLocation } from 'react-router-dom';

const Collections = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const navigate = useNavigate();

  const signMeIn = () => {
    navigate('/Login');
  };

  useEffect(() => {
    if (!menuHidden) {
      document.body.setAttribute("style", "overflow: hidden; position: static;");
    } else {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    }
    // Cleanup function to reset the body style when the component unmounts or when menuHidden changes again
    return () => {
      document.body.setAttribute("style", "overflow: visible; position: static;");
    };
  }, [menuHidden]);

  useEffect(() => {
    document.body.setAttribute("style", "overflow: visible; position: static;");
  }, []);

  const openFloatCart = () => {
    setIsOpen(!isOpen);
  };

  const openMenuWindow = () => {
    setMenuHidden(false);
  };

  const isMenuOpen = (state) => {
    setMenuHidden(!state.isOpen);
    return state.isOpen;
  };

  const goToPAGE = (pageName, categoryName) => {
    navigate({
      pathname: `/Categories/${categoryName}`,
      state: { pageName: pageName, categoryName: categoryName }
    });
  }

  const callbackRoutingCloseMenu = () => {
    setMenuHidden(true);
  };




  return (
    <div id="outer-container">



      <HamburgerMenu isMenuOpenFunc={isMenuOpen} pageWrapId="page-wrap" outerContainerId="outer-container" isOpenStatus={menuHidden} history={props.history} callbackClose={callbackRoutingCloseMenu} />
      <div id="page-wrap">
        <Header openMenu={openMenuWindow} openCart={openFloatCart} signIn={signMeIn} history={props.history} />


        < div class="container">
          <div class="row justify-content-center">
            {/* <div class="col-md-4">
              <Card style={{ width: '20rem', height: '20rem', padding: "2px" }} class="shadow" onClick={() => goToPAGE('Handicrafts', 'handicrafts')}>
                <div class="inner">
                  <Card.Img alt="new arrivals" src={handicraftsImage} />
                  <Card.ImgOverlay>
                    <Card.Body class="text-center">
                      <Card.Title style={{ paddingTop: "10rem", color: "black", fontWeight: "bold" }}>HANDICRAFTS</Card.Title>
                    </Card.Body>
                  </Card.ImgOverlay>
                </div>

              </Card>
            </div> */}
            <div class="col-md-4">
  <Card style={{ width: '20rem', height: '20rem', padding: "2px" }} class="shadow" onClick={() => goToPAGE('Books', 'books')}>
    <div class="inner">
      <Card.Img alt="books" src={booksImage} />
      <Card.ImgOverlay>
        <Card.Body class="text-center d-flex align-items-center justify-content-center" style={{height: '100%'}}>
          <Card.Title style={{ color: "black", fontWeight: 'bold', textDecoration: 'underline', backgroundColor: 'rgba(255, 255, 255, 0.5)', color: 'black' }}>BOOKS</Card.Title>
        </Card.Body>
      </Card.ImgOverlay>
    </div>
  </Card>
</div>
<div class="col-md-4">
  <Card style={{ width: '20rem', height: '20rem', padding: "2px" }} class="shadow" onClick={() => goToPAGE('Courses', 'courses')}>
    <div class="inner">
      <Card.Img alt="courses" src={coursesImage} />
      <Card.ImgOverlay>
        <Card.Body class="text-center d-flex align-items-center justify-content-center" style={{height: '100%'}}>
          <Card.Title style={{ color: "black", fontWeight: 'bold', textDecoration: 'underline', backgroundColor: 'rgba(255, 255, 255, 0.5)', color: 'black' }}>COURSES</Card.Title>
        </Card.Body>
      </Card.ImgOverlay>
    </div>
  </Card>
</div>
          </div>
        </div>

        <FloatCart isTheCartOpen={isOpen} history={props.history} />
        <Footer history={props.history} />


      </div>
    </div >
  );

}

export default Collections;
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import Button from '@mui/material/Button';
import School from '@mui/icons-material/School';
import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';

const PhoneAndGradeComponent = ({ onNext, setPhone, setSelectedGrade, phone, selectedGrade, errorPhone, errorGrade }) => {
    const grades = [
        { label: 'KG', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 },
        { label: 'Adults', value: 13 },
    ];

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: '0 auto',
                padding: 4,
                textAlign: 'center',
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <Typography
                variant="h3"
                sx={{
                    fontFamily: 'Times New Roman, serif',
                    color: 'red',
                    fontWeight: 'bold',
                    marginBottom: 4,
                    fontSize: isSmallScreen ? '2rem' : '2.5rem',
                }}
            >
                Book Free Trial Class
            </Typography>

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        color: 'black',
                        marginBottom: 2,
                        fontSize: isSmallScreen ? '1.25rem' : '1.5rem',
                    }}
                >
                    Phone Number
                </Typography>
                <PhoneInput
                    country={'us'}
                    value={phone}
                    onChange={setPhone}
                    inputStyle={{
                        width: '100%',
                        padding: '12px',
                        paddingLeft: '48px',
                        fontSize: '16px',
                        borderRadius: '8px',
                        border: '2px solid #e0e0e0',
                        fontFamily: 'Times New Roman, serif',
                    }}
                    containerStyle={{ width: '100%' }}
                />
                {errorPhone && !phone && (
                    <Typography
                        sx={{
                            color: 'red',
                            fontSize: '0.875rem',
                            marginTop: 1,
                            fontFamily: 'Times New Roman, serif',
                        }}
                    >
                        {errorPhone}
                    </Typography>
                )}
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Times New Roman, serif',
                        color: 'black',
                        marginBottom: 2,
                        fontSize: isSmallScreen ? '1.25rem' : '1.5rem',
                    }}
                >
                    Select Your Grade
                </Typography>
                <Grid container spacing={2}>
                    {grades.map((grade) => (
                        <Grid item xs={6} sm={4} md={3} key={grade.value}>
                            <Box
                                sx={{
                                    padding: 2,
                                    border: '2px solid #e0e0e0',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    backgroundColor: selectedGrade === grade.value ? 'rgba(255, 0, 0, 0.1)' : 'white',
                                    borderColor: selectedGrade === grade.value ? 'red' : '#e0e0e0',
                                    color: selectedGrade === grade.value ? 'red' : 'black',
                                    fontFamily: 'Times New Roman, serif',
                                    fontSize: '1.25rem',
                                    textAlign: 'center',
                                    '&:hover': {
                                        borderColor: 'red',
                                        transform: 'translateY(-2px)',
                                        boxShadow: 2,
                                    },
                                }}
                                onClick={() => setSelectedGrade(grade.value)}
                            >
                                <strong>{grade.label}</strong>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                {errorGrade && !selectedGrade && (
                    <Typography
                        sx={{
                            color: 'red',
                            fontSize: '0.875rem',
                            marginTop: 1,
                            fontFamily: 'Times New Roman, serif',
                        }}
                    >
                        {errorGrade}
                    </Typography>
                )}
            </Box>

            <Box>
                <Button
                    variant="contained"
                    startIcon={<School />}
                    sx={{
                        backgroundColor: 'red',
                        color: 'white',
                        padding: '12px 24px',
                        fontSize: '1.25rem',
                        borderRadius: '8px',
                        fontFamily: 'Times New Roman, serif',
                        '&:hover': {
                            backgroundColor: 'darkred',
                            transform: 'translateY(-2px)',
                            boxShadow: 2,
                        },
                    }}
                    onClick={onNext}
                >
                    Select a Class
                </Button>
            </Box>
        </Box>
    );
};

export default PhoneAndGradeComponent;
import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Typography,
  Box,
  Grid,
  Chip,
  TextField,
  Autocomplete,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";

const predefinedTags = [
  "Mathematics",
  "Science",
  "Technology",
  "Engineering",
  "Arts & Crafts",
  "Music",
  "History",
  "Geography",
  "Social Studies",
  "Philosophy",
  "Learn Programming Language",
  "Computer Science",
  "Coding for Kids",
  "Python Programming",
  "Scratch for Kids",
  "C++ Programming",
  "Java Programming",
  "Web Development",
  "Mobile App Development",
  "Game Development",
  "Physics",
  "Chemistry",
  "Biology",
  "Environmental Science",
  "Space Science",
  "Astronomy",
  "Engineering Basics",
  "Electronics & Circuits",
  "Robotics",
  "3D Printing & Design",
  "Learn Language",
  "Learn Spoken Language",
  "Learn Urdu",
  "Learn Arabic",
  "Learn Spanish",
  "Learn French",
  "Learn Chinese",
  "Learn English",
  "English Grammar",
  "English Literature",
  "Public Speaking",
  "Creative Writing",
  "Essay Writing",
  "Religion",
  "Islamic Studies",
  "Christian Studies",
  "Quran Recitation & Tajweed",
  "Hadith & Sunnah",
  "Ethics & Morality",
  "Mindfulness & Meditation",
  "Critical Thinking & Problem-Solving",
  "Financial Literacy for Kids",
  "Entrepreneurship for Kids",
  "Sports & Fitness",
  "Outdoor & Adventure",
  "Cooking & Baking",
  "Health & Nutrition",
  "Yoga & Pilates",
  "Dance",
  "Photography",
  "Drawing & Painting",
  "Crafting & DIY",
  "Gardening",
  "Fashion Design",
];

// Define the component using a function declaration
function CourseTagsComponent({ formData, handleChange, error, helperText }, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);
  const [newTag, setNewTag] = useState("");

  const handleInfoClick = () => {
    if (isMobile) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddTag = (tag) => {
    if (formData.courseTags.length >= 3) {
      toast.warning("You can only select up to 3 tags.");
      return;
    }
    if (!formData.courseTags.includes(tag)) {
      handleChange({
        target: {
          name: "courseTags",
          value: [...formData.courseTags, tag],
        },
      });
    }
  };

  const handleRemoveTag = (tag) => {
    handleChange({
      target: {
        name: "courseTags",
        value: formData.courseTags.filter((t) => t !== tag),
      },
    });
  };

  const handleCreateTag = () => {
    if (newTag.trim() === "") {
      toast.error("Tag cannot be empty.");
      return;
    }
    if (newTag.length > 20) {
      toast.error("Tag cannot be longer than 20 characters.");
      return;
    }
    if (formData.courseTags.length >= 3) {
      toast.warning("You can only select up to 3 tags.");
      return;
    }
    if (!formData.courseTags.includes(newTag)) {
      handleChange({
        target: {
          name: "courseTags",
          value: [...formData.courseTags, newTag],
        },
      });
      setNewTag("");
      toast.success("Tag added successfully!");
    }
  };

  const tagsDescription =
    "Choose one primary tag. You can also create a new tag if you can't find an existing tag (max 20 characters). Although not recommended but you can select up to 3 tags.";

  return (
    <Box className="course-tags" sx={{ marginBottom: 3 }} ref={ref}>
      <FormControl component="fieldset" fullWidth error={error}>
        <FormLabel component="legend" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Course Tags *
        </FormLabel>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
              {tagsDescription}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Autocomplete
              multiple
              freeSolo
              options={predefinedTags}
              value={formData.courseTags}
              onChange={(event, value) => {
                if (value.length > 3) {
                  toast.warning("You can only select up to 3 tags.");
                  return;
                }
                handleChange({
                  target: {
                    name: "courseTags",
                    value,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select or create tags"
                  placeholder="Type to search or create a tag"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Create New Tag"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              fullWidth
              inputProps={{ maxLength: 20 }}
              helperText={`${newTag.length}/20 characters`}
              sx={{ marginBottom: 1 }}
            />
            <Button
              variant="contained"
              onClick={handleCreateTag}
              sx={{ width: "100%" }}
              disabled={newTag.trim() === "" || newTag.length > 20}
            >
              Add Tag
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {formData.courseTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => handleRemoveTag(tag)}
                  sx={{ marginBottom: 1 }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
        {helperText && (
          <Typography variant="caption" color={error ? "error" : "textSecondary"} sx={{ marginTop: 1 }}>
            {helperText}
          </Typography>
        )}
      </FormControl>

      {/* Mobile Dialog for Tags Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>About Course Tags</DialogTitle>
        <DialogContent>
          <Typography>{tagsDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

// Wrap the function component with React.forwardRef
const CourseTags = React.forwardRef(CourseTagsComponent);

// Default export
export default CourseTags;
import React, { useEffect, useState } from "react";
import { getBaseUrl } from "../../Utils/getBaseUrl";
import {
  Box,
  Typography,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Avatar,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import MessageIcon from "@mui/icons-material/Message";
import { toast } from "react-toastify";
import Spinner from "../../Spinner";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import StudentList from "./StudentList";
import ProductList from "./ProductList";

const EMAIL = "email";

const MessagesTabPanel = () => {
  const [asTeacherMessages, setAsTeacherMessages] = useState([]);
  const [asStudentMessages, setAsStudentMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newMessage, setNewMessage] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProductMessages, setSelectedProductMessages] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [uniqueStudents, setUniqueStudents] = useState([]);
  const [view, setView] = useState("teacher");
  const [currentUserId, setCurrentUserId] = useState(null);
  const [teacherId, setTeacherId] = useState(null);
  const [ownerName, setOwnerName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentUserName, setCurrentUserName] = useState("");

  const fetchMessages = async () => {
    setIsLoading(true);
    const baseUrl = getBaseUrl();
    const dataRequest = {
      user_email: localStorage.getItem(EMAIL),
    };
    const response = await fetch(`${baseUrl}/api/get-messages-about-products`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataRequest),
    });
    if (response.ok) {
      const data = await response.json();
      setAsTeacherMessages(data.as_teacher);
      setAsStudentMessages(data.as_student);
      setCurrentUserId(data.user_id);

      if (data.as_student.length > 0) {
        const firstProduct = data.as_student[0];
        if (firstProduct.messages.length > 0) {
          setCurrentUserName(firstProduct?.messages[0]?.sender_name);
          const teacherMessage = firstProduct.messages.find(
            (msg) => msg.sender_id !== data.user_id
          );
          if (teacherMessage) {
            setTeacherId(teacherMessage.sender_id);
          }
        }
      }
    } else {
      console.error("Failed to fetch messages");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const handleSendMessage = async () => {
    if (
      !newMessage.trim() ||
      !selectedProductId ||
      (!selectedStudentId && view === "teacher")
    )
      return;

    setIsLoading(true);
    const baseUrl = getBaseUrl();
    const dataRequest = {
      sender_email: localStorage.getItem(EMAIL),
      message: newMessage.trim(),
      product_id: selectedProductId,
      receiver_id: view === "teacher" ? selectedStudentId : teacherId,
    };
    const response = await fetch(
      `${baseUrl}/api/send-messages-about-products`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      }
    );
    if (response.ok) {
      const newMsg = {
        sender_id: currentUserId,
        receiver_id: view === "teacher" ? selectedStudentId : teacherId,
        sender_name: currentUserName,
        message: newMessage.trim(),
        created_at: new Date().toISOString(),
      };
      setSelectedProductMessages((prevMessages) => [...prevMessages, newMsg]);
      if (view === "teacher") {
        setAsTeacherMessages((prevMessages) =>
          prevMessages.map((product) =>
            product.product_id === selectedProductId
              ? { ...product, messages: [...product.messages, newMsg] }
              : product
          )
        );
      } else {
        setAsStudentMessages((prevMessages) =>
          prevMessages.map((product) =>
            product.product_id === selectedProductId
              ? { ...product, messages: [...product.messages, newMsg] }
              : product
          )
        );
      }
      setNewMessage("");
      toast.success("Message sent successfully!");
    } else {
      toast.error("Failed to send message");
      console.error("Failed to send message");
    }
    setIsLoading(false);
  };

  const handleProductClick = (productId) => {
    setSelectedProductId(productId);
    const product =
      view === "teacher"
        ? asTeacherMessages.find((product) => product.product_id === productId)
        : asStudentMessages.find((product) => product.product_id === productId);
    setSelectedProductMessages(product ? product.messages : []);
    setSelectedProduct(product);
    setSelectedStudentId(null);

    if (view === "student" && product) {
      setOwnerName(product.owner_name);
    }

    if (view === "teacher" && product) {
      const studentsSet = new Set();
      product.messages.forEach((msg) => {
        if (msg.sender_id !== currentUserId) {
          studentsSet.add(
            JSON.stringify({
              sender_id: msg.sender_id,
              sender_name: msg.sender_name,
            })
          );
        }
      });

      const studentsArray = Array.from(studentsSet).map((student) =>
        JSON.parse(student)
      );
      setUniqueStudents(studentsArray);
    }
  };

  const handleStudentClick = (studentId) => {
    setSelectedStudentId(studentId);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
      setSelectedProductId(null);
      setSelectedProductMessages([]);
      setSelectedStudentId(null);
      setOwnerName("");
      setSelectedProduct(null);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  const messagesToShow =
    view === "teacher" ? asTeacherMessages : asStudentMessages;

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="view toggle"
          sx={{
            "& .MuiToggleButton-root": {
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: "4px",
              padding: "8px 16px",
              textTransform: "none",
              "&.Mui-selected": {
                backgroundColor: "#1976d2",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              },
              "&:hover": {
                backgroundColor: "#e3f2fd",
              },
            },
          }}
        >
          <ToggleButton value="teacher" aria-label="teacher view">
            Messages as Teacher
          </ToggleButton>
          <ToggleButton value="student" aria-label="student view">
            Messages as Student
          </ToggleButton>
        </ToggleButtonGroup>
        <IconButton onClick={fetchMessages} color="primary">
          <RefreshIcon />
        </IconButton>
      </Box>

      <Box flex="1" overflow="auto" mb={2}>
        {messagesToShow.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="300px"
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              border: "1px dashed #ccc",
            }}
          >
            <MessageIcon sx={{ fontSize: 64, color: "#9e9e9e", mb: 2 }} />
            <Typography variant="h6" color="textSecondary">
              No messages to display.
            </Typography>
          </Box>
        ) : (
          <ProductList
            products={messagesToShow}
            selectedProductId={selectedProductId}
            onProductClick={handleProductClick}
          />
        )}

        {selectedProductId && selectedProduct && (
          <Box mt={2}>
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar
                src={selectedProduct.product_images[0]}
                alt={selectedProduct.product_name}
                sx={{ width: 56, height: 56, mr: 2 }}
              />
              <Typography variant="h5">
                {selectedProduct.product_name}
              </Typography>
            </Box>

            {view === "teacher" && (
              <StudentList
                students={uniqueStudents}
                selectedStudentId={selectedStudentId}
                onStudentClick={handleStudentClick}
              />
            )}

            {view === "student" && ownerName && (
              <Box mb={2}>
                <Typography variant="h6">Teacher: {ownerName}</Typography>
              </Box>
            )}

            <MessageList
              messages={selectedProductMessages}
              view={view}
              currentUserId={currentUserId}
              selectedStudentId={selectedStudentId}
            />
          </Box>
        )}
      </Box>

      {selectedProductId && (selectedStudentId || view === "student") && (
        <MessageInput
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleSendMessage={handleSendMessage}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default MessagesTabPanel;

// src/components/Auth.js
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { authStore } from '../../../stores/storeContext';// Import the MobX store
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import { getBaseUrl } from '../../Utils/getBaseUrl'
import './Auth.css';

const EMAIL = "email";

const Auth = observer((props) => {
  const navigate = useNavigate();
  const [stage, setStage] = useState('login');
  const [errorServerMessage, setErrorServerMessage] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formPassword, setFormPassword] = useState('');
  const [formFirstName, setFormFirstName] = useState('');
  const [formLastName, setFormLastName] = useState('');
  const [formPassword2, setFormPassword2] = useState('');

  const handleChangeEmail = (event) => setFormEmail(event.target.value);
  const handleChangePassword = (event) => setFormPassword(event.target.value);
  const handleChangeFirstName = (event) => setFormFirstName(event.target.value);
  const handleChangeLastName = (event) => setFormLastName(event.target.value);
  const handleChangePassword2 = (event) => setFormPassword2(event.target.value);

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    if (formEmail.trim() === "" || formPassword.trim() === "") {
      toast.error("Please fill in all fields");
      return;
    }
    try {
      const baseUrl = getBaseUrl();
      const dataRequest = { email: formEmail.trim(), password: formPassword.trim() };
      const response = await fetch(`${baseUrl}/api/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
        credentials: "include",
      });
      const data = await response.json();
      if (response.ok && data.message === "Login successful") {
        toast.success("Login successful");
        authStore.login(formEmail, formPassword); // Use MobX store
        localStorage.setItem(EMAIL, dataRequest.email);
        props.closePopup();
        // navigate({ pathname: "/" });
      } else {
        toast.error(data.error);
        setErrorServerMessage(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitRegister = async (event) => {
    event.preventDefault();
    const requiredFields = [formFirstName, formLastName, formEmail, formPassword, formPassword2];
    const emptyFields = requiredFields.filter((field) => !field.trim());
    if (emptyFields.length) {
      toast.error("Please fill in all required fields.");
      setErrorServerMessage("Please fill in all required fields.");
      return;
    }
    if (formPassword !== formPassword2) {
      toast.error("Passwords must match.");
      setErrorServerMessage("Passwords must match.");
      return;
    }
    const dataRequest = {
      first_name: formFirstName.trim(),
      last_name: formLastName.trim(),
      email: formEmail.trim(),
      password: formPassword.trim(),
    };
    try {
      const response = await fetch(`${getBaseUrl()}/api/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataRequest),
      });
      const data = await response.json();
      if (response.ok && data.message === "Registration successful. Please check your email to verify your account.") {
        toast.success("Registration successful. Please check your email to verify your account.");
        setErrorServerMessage(data.message);
        localStorage.setItem(EMAIL, dataRequest.email);
        authStore.login(dataRequest.email, dataRequest.password); // Use MobX store
        props.closePopup();
      } else {
        toast.error(data.error || "An error occurred. Please try again.");
        setErrorServerMessage(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
      setErrorServerMessage(error.message);
    }
  };

  const handleSubmitForgot = async (event) => {
    event.preventDefault();
    const email = formEmail.trim();
    if (!email) {
      setErrorServerMessage('Email not provided');
      return;
    }
    try {
      const response = await fetch(`${getBaseUrl()}/api/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success("Password reset link is sent to your email.");
        setErrorServerMessage("Password reset link is sent to your email.");
        props.closePopup();
      } else {
        toast.error(data.error);
        setErrorServerMessage(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const signInUsingGoogle = async () => {
    const baseUrl = getBaseUrl();
    window.location.href = `${baseUrl}/auth/google`;
  };

  useEffect(() => {
    console.log('Current stage:', stage);
  }, [stage]);

  return (
    <div className="auth-container">
      {stage === 'login' && (
        <div className="scrollable-content">
          <Login
            formEmail={formEmail}
            formPassword={formPassword}
            handleChangeEmail={handleChangeEmail}
            handleChangePassword={handleChangePassword}
            handleSubmitLogin={handleSubmitLogin}
            setStage={setStage}
            signInUsingGoogle={signInUsingGoogle}
            navigate={navigate}
          />
        </div>
      )}
      {stage === 'register' && (
        <div className="scrollable-content">
          <Register
            formFirstName={formFirstName}
            formLastName={formLastName}
            formEmail={formEmail}
            formPassword={formPassword}
            formPassword2={formPassword2}
            handleChangeFirstName={handleChangeFirstName}
            handleChangeLastName={handleChangeLastName}
            handleChangeEmail={handleChangeEmail}
            handleChangePassword={handleChangePassword}
            handleChangePassword2={handleChangePassword2}
            handleSubmitRegister={handleSubmitRegister}
            setStage={setStage}
            signInUsingGoogle={signInUsingGoogle}
          />
        </div>
      )}
      {stage === 'forgot' && (
        <div className="scrollable-content">
          <ForgotPassword
            formEmail={formEmail}
            handleChangeEmail={handleChangeEmail}
            handleSubmitForgot={handleSubmitForgot}
            setStage={setStage}
          />
        </div>
      )}
    </div>
  );
});

export default Auth;
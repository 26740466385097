import React from "react";
import AuthStore from "./AuthStore";
import CartStore from "./CartStore";
import TotalStore from "./TotalStore";

// Create instances of the MobX stores
const authStore = new AuthStore();
const cartStore = new CartStore();
const totalStore = new TotalStore();

// Create a React Context
const StoreContext = React.createContext({
  authStore,
  cartStore,
  totalStore
});

// Custom hook to use the store
export const useStore = () => React.useContext(StoreContext);

export { StoreContext, authStore, cartStore, totalStore };